import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_RESERVATIONHISTORY,
  SET_RESERVATIONHISTORY,
  GET_RESERVATIONCOMPHISTORY,
  SET_RESERVATIONCOMPHISTORY,
} from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetReservationList = function* () {
  yield takeEvery(GET_RESERVATIONHISTORY, workerGetReservationList);
};

function* workerGetReservationList({ limitTxt, orderStatus }) {
  try {
    const uri =
      apiUrl +
      "reports/reservation_order_history/?app_id=" +
      appId +
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      limitTxt +
      "&order_status=" +
      orderStatus;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_RESERVATIONHISTORY, value: resultArr });
  } catch {
    console.log("Get reservation list Failed");
  }
}

export const watchGetReservationCompList = function* () {
  yield takeEvery(GET_RESERVATIONCOMPHISTORY, workerGetReservationCompList);
};

function* workerGetReservationCompList({ limitTxt, orderStatus }) {
  try {
    const uri =
      apiUrl +
      "reports/reservation_order_history/?app_id=" +
      appId +
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      limitTxt +
      "&order_status=" +
      orderStatus;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_RESERVATIONCOMPHISTORY, value: resultArr });
  } catch {
    console.log("Get reservation list Failed");
  }
}
