/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_BRANDS_MALLS, SET_BRANDS_MALLS } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetBrandsMalls = function* () {
  yield takeEvery(GET_BRANDS_MALLS, workerGetBrandsMall);
};

function* workerGetBrandsMall({brand_slug, postal_code}) {
  try {
    const uri = apiUrl + "brands/get_brands_malls?app_id=" + appId + "&brand_slug=" +
      brand_slug +"&postal_code=" +postal_code;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    //console.log(resultArr, "resultArr");
    yield put({ type: SET_BRANDS_MALLS, value: resultArr });
  } catch {
    console.log("Get Events Failed");
  }
}
