import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_SITE_LOCATION, SET_SITE_LOCATION } from '../actions';
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';

export const watchGetSitelocation = function* () {
  yield takeEvery(GET_SITE_LOCATION, workerGetSiteLocation);
}

function* workerGetSiteLocation() {

  var delivery_postal_code = 
      cookie.load("nearest_postal_code") !== "" &&
      typeof cookie.load("nearest_postal_code") !== undefined &&
      typeof cookie.load("nearest_postal_code") !== "undefined"
        ? cookie.load("nearest_postal_code")
        : "";
  try {
    const uri = apiUrl+'outlets/getSiteLocation?app_id='+appId + "&postal_code="+ delivery_postal_code;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
  yield put({ type: SET_SITE_LOCATION, value: resultArr });
  } 

  catch {
    console.log('Get Banner Failed');
  }
} 
