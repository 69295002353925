/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { appId, apiUrl, deliveryId } from "../Helpers/Config";
import axios from "axios";
import cookie from "react-cookies";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { showAlert } from "../Helpers/SettingHelper";
var Parser = require("html-react-parser");
var qs = require("qs");

class Account extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let activationKey =
      typeof this.props.match.params.activationKey != "undefined"
        ? this.props.match.params.activationKey
        : "";
    var postObject = {
      app_id: appId,
      key: activationKey,
    };
    axios
      .post(apiUrl + "customer/activation", qs.stringify(postObject))
      .then((res) => {
        const { history } = this.props;

        if (res.data.status === "ok") {
          this.showAlert_manual(
            "ACCOUNT ACTIVATED",
            "Welcome to UPshop! Login to start shopping now.");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          cookie.save("loginPopup", 1, { path: "/" });
          history.push("/");
        } else {
          showAlert(
            "Notice",
            "Your activation link has been expired. Please contact your admin."
          );
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          history.push("/");
        }
      });
  }

  /* show Alert */
 showAlert_manual(header, message, autoClose = "No") {
  $(".alert_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body account_activated"><p>Welcome to UPshop!</p><p>Login to start shopping now.</p><div class="alt_btns"><a href="#login-popup" data-effect="mfp-zoom-in" class="open-popup-link htico_sign button">OK</a></div></div></div></div></div></div>'
    );
};

  render() {
    return <div></div>;
  }
}

Header.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(Account);
