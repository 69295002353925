/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";
const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";
function validationConfig(props) {
  const { firstname, email } = props.fields;

  return {
    fields: ["firstname", "lastname", "email"],
    validations: {
      firstname: [[isEmpty, firstname]],
      email: [[isEmail, email]],
    },
  };
}

class Subscriber extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgFirstName, errMsgEmail;

    if ($validation.firstname.error.reason !== undefined) {
      errMsgFirstName = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <div className="focus-out">
            <label>Your Name</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.firstname}
              {...$field("firstname", (e) =>
                onChange("firstname", e.target.value)
              )}
            />
            {errMsgFirstName}
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.lastname}
              {...$field("lastname", (e) =>
                onChange("lastname", e.target.value)
              )}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Your Email Address</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.email}
              {...$field("email", (e) => onChange("email", e.target.value))}
            />
            <div id="spn-email-error">{errMsgEmail}</div>
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="btn btn_black btn_minwid subscribe_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              Submit{" "}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
Subscriber = validated(validationConfig)(Subscriber);

export default Subscriber;
