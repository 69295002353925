/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_BRANDS, SET_BRANDS } from "../actions";
import { appId, apiUrl, deliveryId } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetBrands = function* () {
  yield takeEvery(GET_BRANDS, workerGetBrands);
};

function* workerGetBrands({brand_slug}) {
  try {

    if(brand_slug  !=='' && brand_slug !== 'undefined' && brand_slug !== undefined){
      brand_slug = brand_slug;
    }else{
      brand_slug = '';
    }

    const uri = apiUrl + "brands/get_brands?app_id=" + appId + "&brand_slug=" + brand_slug;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_BRANDS, value: resultArr });
  } catch {
    console.log("Get Events Failed");
  }
}
