/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";

import { GET_HIGHLIGHTPROLIST, GET_STATIC_BLOCK } from "../../actions";
import { deliveryId, proFolderUrl, appId, apiUrl } from "../Helpers/Config";
import { stripslashes } from "../Helpers/SettingHelper";
import Slider from "react-slick";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import dealsplace from "../../common/images/deals-place.jpg";
import searchorg from "../../common/images/search-org.png";
import filter from "../../common/images/filter.png";
import closebl from "../../common/images/close-block.png";
import axios from "axios";

import elipse from "../../common/images/elipse.png";
import deal from "../../common/images/updeal.png";
var Parser = require("html-react-parser");

class Deals extends Component {
  constructor(props) {
    super(props);

    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";

    let defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";

    this.state = {
      defaultAvilTy: defaultAvilTy,
      settings: [],
      brandlist: [],
      brandbackgroundImg: "",
      tag: [],

      locationImage: "",
      notfounderror: "",
      status: true,
      brandname: "",
      brandDetails: "",
      selectPostalCode: "No",
      urltype: this.props.match.path,
      deliveryPostalCode: deliveryPostalCode,

      delivery_tat_time: "",
      pickup_tat_time: "",
      imagesrc: "",
      brand_active_image: "",

      highlightproducts: [],
      displyProductts: "",
      staticblack: [],
      deals_banner: "",
      applyFilter: "N",
      tagList: [],
      tagList_error_message: "",
      tagdisplay: "",
      filterTag: [],
      searchKeyWord: "",
    };
    this.loadProductTagList();
  }

  componentDidMount() {
    this.props.getHighlightList();
    this.props.getStaticBlock();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalsettings !== this.state.settings) {
      if (nextProps.globalsettings.length > 0) {
        if (nextProps.globalsettings[0].status === "ok") {
          var settingsArr = nextProps.globalsettings[0].result_set;
          var client_tat_setting_restaurants_delivery = "";
          var client_tat_setting_restaurants_pickup = "";
          if (settingsArr !== "" && settingsArr !== "undefined") {
            client_tat_setting_restaurants_delivery =
              settingsArr.order_tat_all.client_tat_setting_restaurants_delivery;
            client_tat_setting_restaurants_pickup =
              settingsArr.order_tat_all.client_tat_setting_restaurants_pickup;
          }
          this.setState({
            settings: nextProps.globalsettings[0].result_set,
            delivery_tat_time: client_tat_setting_restaurants_delivery,
            pickup_tat_time: client_tat_setting_restaurants_pickup,
          });
        }
      }
    }
    if (this.state.highlightproducts !== nextProps.highlightproducts) {
      this.setState(
        { highlightproducts: nextProps.highlightproducts },
        function () {
          this.displayHighlightProducts();
          $("#dvLoading").fadeOut(2000);
        }
      );
    }
    if (nextProps.staticblack !== this.state.footerBlocks) {
      var deals_banner = "";
      if (Object.keys(nextProps.staticblack).length > 0) {
        nextProps.staticblack.map((data) => {
          if (data.staticblocks_slug === "deals-banner") {
            deals_banner = data.staticblocks_description;
            return "";
          }
        });
      }
      this.setState({
        deals_banner: deals_banner,
      });
    }
  }

  loadProductTagList() {
    var order_outlet_id = this.state.orderOutletId;
    axios
      .get(apiUrl + "products/product_tags?app_id=" + appId)
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState({ tagList: response.data.result_set }, function () {
            this.displayTag();
          });
        } else {
          this.setState({ tagList_error_message: "No Products Tags found." });
        }
      });
  }
  displayTag() {
    var tagdisplay = this.state.tagList.map((item, index) => {
      return (
        <li className="custom_checkbox" key={index}>
          <input
            type="checkbox"
            className="restaurants_tag"
            onChange={this.handleChange.bind(this)}
            value={item.pro_tag_id}
          />
          <span>{item.pro_tag_name}</span>
        </li>
      );
    });
    this.setState({ tagdisplay: tagdisplay });
  }

  handleChange(event) {
    let filterTag = this.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    this.setState({ filterTag: filterTag }, function () {
      this.displayHighlightProducts();
    });
  }

  clearFilter(event) {
    event.preventDefault();
    $(".restaurants_tag").prop("checked", false);
    this.setState({ filterTag: [] }, function () {
      this.setState({ applyFilter: "Y" }, function () {
        this.displayHighlightProducts();
      });
    });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "selectPostalCode") {
      if (value == "Yes") {
        cookie.save("defaultAvilablityId", deliveryId, { path: "/" });
        this.setState(
          { defaultAvilTy: deliveryId, selectPostalCode: "No" },
          function () {}
        );
      }
    }
    if (field === "postalcode") {
      this.setState({ orderPostalCode: value });
      if (this.state.defaultAvilTy === deliveryId) {
        this.setState({ deliveryPostalCode: value });
      }
    }
    if (field === "loadOutlet" && value !== "") {
      var outletdata = value.split("_");
      this.setState({ defaultAvilTy: outletdata[0] });
    }
    if (field === "applyFilter" && value !== "") {
      this.setState({ applyFilter: "N" });
    }
  };

  displayHighlightProducts() {
    if (Object.keys(this.state.highlightproducts).length > 0) {
      var totaldeals = 0;
      var result = this.state.highlightproducts.map((item, index) => {
        var checkfilter = this.checkfilter(item);
        if (checkfilter === true) {
          totaldeals++;
          return (
            <li key={index}>
              <Link
                to={
                  "/food/" +
                  item.sl_slug +
                  "/" +
                  item.outlet_slug +
                  "/" +
                  item.pro_cate_slug +
                  "/" +
                  item.pro_subcate_slug +
                  "/" +
                  item.product_slug
                }
              >
                <div className="market-item">
                  <div className="market-image">
                    <a>
                      <img
                        src={
                          item.product_thumbnail !== "" &&
                          item.product_thumbnail !== null
                            ? proFolderUrl +
                              "main-image/" +
                              item.product_thumbnail
                            : dealsplace
                        }
                      />
                    </a>
                    {item.product_tag_info !== "" &&
                      item.product_tag_info !== null && (
                        <span className="tag-info">
                          {item.product_tag_info}
                        </span>
                      )}
                    {item.productcount !== "" &&
                      item.productcount !== null &&
                      item.productcount !== "0" && (
                        <span className="tag-info-count">
                          Bought {item.productcount} Times
                        </span>
                      )}
                  </div>
                  <div className="market-name">
                    <h4>
                      {item.product_alias !== "" && item.product_alias !== null
                        ? stripslashes(item.product_alias)
                        : stripslashes(item.product_name)}
                    </h4>
                    <div className="deal-name">
                      <span>{stripslashes(item.brand_name)}</span>
                    </div>
                    <div className="deal-outlet">
                      <span>{stripslashes(item.outlet_address_line1)}</span>
                    </div>

                    <div className="price">${item.product_price}</div>
                  </div>
                </div>
              </Link>
            </li>
          );
        }
      });
      if (totaldeals === 0) {
        result = <div>No Deals Found</div>;
      }
      this.setState({ displyProductts: result });
    } else {
    }
  }

  checkfilter(item) {
    var filterTag = this.state.filterTag;
    var tag_result = true;
    if (filterTag.length > 0) {
      if (item.product_tag.length > 0) {
        var tagavail = 0;
        item.product_tag.map((tag) => {
          if (filterTag.indexOf(tag.tag_id) >= 0) {
            tagavail++;
          }
        });
        if (tagavail === 0) {
          tag_result = false;
        }
      } else {
        tag_result = false;
      }
    }

    var keywords = this.state.searchKeyWord.toLowerCase();
    var product_name =
      item.product_alias !== "" && item.product_alias !== null
        ? item.product_alias
        : item.product_name;
    var error = 0;
    if (keywords !== "") {
      if (
        product_name.toLowerCase().indexOf(keywords) >= 0 ||
        item.brand_name.toLowerCase().indexOf(keywords) >= 0 ||
        item.outlet_address_line1.toLowerCase().indexOf(keywords) >= 0
      ) {
      } else {
        error++;
      }
    }

    if (error === 0) {
      if (tag_result === false) {
        error++;
      }
    }

    if (error === 0) {
      return true;
    } else {
      return false;
    }
  }

  searchProKeyPress = (event) => {
    this.setState({ searchKeyWord: event.target.value }, function () {
      this.displayHighlightProducts();
    });
  };

  render() {
    var banner_name = this.state.imagesrc + this.state.brand_active_image;
    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
    };

    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />

        <section className="mini-banner-parent rel">
          {this.state.deals_banner !== "" && (
            <Slider {...settingsGallery}>
              {Parser(this.state.deals_banner)}
            </Slider>
          )}
        </section>

        <div className="search-and-info mall-info">
          <div className="container deals-filters">
            <div className="search-info-inner">
              <div className="search-filter mall-search">
                <div className="search-filter-lhs">
                  <input
                    type="text"
                    className="search-rest"
                    placeholder="Search brand, deals & outlet"
                    id="searchKeyWord"
                    onKeyUp={this.searchProKeyPress}
                  />
                  <img src={searchorg} alt="" />
                </div>

                <div className="search-filter-rhs restaurant_filter">
                  Filters
                  <img
                    src={closebl}
                    alt=""
                    className="close_filter_section filter-clear-image"
                    style={{ display: "none" }}
                  />
                  <img
                    className="close_filter_section filter-img"
                    src={filter}
                    alt=""
                  />
                </div>

                <div className="filter-details" style={{ display: "none" }}>
                  {this.state.filterTag.length > 0 && (
                    <div className="clear-filter">
                      <Link to="#" onClick={this.clearFilter.bind(this)}>
                        Clear
                      </Link>
                    </div>
                  )}
                  <div className="item-filter common-filter">
                    <h3 className="filter-tag">
                      {this.state.tagList_error_message == ""
                        ? "Tag"
                        : this.state.tagList_error_message}
                    </h3>
                    {this.state.tagList_error_message === "" && (
                      <ul>{this.state.tagdisplay}</ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="markets mall-inner deals-new">
          <div className="container">
            {this.state.status === true ? (
              <ul>{this.state.displyProductts}</ul>
            ) : (
              <div>No Deals Found.</div>
            )}
          </div>
        </section>

        <div id="dvLoading"></div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var highlightproducts = Array();
  var highlightstatus = "";
  if (Object.keys(state.highlightproductlist).length > 0) {
    if (
      state.highlightproductlist[0].status === "ok" &&
      Object.keys(state.highlightproductlist[0].result_set).length > 0
    ) {
      highlightproducts = state.highlightproductlist[0].result_set;
      highlightstatus = "success";
    } else {
      highlightstatus = "failure";
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    highlightproducts: highlightproducts,
    highlightstatus: highlightstatus,
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHighlightList: () => {
      dispatch({ type: GET_HIGHLIGHTPROLIST });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

Deals.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Deals));
