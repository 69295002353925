/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { appId } from "../Helpers/Config";
import cookie from "react-cookies";
import update from "immutability-helper";

import {
  GET_CUSTOMER_DETAIL,
  GET_UPDATECUSTOMERPROFILE,
  GET_ACTIVITYCOUNT,
} from "../../actions";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";

import Form from "./Form";

var qs = require("qs");

class Myaccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        firstname: "",
        lastname: "",
        nickname: "",
        email: "",
        mobile: "",
        postal: "",
        unitnumber2: "",
        unitnumber1: "",
        company: "",
        birthdate: "",
        birth_month: "",
        birth_year: "",
        fbid: "",
        image_source: "",
        photo: "",
        gender: "",
        oldphoto: "",
      },
      status: "OK",
      date1: "",
      defaultDispaly: "",
      order_all: 0,
      overall_orders: 0,

      activityPoints: {
        reward_ponits: "",
        reward_ponits_monthly: "",
        order_all: "",
        promotion: "",
      },
      ordercount: 0,
    };

    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    }
  }

  componentDidMount() {
    var params = "&status=A&refrence=" + cookie.load("UserId");
    $("#dvLoading").fadeOut(2000);
    this.props.getCustomerDetail(params);
    this.getActivityCountsNew();

    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 400);
  }

  getActivityCountsNew() {
    const inputKeys = ["order_all", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  /* To Submit the personal informartion form */
  handleFormSubmit = () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const formPayload = this.state.fields;

    var user_img = "";

    if (this.state.fields.oldphoto == formPayload.photo) {
      user_img = "";
    } else {
      user_img = formPayload.photo;
    }

    var gender_value = formPayload.gender.value;

    if (
      gender_value !== "" &&
      gender_value !== null &&
      gender_value !== undefined
    ) {
      gender_value = gender_value;
    } else {
      gender_value = this.state.fields.gender;
    }

    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name:
        formPayload.firstname !== "" && formPayload.firstname !== "undefined"
          ? formPayload.firstname
          : "",
      customer_last_name:
        formPayload.lastname !== "" && formPayload.lastname !== "undefined"
          ? formPayload.lastname
          : "",
      customer_nick_name:
        formPayload.nickname !== "" && formPayload.nickname !== "undefined"
          ? formPayload.nickname
          : "",
      customer_phone:
        formPayload.mobile !== "" && formPayload.mobile !== "undefined"
          ? formPayload.mobile
          : "",
      customer_email:
        formPayload.email !== "" && formPayload.email !== "undefined"
          ? formPayload.email
          : "",
      customer_birthdate:
        cookie.load("birthdate") !== "" &&
        typeof cookie.load("birthdate") !== undefined &&
        typeof cookie.load("birthdate") !== "undefined"
          ? cookie.load("birthdate").replaceAll("/", "-")
          : "",
      customer_postal_code:
        formPayload.postal !== "" && formPayload.postal !== "undefined"
          ? formPayload.postal
          : "",
      customer_address_name2:
        formPayload.address1 !== "" && formPayload.address1 !== "undefined"
          ? formPayload.address1
          : "",
      customer_company_name:
        formPayload.company !== "" && formPayload.company !== "undefined"
          ? formPayload.company
          : "",
      customer_gender: gender_value,
      customer_address_line1:
        formPayload.unitnumber1 !== "" &&
        formPayload.unitnumber1 !== "undefined"
          ? formPayload.unitnumber1
          : "",
      customer_address_line2:
        formPayload.unitnumber2 !== "" &&
        formPayload.unitnumber2 !== "undefined"
          ? formPayload.unitnumber2
          : "",
      customer_id: cookie.load("UserId"),
      customer_photo: user_img,
    };
    $(".myaccount_update").append('<b class="gloading_img"></b>');
    this.props.getUpdateCustomerProfile(qs.stringify(postObject), config);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.updatecustomerprofile !== this.props.updatecustomerprofile) {
      const { updatecustomerprofile } = nextProps;
      if (updatecustomerprofile[0].status === "ok") {
        $(".myaccount_update .gloading_img").remove();
        cookie.save(
          "UserFname",
          updatecustomerprofile[0].result_set.customer_first_name,
          { path: "/" }
        );
        cookie.save(
          "UserLname",
          updatecustomerprofile[0].result_set.customer_last_name,
          { path: "/" }
        );
        cookie.save(
          "UserMobile",
          updatecustomerprofile[0].result_set.customer_phone,
          { path: "/" }
        );
        cookie.save(
          "UserEmail",
          updatecustomerprofile[0].result_set.customer_email,
          { path: "/" }
        );
        cookie.save(
          "UserDefaultAddress",
          updatecustomerprofile[0].result_set.customer_address_name,
          { path: "/" }
        );
        cookie.save(
          "UserDefaultUnitOne",
          updatecustomerprofile[0].result_set.customer_address_line1,
          { path: "/" }
        );
        cookie.save(
          "UserDefaultUnitTwo",
          updatecustomerprofile[0].result_set.customer_address_line2,
          { path: "/" }
        );
        cookie.save(
          "UserDefaultPostalCode",
          updatecustomerprofile[0].result_set.customer_postal_code,
          { path: "/" }
        );

        /* Redirect to checkout page .. */
        if (
          cookie.load("isChecoutRedirect") === "Yes" &&
          cookie.load("cartValue") === "Yes"
        ) {
          cookie.save("isChecoutRedirect", "No", { path: "/" });
          /*hashHistory.push("/checkout");*/
        }
        window.location.reload();
      } else {
        //this.setStateLoading('ok');
        $(".myaccount_update .gloading_img").remove();
        $("#jquery-error-msg").show();
        if (updatecustomerprofile[0].form_error) {
          $("#jquery-error-msg .jquery-error-msg").html(
            updatecustomerprofile[0].form_error
          );
        } else {
          $("#jquery-error-msg .jquery-error-msg").html(
            updatecustomerprofile[0].message
          );
        }
        setTimeout(function () {
          $("#jquery-error-msg").hide();
        }, 3000);
      }
    }

    if (nextProps.customerdetail !== this.props.customerdetail) {
      //const {customerdetail} = nextProps;
      if (nextProps.customerdetail[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        // var birth_date = "";
        // var birth_month = "";
        // var birth_year = "";
        if (
          nextProps.customerdetail[0].result_set.customer_birthdate == null ||
          nextProps.customerdetail[0].result_set.customer_birthdate ==
            "0000-00-00" ||
          nextProps.customerdetail[0].result_set.customer_birthdate ==
            "1970-01-01"
        ) {
          var birthdate = "";
        } else {
          var birthdate =
            nextProps.customerdetail[0].result_set.customer_birthdate;
          var birthyear = birthdate.substring(0, 4);
          var birthmonth = birthdate.substring(5, 7);
          var birthdatev = birthdate.substring(8, 10);
          var birthdateTemp = birthdatev + "/" + birthmonth + "/" + birthyear;
          cookie.save("birthdate", birthdateTemp, { path: "/" });

          // var birthdateslit = birthdate.split("-");
          // birth_date = birthdateslit[2];
          // birth_month = birthdateslit[1];
          // birth_year = birthdateslit[0];
          /* 
          birth_date: "",
          birth_month: "",
          birth_year: "", */
        }

        this.setState({
          fields: {
            firstname:
              nextProps.customerdetail[0].result_set.customer_first_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_first_name
                : "",

            birthdate: birthdate,
            // birth_date: birth_date,
            // birth_month: birth_month,
            // birth_year: birth_year,
            lastname:
              nextProps.customerdetail[0].result_set.customer_last_name !== null
                ? nextProps.customerdetail[0].result_set.customer_last_name
                : "",
            nickname:
              nextProps.customerdetail[0].result_set.customer_nick_name !== null
                ? nextProps.customerdetail[0].result_set.customer_nick_name
                : "",
            email:
              nextProps.customerdetail[0].result_set.customer_email !== null
                ? nextProps.customerdetail[0].result_set.customer_email
                : "",
            mobile:
              nextProps.customerdetail[0].result_set.customer_phone !== null
                ? nextProps.customerdetail[0].result_set.customer_phone
                : "",
            photo:
              nextProps.customerdetail[0].result_set.customer_photo !== null &&
              nextProps.customerdetail[0].result_set.customer_photo !== ""
                ? nextProps.customerdetail[0].common.image_source +
                  nextProps.customerdetail[0].result_set.customer_photo
                : "",
            postal:
              nextProps.customerdetail[0].result_set.customer_postal_code !==
              null
                ? nextProps.customerdetail[0].result_set.customer_postal_code
                : "",
            unitnumber2:
              nextProps.customerdetail[0].result_set.customer_address_line2 !==
              null
                ? nextProps.customerdetail[0].result_set.customer_address_line2
                : "",
            unitnumber1:
              nextProps.customerdetail[0].result_set.customer_address_line1 !==
              null
                ? nextProps.customerdetail[0].result_set.customer_address_line1
                : "",
            address:
              nextProps.customerdetail[0].result_set.customer_address_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_address_name
                : "",
            address1:
              nextProps.customerdetail[0].result_set.customer_address_name2 !==
              null
                ? nextProps.customerdetail[0].result_set.customer_address_name2
                : "",
            company:
              nextProps.customerdetail[0].result_set.customer_company_name !==
              null
                ? nextProps.customerdetail[0].result_set.customer_company_name
                : "",
            fbid: nextProps.customerdetail[0].result_set.customer_fb_id,
            image_source: nextProps.customerdetail[0].common.image_source,
            juicedid:
              nextProps.customerdetail[0].result_set.customer_unique_id !== null
                ? nextProps.customerdetail[0].result_set.customer_unique_id
                : "",
            joinedOn:
              nextProps.customerdetail[0].result_set.customer_created_on !==
              null
                ? nextProps.customerdetail[0].result_set.customer_created_on
                : "",
            gender:
              nextProps.customerdetail[0].result_set.customer_gender !== null &&
              nextProps.customerdetail[0].result_set.customer_gender !== ""
                ? nextProps.customerdetail[0].result_set.customer_gender
                : "O",
            oldphoto:
              nextProps.customerdetail[0].result_set.customer_photo !== null &&
              nextProps.customerdetail[0].result_set.customer_photo !== ""
                ? nextProps.customerdetail[0].common.image_source +
                  nextProps.customerdetail[0].result_set.customer_photo
                : "",
          },
          status: nextProps.customerdetail[0].status,
        });

        var vDefaultAddr = "";
        var list = document.getElementsByClassName("form-group-input");
        var n;
        for (n = 0; n < list.length; ++n) {
          if (list[n].getElementsByTagName("input")[0].value !== "") {
            list[n].classList.remove("is-empty");
          } else {
            list[n].classList.add("is-empty");
          }
        }
      }
    }

    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
          });
        }
      }
    } else {
      this.setState({ order_all: 0, overall_orders: 0 });
    }
  }

  render() {
    return (
      <div className="myacc-main-div">
        <Header />

        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_toptext">
                <h2>My Account</h2>
                {cookie.load("userAccountType") === "2" && (
                  <p>
                    My Account Dashboard allows you to view your recent
                    activities, check your reward points and update account
                    information.
                  </p>
                )}
              </div>
              <Sidebar currentPage="myaccount" />
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec">
                            <Form
                              {...this.props}
                              fields={this.state.fields}
                              activityPoints={this.state.activityPoints}
                              onChange={this.fieldChange}
                              onValid={this.handleFormSubmit}
                              onInvalid={(e) => console.log(e, "Form invalid!")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customerdetail: state.customerdetail,
    updatecustomerprofile: state.updatecustomerprofile,
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
    getUpdateCustomerProfile: (formPayload, config) => {
      dispatch({ type: GET_UPDATECUSTOMERPROFILE, formPayload, config });
    },
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Myaccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Myaccount)
);
