import { SET_MULTIPLE_MARKET_SEARCH } from '../actions';

const multiplemarketsearch = (state = [], action) => {

  switch (action.type) {
    case SET_MULTIPLE_MARKET_SEARCH:
      return [...action.value];

    default: return state;
  }
}


export default multiplemarketsearch;