/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import cookie from "react-cookies";
import moment from "moment";
import Select from "react-select";
import {
  appId,
  apiUrlV2,
  deliveryId,
  brandUrl,
  siteURL,
  proFolderUrl,
  reservationId,
  CountryTxt,
} from "../Helpers/Config";
import axios from "axios";
import { format } from "date-fns";
import { stripslashes, showLoader, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import OrderdatetimeSlot from "../Layout/OrderdatetimeSlot";

import noimage from "../../common/images/no-img-product.png";
import dealsplace from "../../common/images/deals-place.jpg";
import facebook from "../../common/images/facebook-brand.svg";
import instagram from "../../common/images/instagram-brand.svg";

import { connect } from "react-redux";
import {
  GET_GLOBAL_SETTINGS,
  GET_BRANDS,
  GET_BRANDS_MALLS,
  GET_HIGHLIGHTPROLIST,
  GET_RES_ORDER_SUBMIT,
} from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

var Parser = require("html-react-parser");

class Brands extends Component {
  constructor(props) {
    super(props);
    let uriparms_one = "";
    let pathname = props.location.pathname;
    pathname = pathname.split("/");
    uriparms_one = pathname[2];
    var brand_slug = uriparms_one;

    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";

    let defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";

    let home_brand_id =
      cookie.load("home_brand_id") !== "" &&
      typeof cookie.load("home_brand_id") !== undefined &&
      typeof cookie.load("home_brand_id") !== "undefined"
        ? cookie.load("home_brand_id")
        : "";
    var reservation =
      this.props.match.path === "/brands/:brandSlug/reservations"
        ? "Yes"
        : "No";
    this.state = {
      UserId:
        cookie.load("UserId") != "" && cookie.load("UserId") != undefined
          ? cookie.load("UserId")
          : "",
      brand_slug: brand_slug,
      reservation: reservation,
      settings: [],
      brandArray: [],
      brandlist: [],
      brandbackgroundImg: "",
      tag: [],
      brandsmalls: [],
      brandId: home_brand_id,
      locationImage: "",
      notfounderror: "",
      status: true,
      brandname: "",
      brandDetails: "",
      selectPostalCode: "No",
      urltype: this.props.match.path,
      deliveryPostalCode: deliveryPostalCode,
      eventList: [],
      eventImagePath: "",
      event_List: "",
      brandSlug: uriparms_one,
      delivery_tat_time: "",
      pickup_tat_time: "",
      imagesrc: "",
      brand_active_image: "",
      highlightproducts: [],
      displyProductts: "",
      listOutlet: [],

      getDateTimeFlg: "",
      reservation_outlet_id: "",
      reservation_outlet_postalcode: "",
      reservation_outlet_name: "",
      reservation_outletaddress_line: "",
      reservation_outlet_address: "",
      datePickershow: 0,

      dineInfo: "",
      seletedAvilablityId: "",
      seletedOutletId: "",
      order_tat_time: "",
      orderHandled: "",
      adultValue: 0,
      childValue: 0,
      reservationConfirm: false,

      outlet_error: false,
      pax_error: false,

      mobile_number:
        cookie.load("UserMobile") != "" &&
        cookie.load("UserMobile") != undefined
          ? cookie.load("UserMobile")
          : "",
      first_name:
        cookie.load("UserFname") != "" && cookie.load("UserFname") != undefined
          ? cookie.load("UserFname")
          : "",
      last_name:
        cookie.load("UserLname") != "" && cookie.load("UserLname") != undefined
          ? cookie.load("UserLname")
          : "",
      customer_email:
        cookie.load("UserEmail") != "" && cookie.load("UserEmail") != undefined
          ? cookie.load("UserEmail")
          : "",

      error_mobile_number:
        cookie.load("UserMobile") != "" &&
        cookie.load("UserMobile") != undefined
          ? false
          : true,
      error_first_name:
        cookie.load("UserFname") != "" && cookie.load("UserFname") != undefined
          ? false
          : true,
      error_last_name:
        cookie.load("UserLname") != "" && cookie.load("UserLname") != undefined
          ? false
          : true,
      error_customer_email:
        cookie.load("UserEmail") != "" && cookie.load("UserEmail") != undefined
          ? false
          : true,
      RerservationStatus: "",
      finalreservationDate: "",
      reservation_local_order_no: "",
    };
    this.props.getSettings();
    this.props.getBrands(brand_slug);
    this.props.getBrandsMalls(brand_slug, deliveryPostalCode);
  }

  componentDidMount() {
    this.loadAllOutlets();
    $(".dvLoadrCls").show();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalsettings !== this.state.settings) {
      if (nextProps.globalsettings.length > 0) {
        if (nextProps.globalsettings[0].status === "ok") {
          var settingsArr = nextProps.globalsettings[0].result_set;
          var client_tat_setting_restaurants_delivery = "";
          var client_tat_setting_restaurants_pickup = "";
          if (settingsArr !== "" && settingsArr !== "undefined") {
            client_tat_setting_restaurants_delivery =
              settingsArr.order_tat_all.client_tat_setting_restaurants_delivery;
            client_tat_setting_restaurants_pickup =
              settingsArr.order_tat_all.client_tat_setting_restaurants_pickup;
          }
          this.setState({
            settings: nextProps.globalsettings[0].result_set,
            delivery_tat_time: client_tat_setting_restaurants_delivery,
            pickup_tat_time: client_tat_setting_restaurants_pickup,
          });
        }
      }
    }

    if (nextProps.RerservationStatus !== this.state.RerservationStatus) {
      this.setState(
        { RerservationStatus: nextProps.RerservationStatus },
        function () {
          hideLoader("res-cont-div", "class");
          if (
            nextProps.RerservationStatus === "success" &&
            nextProps.RerservationOrderlocalId !== ""
          ) {
            this.setState(
              {
                reservation_local_order_no:
                  nextProps.reservationdata.common.local_order_no,
              },
              function () {
                this.displayBrandsdeatils();
              }
            );
          }

          if (nextProps.RerservationStatus === "failure") {
            showAlert("error", "Sorry!. Try Again");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
          }
        }
      );
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "proceed-reservation" && value === "Yes") {
      this.setState(
        {
          reservationConfirm: true,
          mobile_number:
            cookie.load("UserMobile") != "" &&
            cookie.load("UserMobile") != undefined
              ? cookie.load("UserMobile")
              : "",
          first_name:
            cookie.load("UserFname") != "" &&
            cookie.load("UserFname") != undefined
              ? cookie.load("UserFname")
              : "",
          last_name:
            cookie.load("UserLname") != "" &&
            cookie.load("UserLname") != undefined
              ? cookie.load("UserLname")
              : "",
          customer_email:
            cookie.load("UserEmail") != "" &&
            cookie.load("UserEmail") != undefined
              ? cookie.load("UserEmail")
              : "",
          error_mobile_number:
            cookie.load("UserMobile") != "" &&
            cookie.load("UserMobile") != undefined
              ? false
              : true,
          error_first_name:
            cookie.load("UserFname") != "" &&
            cookie.load("UserFname") != undefined
              ? false
              : true,
          error_last_name:
            cookie.load("UserLname") != "" &&
            cookie.load("UserLname") != undefined
              ? false
              : true,
          error_customer_email:
            cookie.load("UserEmail") != "" &&
            cookie.load("UserEmail") != undefined
              ? false
              : true,
        },
        function () {
          this.displayBrandsdeatils();
        }
      );
    }
  };

  loadAllOutlets() {
    axios
      .get(
        apiUrlV2 +
          "outlets/getAllOutles?app_id=" +
          appId +
          "&availability_id=" +
          reservationId
      )
      .then((response) => {
        $("#dvLoading").fadeOut(2000);
        if (response.data.status === "ok") {
          var listOutlet = [];
          if (response.data.result_set.length > 0) {
            response.data.result_set.map((item) => {
              listOutlet.push({
                label: item.outlet_name,
                value:
                  item.oa_outlet_id +
                  "~" +
                  item.outlet_postal_code +
                  "~" +
                  stripslashes(item.outlet_name).replace("'", "`") +
                  "~" +
                  stripslashes(item.outlet_address_line1) +
                  "~" +
                  item.outlet_delivery_timing,
              });
            });
          }
          this.setState({ listOutlet: listOutlet }, function () {
            this.displayBrandsdeatils();
          });
        }
      });
  }

  handleChange(event) {
    if (event.target.name === "reservation_notes") {
      this.setState({ reservation_notes: event.target.value });
    }
    if (event.target.name === "mobile_number") {
      this.setState({ mobile_number: event.target.value });
    }
  }

  handleChangeOutlet(event) {
    // this.resetErrorClass();
    this.setState({ reservation_date: "", reservation_time: "" });

    showLoader("datepickers_main_div", "class");

    var ouLtvl = event.value;

    this.setState({ reservation_selected_outlet: ouLtvl });
    var outletIdvl = "",
      outletAddrsLn = "",
      outletAddrs = "",
      outletPostCd = "",
      outletName = "",
      errorInfo = "block",
      outletTatTm = "";
    if (ouLtvl !== "") {
      var splitedOutletArr = ouLtvl.split("~");
      outletIdvl = splitedOutletArr[0];
      outletPostCd = splitedOutletArr[1];
      outletName = splitedOutletArr[2];
      outletAddrsLn = splitedOutletArr[3];
      outletTatTm = splitedOutletArr[4];
      outletAddrs = outletAddrsLn + " " + CountryTxt + ", " + outletPostCd;
      errorInfo = "none";

      hideLoader("datepickers_main_div", "class");
    }

    this.setState({
      reservation_outlet_id: outletIdvl,
      reservation_outlet_postalcode: outletPostCd,
      reservation_outlet_name: outletName,
      reservation_outletaddress_line: outletAddrsLn,
      reservation_outlet_address: outletAddrs,
      datePickershow: 1,
    });

    var seletedOutletId = outletIdvl;

    var orderHandled = outletName;

    var dineInfo = [];
    dineInfo["orderOutletId"] = outletIdvl;
    dineInfo["orderOutletName"] = stripslashes(outletName);
    dineInfo["orderPostalCode"] = outletPostCd;
    dineInfo["orderTAT"] = outletTatTm;
    dineInfo["orderHandled"] = orderHandled;
    dineInfo["orderHandledByText"] = orderHandled;
    dineInfo["defaultAvilablityId"] = reservationId;

    this.setState({
      dineInfo: dineInfo,
      seletedAvilablityId: reservationId,
      seletedOutletId: outletIdvl,
      order_tat_time: outletTatTm,
      orderHandled: orderHandled,
      outlet_error: false,
    });

    var dineInfo = this.state.dineInfo;
    if (seletedOutletId !== "") {
      axios
        .get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            reservationId +
            "&outletId=" +
            seletedOutletId
        )
        .then((res) => {
          if (res.data.status === "success") {
            this.setState({ getDateTimeFlg: "yes" }, function () {
              this.displayBrandsdeatils();
            });
          } else {
            $(document).find(".order_date_value,.order_time_value").val("");
          }
        });
    } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    }

    // document.getElementById("error_info_div").style.display = errorInfo;
  }

  updateAdultQty(type) {
    var reservation_outlet_id = this.state.reservation_outlet_id;
    var currentAdult = this.state.adultValue;
    var newValue = "";
    if (reservation_outlet_id !== "") {
      if (type === "increment") {
        newValue = parseInt(currentAdult) + 1;
      } else if (type === "decrement") {
        if (parseInt(currentAdult) > 0) {
          newValue = parseInt(currentAdult) - 1;
        } else {
          newValue = 0;
        }
      }
      var childValue = this.state.childValue;
      if (newValue === 0) {
        childValue = 0;
      }
      this.setState(
        { adultValue: newValue, pax_error: false, childValue: childValue },
        function () {
          this.displayBrandsdeatils();
        }
      );
    } else {
      this.setState({ outlet_error: true }, function () {
        this.displayBrandsdeatils();
      });
    }
  }
  updateChildtQty(type) {
    if (parseInt(this.state.adultValue) > 0) {
      var reservation_outlet_id = this.state.reservation_outlet_id;
      var currentChild = this.state.childValue;
      if (reservation_outlet_id !== "") {
        var newValue = "";
        if (type === "increment") {
          newValue = parseInt(currentChild) + 1;
        } else if (type === "decrement") {
          if (parseInt(currentChild) > 0) {
            newValue = parseInt(currentChild) - 1;
          } else {
            newValue = 0;
          }
        }
        this.setState({ childValue: newValue }, function () {
          this.displayBrandsdeatils();
        });
      }
    }
  }

  back_reservation() {
    this.setState({ reservationConfirm: false }, function () {
      this.displayBrandsdeatils();
    });
  }

  submit_reservation() {
    var error = 0;
    if (this.state.reservation_outlet_id === "") {
      this.setState({ outlet_error: true });
      error++;
    } else {
      this.setState({ outlet_error: false });
    }
    if (error === 0) {
      if (this.state.adultValue === 0) {
        this.setState({ pax_error: true });
        error++;
      } else {
        this.setState({ pax_error: false });
      }
    }

    if (error === 0) {
      var UserId =
        cookie.load("UserId") != "" && cookie.load("UserId") != undefined
          ? cookie.load("UserId")
          : "";
      if (UserId === "") {
        $.magnificPopup.open({
          items: {
            src: "#login-popup",
          },
          type: "inline",
        });
      } else {
        this.setState({ reservationConfirm: true }, function () {
          this.displayBrandsdeatils();
        });
      }
    } else {
      var currentThis = this;
      setTimeout(function () {
        currentThis.displayBrandsdeatils();
      }, 100);
    }
  }

  reservation_order_submit() {
    showLoader("showLoader", "class");

    var reservation_order = Array();
    reservation_order["app_id"] = appId;
    reservation_order["order_source"] = "Web";
    reservation_order["time_slot_type_advanced"] = "2";
    reservation_order["reservation_status"] = 0;
    reservation_order["availability_id"] = reservationId;
    reservation_order["customer_fname"] = cookie.load("UserFname");
    reservation_order["customer_lname"] = cookie.load("UserLname");
    reservation_order["customer_email"] = cookie.load("UserEmail");
    reservation_order["customer_mobile_no"] = cookie.load("UserMobile");

    reservation_order["customer_id"] = cookie.load("UserId");
    reservation_order["reservation_datetime"] = format(
      new Date(cookie.load("orderDateTime").replaceAll('"', "")),
      "yyyy-MM-dd"
    );
    reservation_order["reservation_outlet_id"] =
      this.state.reservation_outlet_id;
    (reservation_order["reservation_outlet_time"] =
      "1-" +
      format(
        new Date(cookie.load("orderDateTime").replaceAll('"', "")),
        "HH:mm:ss"
      )),
      (reservation_order["reservation_time_slot"] = 1);

    reservation_order["reservation_outlet_name"] =
      this.state.reservation_outlet_name;
    reservation_order["reservation_no_of_adult_pax"] = this.state.adultValue;
    reservation_order["reservation_no_of_children_pax"] = this.state.childValue;
    reservation_order["reservation_instruction"] = this.state.reservation_notes;
    var reservationDate = format(
      new Date(cookie.load("orderDateTime").replaceAll('"', "")),
      "iii dd MMM yyyy, hh:mm aaa"
    );
    showLoader("res-cont-div", "class");
    this.setState({ finalreservationDate: reservationDate }, function () {
      this.props.getResOrdersubmit(reservation_order);
    });
  }

  displayBrandsdeatils() {
    var branddetails = (
      <div className="restaurant-info reservation-info">
        {this.state.reservation_local_order_no === "" && (
          <div className="restaurant-info-title">
            <h3>Reservation</h3>
          </div>
        )}
        {this.state.reservationConfirm === false && (
          <div className="restaurant-info-lhs">
            <ul class="bxslider">
              <li>
                <img
                  alt=""
                  src="https://neptune.ninjaos.com/media/custom_images/Artboard_1-8.png"
                />
              </li>
              <li>
                <img
                  alt=""
                  src="https://neptune.ninjaos.com/media/custom_images/Artboard_1-8.png"
                />
              </li>
              <li>
                <img
                  alt=""
                  src="https://neptune.ninjaos.com/media/custom_images/Artboard_1-8.png"
                />
              </li>
              <li>
                <img
                  alt=""
                  src="https://neptune.ninjaos.com/media/custom_images/Artboard_1-8.png"
                />
              </li>
            </ul>
            {/* <div id="bx-pager">
              <a data-slide-index="0" href="">
                {" "}
                <img
                  alt=""
                  src="https://neptune.ninjaos.com/media/custom_images/Artboard_1-8.png"
                />{" "}
              </a>{" "}
              <a data-slide-index="1" href="">
                {" "}
                <img
                  alt=""
                  src="https://neptune.ninjaos.com/media/custom_images/Artboard_1-8.png"
                />{" "}
              </a>{" "}
              <a data-slide-index="2" href="">
                {" "}
                <img
                  alt=""
                  src="https://neptune.ninjaos.com/media/custom_images/Artboard_1-8.png"
                />{" "}
              </a>{" "}
              <a data-slide-index="3" href="">
                {" "}
                <img
                  alt=""
                  src="https://neptune.ninjaos.com/media/custom_images/Artboard_1-8.png"
                />{" "}
              </a>
            </div> */}
          </div>
        )}
        <div
          className={
            this.state.reservationConfirm === true
              ? "restaurant-info-rhs reservation-full-width"
              : "restaurant-info-rhs"
          }
        >
          {this.state.reservation_local_order_no !== "" ? (
            <div className="confirm-area">
              <div className="thanks-orange">
                <p>
                  Your Reservation is Confirmed! <br />
                  Reservation details have been sent to
                  {this.state.customer_email}
                </p>
              </div>
              <div className="thanks-info">
                <ul>
                  <li>
                    <strong>
                      Confirmation ID:{" "}
                      <span>{this.state.reservation_local_order_no}</span>
                    </strong>
                  </li>
                  <li>
                    <i class="fa fa-user" aria-hidden="true"></i>
                    {this.state.first_name} {this.state.last_name}
                  </li>
                  <li>
                    <i class="fa fa-calendar-o" aria-hidden="true"></i>{" "}
                    {this.state.finalreservationDate}
                  </li>
                  <li>
                    <i class="fa fa-users" aria-hidden="true"></i>
                    {this.state.adultValue} Adults
                    {this.state.childValue > 0 &&
                      " " + this.state.childValue + " Child"}
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div className="reservation-frm">
              <div className="reservation-intial-frm">
                <div className="form-group selectav">
                  <Select
                    onChange={this.handleChangeOutlet.bind(this)}
                    options={this.state.listOutlet}
                    placeholder="Select Any One Outlet"
                  />
                  {this.state.outlet_error === true && (
                    <span className="error">Please select any one outlet</span>
                  )}
                </div>
                <div className="form-group calcav">
                  <OrderdatetimeSlot
                    {...this.props}
                    hdrState={this.state}
                    setdateTimeFlg={this.setdateTimeFlg}
                  />
                  <div className="ordrdatetime_error"></div>
                </div>
                <div className="form-group">
                  <div className="adult-kid">
                    <div className="res-qty">
                      <div className="up-res-ppl">
                        <div className="res-pm">
                          <p>Adults</p>
                        </div>
                        <div className="increator">
                          <div className="qty_bx">
                            <span
                              className="qty_minus"
                              onClick={this.updateAdultQty.bind(
                                this,
                                "decrement"
                              )}
                            >
                              -
                            </span>
                            <input
                              type="text"
                              name="adultValue"
                              value={this.state.adultValue}
                              readOnly
                            />
                            <span
                              className="qty_plus"
                              onClick={this.updateAdultQty.bind(
                                this,
                                "increment"
                              )}
                            >
                              +
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="up-res-ppl">
                        <div className="res-pm">
                          <p>Kids</p>
                        </div>
                        <div className="increator">
                          <div className="qty_bx">
                            <span
                              className="qty_minus"
                              onClick={this.updateChildtQty.bind(
                                this,
                                "decrement"
                              )}
                            >
                              -
                            </span>
                            <input
                              type="text"
                              className="proqty_input"
                              readOnly
                              value={this.state.childValue}
                            />
                            <span
                              className="qty_plus"
                              onClick={this.updateChildtQty.bind(
                                this,
                                "increment"
                              )}
                            >
                              +
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.pax_error === true && (
                      <span className="error">Please select Pax's</span>
                    )}
                  </div>
                </div>
                {this.state.reservationConfirm === false && (
                  <div className="form-group">
                    <div className="continue">
                      <a
                        href={void 0}
                        className="button"
                        onClick={this.submit_reservation.bind(this)}
                      >
                        Reserve Now
                      </a>
                    </div>
                  </div>
                )}
              </div>
              {this.state.reservationConfirm === true && (
                <div className="reservation-confirm-frm">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 nameav">
                        <input
                          type="text"
                          className="proqty_input"
                          name="mobile_number"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.first_name}
                          placeholder="First Name"
                        />
                        {this.state.error_first_name === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <input
                          type="text"
                          className="proqty_input"
                          name="mobile_number"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.last_name}
                          placeholder="Last Name"
                        />
                        {this.state.error_last_name === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 emailav">
                        <input
                          type="text"
                          className="proqty_input"
                          name="customer_email"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.customer_email}
                          readOnly={true}
                          placeholder="Email"
                        />
                        {this.state.error_customer_email === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mobileav">
                        <input
                          type="text"
                          className="proqty_input"
                          name="mobile_number"
                          onChange={this.handleChange.bind(this)}
                          value={this.state.mobile_number}
                          readOnly={true}
                          placeholder="Mobile Number"
                        />
                        {this.state.error_mobile_number === true && (
                          <span className="error">This field is required.</span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-12 col-sm-12 textav">
                        <div className="special-instructions">
                          <textarea
                            placeholder="Enter your special instruction here..."
                            className="reservation_notes"
                            maxLength="500"
                            rows="3"
                            name="reservation_notes"
                            value={this.state.reservation_notes}
                            onChange={this.handleChange.bind(this)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group cc-btn">
                    <div className="continue">
                      <a
                        href={void 0}
                        className="button"
                        onClick={this.back_reservation.bind(this)}
                      >
                        Back
                      </a>
                    </div>
                    <div className="continue res-cont-div">
                      <a
                        href={void 0}
                        className="button"
                        onClick={this.reservation_order_submit.bind(this)}
                      >
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );

    this.setState({ brandDetails: branddetails }, function () {
      $(".bxslider").bxSlider({ pagerCustom: "#bx-pager" });
    });
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState(
        { seleted_ord_date: value, seleted_ord_time: ordTime },
        function () {
          this.setOrderOutletDateTimeData();
        }
      );
    } else if (field == "ordTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_time: tmSltArr["startTime"] }, function () {
        this.setOrderOutletDateTimeData();
      });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;

    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
      var OrderHours = seletedOrdTime.getHours();
      var OrderMunts = seletedOrdTime.getMinutes();
      var OrderSecnd = seletedOrdTime.getSeconds();
      var orderDateTime = new Date(OrderDate);
      orderDateTime.setHours(OrderHours);
      orderDateTime.setMinutes(OrderMunts);
      orderDateTime.setSeconds(OrderSecnd);

      var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
      var deliveryTime =
        this.convPad(OrderHours) +
        ":" +
        this.convPad(OrderMunts) +
        ":" +
        this.convPad(OrderSecnd);

      cookie.save("orderDateTime", orderDateTime, { path: "/" });
      cookie.save("deliveryDate", deliveryDate, { path: "/" });
      cookie.save("deliveryTime", deliveryTime, { path: "/" });
    }
  }
  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  render() {
    var banner_name = "";

    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />

        <div className="innerbanner">
          <img
            src="https://neptune.ninjaos.com/media/custom_images/0441bd6add9ac593183083b7ceb9ebed.png"
            alt=""
          />
        </div>

        <div className="container restaurant-main">
          {this.state.brandDetails}
        </div>

        <div id="dvLoading"></div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var highlightproducts = Array();
  var highlightstatus = "";
  if (Object.keys(state.highlightproductlist).length > 0) {
    if (
      state.highlightproductlist[0].status === "ok" &&
      Object.keys(state.highlightproductlist[0].result_set).length > 0
    ) {
      highlightproducts = state.highlightproductlist[0].result_set;
      highlightstatus = "success";
    } else {
      highlightstatus = "failure";
    }
  }

  var reservationdata = [];
  var RerservationStatus = "";

  if (Object.keys(state.reservationordersubmit).length > 0) {
    if (
      state.reservationordersubmit[0].status === "ok" &&
      Object.keys(state.reservationordersubmit[0].common).length > 0
    ) {
      RerservationStatus = "success";
      reservationdata = state.reservationordersubmit[0];
    } else {
      RerservationStatus = "failure";
    }
  }

  return {
    globalsettings: state.settings,
    brandlist: state.brands,
    brandsmalls: state.brandsmalls,
    highlightproducts: highlightproducts,
    highlightstatus: highlightstatus,
    RerservationStatus: RerservationStatus,
    reservationdata: reservationdata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getBrands: (brand_slug) => {
      dispatch({ type: GET_BRANDS, brand_slug });
    },
    getBrandsMalls: (brand_slug, postal_code) => {
      dispatch({ type: GET_BRANDS_MALLS, brand_slug, postal_code });
    },
    getHighlightList: (params) => {
      dispatch({ type: GET_HIGHLIGHTPROLIST, params });
    },
    getResOrdersubmit: (resOrderFlg) => {
      dispatch({ type: GET_RES_ORDER_SUBMIT, resOrderFlg });
    },
  };
};

Brands.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Brands));
