/* eslint-disable */

if (location.protocol !== "https:") {
  var locationULR = location.href.replace("www.", "");
  location.href = locationULR.replace("http://", "https://www.");
} else if (location.protocol === "https:") {
  var locationULR = location.href;
  if (locationULR.indexOf("www.") < 0) {
    location.href = "https://www." + locationULR.replace("https://", "");
  }
}
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
///import Restaurants from "./components/Restaurants/Index";
import Products from "./components/Products/Index";
import ProductDetail from "./components/Products/ProductDetailPage";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
import Pdsa from "./components/Pages/Pdsa";
import Faq from "./components/Pages/Faq";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Myorderdetails from "./components/Myaccount/Myorderdetails";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Myreservation from "./components/Myaccount/Myreservation";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Brands from "./components/Brands/Brands";
import BrandList from "./components/Brands/BrandList";
import Mall from "./components/Mall/Mall";
import MultiplePlace from "./components/Multiplemarket/Multiplemarket";
import OUtlets from "./components/Pages/Outlets";
import Myvouchers from "./components/Myaccount/Myvouchers";

import Deals from "./components/Deals/Deals";
import Reservationthankyou from "./components/Reservation/Reservationthankyou";
import Reservationsuggestion from "./components/Reservation/Reservationsuggestion";
import Reservation from "./components/Reservation/Reservation";

import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/shop" component={Home} />
        {/*<Route exact path="/brands" component={Home} />
				<Route path={"/restaurant/:sluglocation"} component={Restaurants} />*/}
        <Route path={"/mall/:sluglocation"} component={Mall} />
        <Route path={"/marketplace"} component={MultiplePlace} />
        <Route path={"marketplace/:retail"} component={MultiplePlace} />

        {/*<Route path={
		            "/grouporder/:slugType/:slugMallname/:outletSlug/:orderID/:outletID/:deliveryDate/:orderDateTime/:deliveryTime" }
		          component={Mall}
		        />*/}

        <Route
          path={
            "/grouporder/:slugType/:slugMallname/:outletSlug/:orderID/:outletID/:deliveryDate/:orderDateTime/:deliveryTime/:orderdeliverycode/:orderType/:locationId"
          }
          component={Mall}
        />

        <Route
          path={
            "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue"
          }
          component={ProductDetail}
        />

        <Route
          path={"/food/:siteLocation/:slugRestaurant/:slugType/:slugValue"}
          component={Products}
        />
        <Route
          path={"/food/:siteLocation/:slugRestaurant/"}
          component={Products}
        />

        <Route
          path={
            "/retail/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue"
          }
          component={Products}
        />
        <Route
          path={"/retail/:siteLocation/:slugRestaurant/:slugType/:slugValue"}
          component={Products}
        />
        <Route
          path={"/retail/:siteLocation/:slugRestaurant/"}
          component={Products}
        />

        {/*<Route path={"/retail/:sluglocation"} component={Restaurants} />
				<Route path={"/retail"} component={Restaurants} />*/}

        {/*<Route path={"/retail"} component={Mall} />*/}

        <Route path={"/retail/:sluglocation"} component={Mall} />

        {/*<Route
					path={
						"/events/:slugEvent/:slugRestaurant/:slugType/:slugValue/:proValue"
					}
					component={Products}
				/>

				<Route
					path={"/events/:slugEvent/:slugRestaurant/:slugType/:slugValue"}
					component={Products}
				/>
				<Route
					path={"/events/:slugEvent/:slugRestaurant/"}
					component={Products}
				/>*/}

        {/*<Route path={"/events/:slugEvent/"} component={Restaurants} />
				<Route path={"/events"} component={Restaurants} />*/}

        <Route path="/checkout" component={Checkout} />
        <Route
          path="/thankyouorderconfirmation/:orderId"
          component={Thankyou}
        />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/faq" component={Faq} />
        <Route path="/terms-of-use" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/about-us" component={Pages} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/pdpa-consent" component={Pdsa} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myvouchers" component={Myvouchers} />
        <Route path="/myorders/:orderId" component={Myorderdetails} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myreservations" component={Myreservation} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/brands/:brandSlug/reservations" component={Brands} />
        <Route path="/reservations" component={Reservation} />
        <Route
          path="/reservation-thankyouorderconfirmation/:orderId"
          component={Reservationthankyou}
        />
        <Route
          path="/reservation_suggest/:acceptreject/:resid"
          component={Reservationsuggestion}
        />
        <Route path="/brands/:brandSlug" component={Brands} />
        <Route path={"/brandlist"} component={BrandList} />
        <Route path={"/locations"} component={OUtlets} />

        <Route path={"/deals"} component={Deals} />

        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
