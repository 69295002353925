/* eslint-disable */
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cookie from "react-cookies";
import Slider from "react-slick";

import { GET_ACTIVITYCOUNT } from "../../actions";

var settingsMyAcc = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1191,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      },
    },
  ],
};

class Myaccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_all: 0,
      overall_orders: 0,
      reservation_count: 0,
      activitycount: [],
    };
  }

  componentDidMount() {
    this.getActivityCountsNew();
  }

  getActivityCountsNew() {
    const inputKeys = ["order_all", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            activitycount: nextProps.activitycount,
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            reservation_count:
              nextProps.activitycount[0].result_set.reservation_orders,
          });
        }
      }
    }
  }

  render() {
    return (
      <div className="mainacc_menuout">
        <ul className="mainacc_menulist">
          <li
            className={this.props.currentPage === "myaccount" ? "active" : ""}
          >
            <Link to="/myaccount" title="My Account">
              <span>My Info</span>
            </Link>
          </li>
          <li className={this.props.currentPage === "myorders" ? "active" : ""}>
            <Link to="/myorders" title="My Orders">
              <span>Orders</span>
              {parseFloat(this.state.overall_orders) > 0 &&
                cookie.load("userAccountType") === "2" && (
                  <span id="masterCount">{this.state.overall_orders}</span>
                )}
            </Link>
          </li>
          <li
            className={
              this.props.currentPage === "myreservations" ? "active" : ""
            }
          >
            <Link to="/myreservations" title="My Reservation">
              <span>Reservation</span>
              {parseFloat(this.state.reservation_count) > 0 &&
                cookie.load("userAccountType") === "2" && (
                  <span id="masterCount">{this.state.reservation_count}</span>
                )}
            </Link>
          </li>
          <li className={this.props.currentPage === "rewards" ? "active" : ""}>
            <Link to="/rewards" title="My Rewards">
              <span>Rewards</span>
            </Link>
          </li>
          <li
            className={
              this.props.currentPage === "mypromotions" ? "active" : ""
            }
          >
            <Link to="/mypromotions" title="My Promotions">
              <span>Promotions</span>
            </Link>
          </li>
          <li
            className={this.props.currentPage === "myvouchers" ? "active" : ""}
          >
            <Link to="/myvouchers" title="My Vouchers">
              <span>Vouchers</span>
            </Link>
          </li>
        </ul>
        <div className="mbacc_mslidersec mbacc_mslider">
          <Slider {...settingsMyAcc}>
            <div
              className={
                this.props.currentPage === "myaccount"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myaccount" title="My Account">
                <span>My Info</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentPage === "myorders"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myorders" title="My Orders">
                <span>Orders</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentPage === "myreservations"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myreservations" title="My Orders">
                <span>Reservation</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentPage === "rewards"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/rewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentPage === "mypromotions"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/mypromotions" title="My Promotions">
                <span>Promotions</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentPage === "myvouchers"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myvouchers" title="My Vouchers">
                <span>Vouchers</span>
              </Link>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Myaccount.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Myaccount)
);
