/* eslint-disable */
import { push } from "react-router-redux";
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_PRODUCT_DETAIL, SET_PRODUCT_DETAIL } from "../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";

export const watchGetProductDetail = function* () {
  yield takeEvery(GET_PRODUCT_DETAIL, workerGetProductDetail);
};

function* workerGetProductDetail({ proSlug, outletID }) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");
    var orderOutletId =
      outletID !== "" &&
      typeof outletID !== undefined &&
      typeof outletID !== "undefined"
        ? outletID
        : cookie.load("orderOutletId") === undefined ||
          cookie.load("orderOutletId") == ""
        ? ""
        : cookie.load("orderOutletId");

    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;

    const uri =
      apiUrl +
      "products/products_list?app_id=" +
      appId +
      "&product_slug=" +
      proSlug +
      "&availability=" +
      availabilityId +
      "&outlet=" +
      orderOutletId +
      spicelPride;

    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    /*yield all([put({ type: SET_PRODUCT_DETAIL, value: resultArr }), put(push(`/products`))]);*/
    yield put({ type: SET_PRODUCT_DETAIL, value: resultArr });
    /*yield put(push('/products'));*/
  } catch {
    console.log("Get Products Failed");
  }
}
