/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";

import {
  stripslashes,
  addressFormat,
  showAlert,
  showPriceValue,
} from "../Helpers/SettingHelper";
import { appId, deliveryId, apiUrlNotify, apiUrl } from "../Helpers/Config";
import thnkyou_tick_img from "../../common/images/tick.png";
import scootyImg from "../../common/images/scooty.png";
import pickupImg from "../../common/images/lunch.svg";
import cartMpImg from "../../common/images/cart-map.png";
import { GET_ORDER_DETAIL } from "../../actions";

var Parser = require("html-react-parser");

class Myorderdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderdetail: [],
      overall_orders: 0,
    };
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    var chkOrderid =
      typeof cookie.load("ChkOrderid") === "undefined"
        ? ""
        : cookie.load("ChkOrderid");
    if (orderId !== "") {
      //&& chkOrderid === orderId
      this.props.getOrderDetail(orderId);
      this.sendOrderSMSNotification(orderId);
      /*this.sendNotification();*/
    } else {
      showAlert("Notice", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      //this.props.history.push("/");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.orderdetail !== nextProps.orderdetail) {
      this.setState({ orderdetail: nextProps.orderdetail }, function () {
        var result = nextProps.orderdetail;
        if (result.length > 0) {
          if (result[0].status === "ok") {
            var order = result[0].result_set[0];
            var productList = [];
            if (
              cookie.load("gtmorderID") !== order.order_local_no &&
              order.order_update_gtm !== "1"
            ) {
              if (Object.keys(order.items).length > 0) {
                var orderItems = order.items;
                Object.keys(order.items).map((index) => {
                  if (orderItems[index].items.length > 0) {
                    if (orderItems[index].items[0].length > 0) {
                      orderItems[index].items[0].map((item) => {
                        productList.push({
                          name: item.item_name,
                          id: item.item_product_id,
                          price: item.item_total_amount,
                          brand: order.items[index].outlet,
                          category: item.pro_cate_name,
                          variant: "",
                          quantity: item.item_qty,
                          coupon: "",
                        });
                      });
                    }
                  }
                });
              }

              window.dataLayer.push({
                ecommerce: {
                  purchase: {
                    actionField: {
                      id: order.order_local_no,
                      affiliation: "Online Store",
                      revenue: order.order_total_amount,
                      tax: order.order_tax_charge,
                      shipping:
                        parseFloat(order.order_delivery_charge) +
                        parseFloat(order.order_additional_delivery),
                      coupon:
                        order.order_promocode_name !== "" &&
                        order.order_promocode_name !== null
                          ? order.order_promocode_name
                          : "",
                    },
                    products: productList,
                  },
                },
              });
            }
            cookie.save("gtmorderID", order.order_local_no);
          }
        }
      });
    }
  }

  sendOrderSMSNotification(orderId) {
    axios
      .get(
        apiUrl +
          "ordersv1/send_order_sms?app_id=" +
          appId +
          "&order_number=" +
          orderId
      )
      .then((res) => {});
  }
  sendNotification() {
    axios.get(apiUrlNotify + "order_notify").then((res) => {});
  }

  loadItems(orderDetail, ordergroups) {
    if (Object.keys(orderDetail).length > 0) {
      var cartItems = orderDetail["items"];

      return Object.keys(cartItems).map((index) => {
        var outlet = cartItems[index].outlet;
        return (
          <div className="outlet-product-inner" key={index}>
            <div className="outlet-name">{outlet}</div>
            {Object.keys(cartItems[index].items).map((index1) => {
              let personName = "";
              if (index1 === "0") {
                personName = "Yours";
              } else {
                if (
                  ordergroups[index1] !== "" &&
                  typeof ordergroups[index1] !== undefined &&
                  typeof ordergroups[index1] !== "undefined"
                ) {
                  personName = ordergroups[index1].order_member_name;
                }
              }
              return (
                <>
                  {Object.keys(ordergroups).length > 0 && (
                    <div className="cart-person-name">{personName}</div>
                  )}
                  {this.loadItemsList(cartItems[index].items[index1])}
                </>
              );
            })}
          </div>
        );
      });
    }
  }

  loadItemsList(orderDetail) {
    if (orderDetail.length > 0) {
      var cartItems = orderDetail;
      return cartItems.map((item, index) => (
        <div className="outlet-product-parent" key={index}>
          <div className="cart_row">
            <div className="row">
              <div className="col-xs-9 cart_left">
                <div className="cart_info">
                  <div class="up-cart-qty">
                    <div class="qty_bx">
                      <label
                        type="text"
                        data-qtyval="0"
                        class="combo-input-label combo-input combolst_qty_value"
                      >
                        {item.item_qty + "x"}
                      </label>
                    </div>
                  </div>

                  <h4>
                    {stripslashes(item.item_name)} X {item.item_qty}
                  </h4>

                  {this.loadModifierItems(
                    "Component",
                    item.modifiers,
                    item.set_menu_component
                  )}

                  {item.item_specification !== "" && (
                    <p className="help-block">
                      {stripslashes(item.item_specification)}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-xs-3 cart_right text-right">
                <div className="cart_price">
                  <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+$" + comboPrice + ")" : "";
        var comboQtyChk = comboQty !== "" ? parseInt(comboQty) : 0;
        if (comboQtyChk > 0) {
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+$" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }
  sateValChange = (field, value) => {};

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };
    let orderArr = this.props.orderdetail;
    var orderDetail = [];
    var ordergroups = [];

    if (Object.keys(orderArr).length > 0) {
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
        ordergroups = orderArr[0].ordergroups;
      }
    }

    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        {/* <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Thank You</p>
          </div>
        </div> */}

        {/* container - start */}
        <div className="container">
          <div className="mainacc_toptext">
            <h2>My Account</h2>
            <p>
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </p>
          </div>
          <div className="mainacc_menuout">
            <ul className="mainacc_menulist">
              <li>
                <Link to="/myaccount" title="My Account">
                  <span>My Info</span>
                </Link>
              </li>
              <li className="active">
                <Link to="/myorders" title="My Orders">
                  <span>Orders</span>
                  {parseFloat(this.state.overall_orders) > 0 && (
                    <span id="masterCount">{this.state.overall_orders}</span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="/rewards" title="My Rewards">
                  <span>Rewards</span>
                </Link>
              </li>
              <li>
                <Link to="/mypromotions" title="My Promotions">
                  <span>Promotions</span>
                </Link>
              </li>
              <li>
                <Link to="/myvouchers" title="My Vouchers">
                  <span>Vouchers</span>
                </Link>
              </li>
            </ul>
            <div className="mbacc_mslidersec mbacc_mslider">
              <Slider {...settingsMyAcc}>
                <div className="mbacc_mslide">
                  <Link to="/myaccount" title="My Account">
                    <span>My Info</span>
                  </Link>
                </div>
                <div className="mbacc_mslide active">
                  <Link to="/myorders" title="My Orders">
                    <span>Orders</span>
                  </Link>
                </div>
                <div className="mbacc_mslide">
                  <Link to="/rewards" title="My Rewards">
                    <span>Rewards</span>
                  </Link>
                </div>
                <div className="mbacc_mslide">
                  <Link to="/mypromotions" title="My Promotions">
                    <span>Promotions</span>
                  </Link>
                </div>
                <div className="mbacc_mslide">
                  <Link to="/myvouchers" title="My Vouchers">
                    <span>Vouchers</span>
                  </Link>
                </div>
              </Slider>
            </div>
          </div>
          {/* innersection_wrap - start */}
          {Object.keys(orderDetail).length > 0 && (
            <div className="innersection_wrap tnk-you">
              {/*<div className="mainacc_toptext tick">
                <img src={thnkyou_tick_img} />
                <h2>Thank You</h2>
                <p>Your order has been placed successfully</p>
              </div>*/}

              {/* order-detail-maindiv - start */}
              <div className="tnkyou-upmarket">
                <div className="thank-order-lhs">
                  <div className="thank-order-detaildiv">
                    <div className="tnk-detail">
                      <h2>YOUR ORDER DETAILS</h2>
                      <div className="tnk-order tnky-indiv">
                        <h3>Order No - {orderDetail.order_local_no}</h3>
                        <p>
                          Order placed at :{" "}
                          {Moment(orderDetail.order_created_on).format(
                            "DD-MM-YYYY hh:mm A"
                          )}
                          <br></br>Pay by : {orderDetail.order_method_name}
                        </p>
                      </div>
                    </div>

                    <div className="tnk-detail mobile-detail">
                      {/*<h2>YOUR ORDER DETAILS</h2>*/}
                      <div className="tnk-order tnky-indiv">
                        {/* <h3>Order No - {orderDetail.order_local_no}</h3> */}
                        {/*<p>
                        Order placed at :{" "}
                        {Moment(orderDetail.order_created_on).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                        <br></br>Pay by : {orderDetail.order_method_name}
                      </p>*/}
                      </div>
                    </div>

                    <div className="tnk-delivery">
                      {orderDetail.order_availability_id === deliveryId ? (
                        <div className="delivery-cart-div">
                          <div className="cart-direction">
                            <img
                              className="cart-direction-left"
                              src={scootyImg}
                            />
                            <img
                              className="cart-direction-right"
                              src={cartMpImg}
                            />
                          </div>
                          <div className="cart_row cart-header-first">
                            <div className="row">
                              <div className="col-xs-6 cart_left text-left">
                                <h4>Order Handling By</h4>
                                <p>{stripslashes(orderDetail.outlet_name)}</p>
                                <p>
                                  {addressFormat(
                                    orderDetail.outlet_unit_number1,
                                    orderDetail.outlet_unit_number2,
                                    orderDetail.outlet_address_line1,
                                    orderDetail.outlet_address_line2,
                                    orderDetail.outlet_postal_code
                                  )}
                                </p>
                              </div>
                              {/* <div className="col-xs-6 cart_right upmarket-col-xs-12"> */}
                              <div className="col-xs-6 cart_right text-right">
                                <h4>Delivery address</h4>
                                <p>
                                  {addressFormat(
                                    orderDetail.order_customer_unit_no1,
                                    orderDetail.order_customer_unit_no2,
                                    orderDetail.order_customer_address_line1,
                                    orderDetail.order_customer_address_line2,
                                    orderDetail.order_customer_postal_code
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="pickup-cart-div">
                          <div className="cart-direction">
                            <img
                              className="cart-direction-left"
                              src={pickupImg}
                            />
                          </div>
                          <div className="cart_row cart-header-first">
                            <div className="row">
                              <div className="col-xs-12 cart_left text-center">
                                <h4>Pickup Location</h4>
                                <p>{stripslashes(orderDetail.outlet_name)}</p>
                                <p>
                                  {addressFormat(
                                    orderDetail.outlet_unit_number1,
                                    orderDetail.outlet_unit_number2,
                                    orderDetail.outlet_address_line1,
                                    orderDetail.outlet_address_line2,
                                    orderDetail.outlet_postal_code
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <br />

                      <div className="delivery_total delivery_total_number delivery_datetime_div">
                        <div className="delivery_total_left">
                          <div className="tnky-date-info">
                            <h2>
                              {orderDetail.order_availability_id === deliveryId
                                ? "Date & time"
                                : "Pickup"}{" "}
                              {orderDetail.order_availability_id === deliveryId
                                ? ""
                                : "Pickup"}{" "}
                            </h2>
                          </div>

                          <h4 className="checkoutDate tnky-checkdate">
                            {Moment(orderDetail.order_date).format(
                              "DD-MM-YYYY"
                            )}
                            , {Moment(orderDetail.order_date).format("hh:mm A")}
                          </h4>
                        </div>
                        {/*<div className="delivery_total_left delivery_total_right">
                        
                      </div>*/}
                      </div>

                      <div className="tnkyousummary mobile-summary">
                        <h4>order summary</h4>
                      </div>

                      {/*<div className="hcart_tt">
                      <div className="col-sm-cls text-left">
                        <h3>Your Items</h3>
                      </div>
                    </div>*/}

                      <div className="tnk-chk-order tnk-uporder">
                        <Link to={"/myorders"} className="button">
                          Go Back Orders
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="thank-order-rhs">
                  <div className="orderitem_body_div">
                    <div className="tnk-detail destop-detail">
                      {/*<h2>YOUR ORDER DETAILS</h2>*/}
                      <div className="tnk-order tnky-indiv">
                        {/* <h3>Order No - {orderDetail.order_local_no}</h3> */}
                        {/*<p>
                        Order placed at :{" "}
                        {Moment(orderDetail.order_created_on).format(
                          "DD-MM-YYYY hh:mm A"
                        )}
                        <br></br>Pay by : {orderDetail.order_method_name}
                      </p>*/}
                      </div>
                    </div>

                    {this.loadItems(orderDetail, ordergroups)}
                    {orderDetail.order_remarks !== "" && (
                      <div className="remark_notesec text-left">
                        <h4>Remarks</h4>
                        <p>{orderDetail.order_remarks}</p>
                      </div>
                    )}
                  </div>

                  <div className="cart_footer tnk_cart_footer">
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-xs-cls">
                          <p className="text-uppercase">SUBTOTAL</p>
                        </div>
                        <div className="col-xs-cls text-right">
                          <span>${orderDetail.order_sub_total}</span>
                        </div>
                      </div>
                    </div>

                    {parseFloat(orderDetail.order_delivery_charge) > 0 && (
                      <div className="cart_row">
                        <div className="row-replace">
                          <div className="col-xs-cls">
                            <p className="text-uppercase">Delivery Charge</p>
                          </div>
                          <div className="col-xs-cls text-right">
                            <span>
                              $
                              {parseFloat(
                                orderDetail.order_delivery_charge
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {parseFloat(orderDetail.order_additional_delivery) > 0 && (
                      <div className="cart_row">
                        <div className="row-replace">
                          <div className="col-xs-cls">
                            <p className="text-uppercase">
                              Additional Delivery Charge
                            </p>
                          </div>
                          <div className="col-xs-cls text-right">
                            <span>
                              $
                              {parseFloat(
                                orderDetail.order_additional_delivery
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {(parseFloat(orderDetail.order_discount_amount) > 0 ||
                      (orderDetail.order_promocode_name !== "" &&
                        orderDetail.order_promocode_name !== null)) && (
                      <div className="cart_row">
                        <div className="row-replace">
                          <div className="col-xs-cls">
                            <p className="text-uppercase">
                              {orderDetail.order_discount_type === "redeem"
                                ? "Discount (-)"
                                : orderDetail.order_promocode_name !== ""
                                ? "Promocode (" +
                                  orderDetail.order_promocode_name +
                                  ")"
                                : "Promocode (-)"}
                            </p>
                          </div>
                          <div className="col-xs-cls text-right">
                            {parseFloat(orderDetail.order_discount_amount) >
                              0 && (
                              <span>
                                $
                                {parseFloat(
                                  orderDetail.order_discount_amount
                                ).toFixed(2)}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {parseFloat(orderDetail.order_voucher_discount_amount) >
                      0 && (
                      <div className="cart_row">
                        <div className="row-replace">
                          <div className="col-xs-cls">
                            <p className="text-uppercase">
                              Voucher Discount Amount
                            </p>
                          </div>
                          <div className="col-xs-cls text-right">
                            <span>
                              $
                              {parseFloat(
                                orderDetail.order_voucher_discount_amount
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {parseFloat(orderDetail.order_tax_calculate_amount) > 0 && (
                      <div className="cart_row">
                        <div className="row-replace">
                          <div className="col-xs-cls">
                            <p className="text-uppercase">
                              GST({parseFloat(orderDetail.order_tax_charge)}%)
                            </p>
                          </div>
                          <div className="col-xs-cls text-right">
                            <span>
                              $
                              {parseFloat(
                                orderDetail.order_tax_calculate_amount
                              ).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="cart_row grant-total-cls">
                      <div className="row-replace">
                        <div className="col-xs-cls text-left">
                          <p className="text-uppercase">Total</p>
                        </div>
                        <div className="col-xs-cls text-right">
                          <span>
                            <sup>$</sup>
                            {parseFloat(orderDetail.order_total_amount).toFixed(
                              2
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    {orderDetail.charity !== "" && (
                      <div className="cart_row cart_footer_totrow charity-total-cls">
                        <div className="row">
                          <div className="col-xs-7">
                            <p className="text-uppercase">Charity</p>
                          </div>
                          <div className="col-xs-5 text-right">
                            <span>
                              {showPriceValue(
                                orderDetail.charity.charity_amount
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* order-detail-maindiv - end */}
            </div>
          )}
          {/* innersection_wrap - start */}
        </div>
        {/* container - end */}

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    orderdetail: state.orderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Myorderdetails);
