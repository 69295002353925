/* eslint-disable */
import React, { Component } from "react";

import { Link } from "react-router-dom";

import Slider from "react-slick";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { stripslashes, hideLoader, showAlert } from "../Helpers/SettingHelper";

import { appId, apiUrl, apiUrlV2, timThumpUrl } from "../Helpers/Config";

var Parser = require("html-react-parser");

import update from "immutability-helper";

import { validated } from "react-custom-validation";
import validator from "validator";

import axios from "axios";
import cookie from "react-cookies";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { GET_REQUESTPAGEDATA, GET_GLOBAL_SETTINGS } from "../../actions";

var Parser = require("html-react-parser");

class Pdsa extends Component {
  constructor(props) {
    super(props);
    this.state = { pagedata: [], pagedetail: "", pagetitle_txt: "" };

    if (
      this.props.match.params !== "" &&
      this.props.match.params.page_slug !== undefined
    ) {
      var page_slug = this.props.match.params.page_slug;
    } else {
      var page_slug = this.props.match.url.replace(/\\|\//g, "");
    }
    //const { page_slug } = this.props.match.params;
    this.props.getGlobalSettings();
    this.props.getRequestpage(page_slug);
  }

  componentDidMount() {

  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentWillReceiveProps(nextProps) {

   if(nextProps.match.path !== this.props.match.path) {
      if ($(".trigger_menu").length > 0) {
        $(".trigger_menu").toggleClass("active");
        if ($(".hmenu_list").hasClass("open")) {
          $(".mega_menu").slideUp();
        }
        $(".hmenu_list").toggleClass("open");
      }
      var pageslug = nextProps.match.path.replace(/\\|\//g, "");
      this.props.getRequestpage(pageslug);
    }

    if (nextProps.pagedata !== this.state.pagedata) {
      $(".dvLoadrCls").fadeOut(500);
      var pageDetails = "";
      var pageTitleTxt = "";
      if (Object.keys(nextProps.pagedata).length > 0) {
        var pagedataTxt = nextProps.pagedata[0].cmspage_description;
        pageTitleTxt = nextProps.pagedata[0].cmspage_title;
        pageDetails = pagedataTxt !== "" ? Parser(pagedataTxt) : "";
      }

      this.setState({
        pagedata: nextProps.pagedata,
        pagedetail: pageDetails,
        pagetitle_txt: pageTitleTxt,
      });
    }

  }

  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="contactusmain-top-div">
        <Header sateValChange={this.sateValChange} />

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Personal Data Protection Act</p>
          </div>
        </div>

        <div className="contact_wrap">
          <div className="contactus_wrapper">
            <div className="container">
               <p>{this.state.pagetitle_txt}</p>

               <p>{this.state.pagedetail}</p>

            </div>
          </div>
        </div>

        <Footer />

        <div id="dvLoading1234"></div>
      </div>
    );
  }
}




const mapStateToProps = (state) => {

  var pagedataRst = Array();
  if (Object.keys(state.pagedata).length > 0) {
    if (state.pagedata[0].status === "ok") {
      pagedataRst = state.pagedata[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
     pagedata: pagedataRst,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    }
  };
};

Pdsa.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Pdsa)
);
