/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { format } from "date-fns";
import { appId, apiUrl, apiUrlV2, deliveryId } from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";
import groupdark from "../../common/images/group.png";
import proVouchar from "../../common/images/pro-voucher.png";
import cashVouchar from "../../common/images/cash-voucher.png";
import smartPhone from "../../common/images/smart-phone.png";

import { GET_PRODUCT_LIST, GET_CART_DETAIL } from "../../actions";
import { th } from "date-fns/locale";
var Parser = require("html-react-parser");
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    var shopType =
      cookie.load("shopType") !== "" &&
      typeof cookie.load("shopType") !== undefined &&
      typeof cookie.load("shopType") !== "undefined"
        ? cookie.load("shopType")
        : 1;
    var pathname = this.props.location.pathname.split("/");
    var currentShopType = "";
    if (pathname[1] == "food") {
      currentShopType = "1";
    } else if (pathname[1] == "retail") {
      currentShopType = "2";
    } else if (pathname[1] == "events") {
      currentShopType = "3";
    }
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    var orderZoneId =
      cookie.load("orderZoneId") !== "" &&
      typeof cookie.load("orderZoneId") !== undefined &&
      typeof cookie.load("orderZoneId") !== "undefined"
        ? cookie.load("orderZoneId")
        : "";
    this.state = {
      selectedNavigation: "",
      settings: [],
      shopType: shopType,
      currentShopType: currentShopType,
      orderZoneId: orderZoneId,
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      outlet_slug: this.props.match.params.slugRestaurant,
      allProductList: "",
      imagesource: "",
      cartOutletList: [],
      outletslist: [],
      totalCartCount: 0,
      orderOutletId:
        cookie.load("orderOutletId") !== "" &&
        typeof cookie.load("orderOutletId") !== undefined
          ? cookie.load("orderOutletId")
          : "",
      shopeClosed: 0,
      defaultAvilTy: avilablityIdTxt,
      updateproductList: [],
      loadlistgtm: false,
      voucherProductDetail: [],
      voucher_product_qty: "",
      voucher_name: "",
      voucher_mobile: "",
      voucher_email: "",
      voucher_message: "",
      error_voucher_name: "",
      error_voucher_email: "",
      error_voucher_message: "",
      error_voucher_mobile: "",
      voucherIndexFlag: "",
      customerId:
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId"),
      outlet_id:
        typeof cookie.load("orderOutletId") === "undefined"
          ? ""
          : cookie.load("orderOutletId"),
      loadingOutletTimeStatus: false,
    };
  }

  componentDidMount() {
    this.props.getCartDetail();
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    let outletslist = nextProps.productState.outletslist;
    let totalCartCount = nextProps.productState.totalCartCount;
    if (totalCartCount != this.state.totalCartCount) {
      this.setState({ totalCartCount: totalCartCount });
    }
    if (nextProps.productState.loadproduct === "Yes") {
      //if (selectedNavigation !== this.state.selectedNavigation) {

      if (selectedNavigation !== "") {
        var catSlug = selectedNavigation;
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }
        this.props.sateValChange("loadproduct", "No");
        if (this.state.loadingOutletTimeStatus === false) {
          this.setState({ loadingOutletTimeStatus: true }, function () {
            this.checkTimeAvilablity(
              nextProps.productState.navigateMenu,
              this.state.outlet_slug
            );
          });
        }

        //this.props.getProductList(catSlug, subcatSlug, this.state.outlet_slug);
      }
      var outlets_list = [];
      if (outletslist.length > 0) {
        outlets_list = outletslist[0];
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
        outletslist: outlets_list,
      });
    }
    if (nextProps.productState.applyFilter === "Y") {
      this.props.sateValChange("applyFilter", "N");
      this.productsubcatlist(
        this.state.updateproductList,
        nextProps.productState.navigateMenu
      );
    }

    if (nextProps.productState.settings !== this.state.settings) {
      if (
        nextProps.productState.settings !== "" &&
        typeof nextProps.productState.settings !== undefined &&
        typeof nextProps.productState.settings !== "undefined"
      ) {
        this.setState({ settings: nextProps.productState.settings });
      }
    }
    if (this.state.cartOutletList !== nextProps.cartOutletList) {
      this.setState({ cartOutletList: nextProps.cartOutletList });
    }
  }

  checkTimeAvilablity(navigateMenu, outlet_slug) {
    if (this.state.orderOutletId !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";

      if (deliveryTime !== "") {
        Axios.get(
          apiUrlV2 +
            "settings/chkOutletTimesAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            this.state.defaultAvilTy +
            "&outletID=" +
            this.state.orderOutletId +
            "&orderDateTime=" +
            orderDate +
            " " +
            deliveryTime
        ).then((response) => {
          var shopeClosed = 0;
          if (response.data.status === "error") {
            shopeClosed = 1;
          }
          this.setState({ shopeClosed: shopeClosed }, function () {
            this.getCateProductList(navigateMenu, outlet_slug);
          });
        });
      } else {
        this.getCateProductList(navigateMenu, outlet_slug);
      }
    }
  }
  getCateProductList(navigateMenu, outlet_slug) {
    if (navigateMenu !== "" && navigateMenu.length > 0) {
      var updateproductList = [];
      var current = this;
      var addSlug = "";
      if (
        this.props.productState.currentPageUrl !== "" &&
        typeof this.props.productState.currentPageUrl !== undefined &&
        typeof this.props.productState.currentPageUrl !== "undefined"
      ) {
        addSlug += "&shop_type=" + this.props.productState.currentPageUrl;
      }

      if (
        this.props.productState.slugEvent !== "" &&
        typeof this.props.productState.slugEvent !== undefined &&
        typeof this.props.productState.slugEvent !== "undefined"
      ) {
        addSlug += "&event=" + this.props.productState.slugEvent;
      }

      var deliveryDate =
        cookie.load("deliveryDate") == undefined ||
        cookie.load("deliveryDate") == ""
          ? ""
          : cookie.load("deliveryDate").split("/");
      var delivery_Date = "";
      if (deliveryDate !== "") {
        delivery_Date =
          deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
      } else {
        delivery_Date = format(new Date(), "yyyy-MM-dd");
      }
      var spicelPride =
        "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;

      navigateMenu.map(function (item, index) {
        var availabilityId =
          cookie.load("defaultAvilablityId") === undefined ||
          cookie.load("defaultAvilablityId") == ""
            ? deliveryId
            : cookie.load("defaultAvilablityId");
        Axios.get(
          apiUrlV2 +
            "products/getAllProducts?app_id=" +
            appId +
            "&availability=" +
            availabilityId +
            "&category_slug=" +
            item.pro_cate_slug +
            "&outletSlug=" +
            outlet_slug +
            addSlug +
            spicelPride
        )
          .then(function (response) {
            if (response.data.status === "ok") {
              if (current.state.imagesource === "") {
                current.setState({ imagesource: response.data.common });
              }
              var result = response.data.result_set;
              updateproductList[item.pro_cate_slug] = result;
              current.setState(
                { updateproductList: updateproductList },
                function () {
                  if (
                    this.state.loadlistgtm === false &&
                    index == navigateMenu.length - 1
                  ) {
                    this.setState({ loadlistgtm: true }, function () {
                      var gtmProducts = [];
                      var i = 1;
                      setTimeout(function () {
                        Object.keys(updateproductList).map((item) => {
                          var gtmProductList =
                            updateproductList[item] !== "" &&
                            updateproductList[item] !== null &&
                            typeof updateproductList[item] !== undefined &&
                            typeof updateproductList[item] !== "undefined"
                              ? updateproductList[item]
                              : "";
                          if (gtmProductList !== "") {
                            if (gtmProductList[0].subcategorie.length > 0) {
                              gtmProductList[0].subcategorie.map((item1) => {
                                if (item1.products.length > 0) {
                                  item1.products.map((item2) => {
                                    var productName =
                                      item2.product_alias !== ""
                                        ? stripslashes(item2.product_alias)
                                        : stripslashes(item2.product_name);
                                    var proPrice = item2.product_price;
                                    var outlet_name =
                                      current.props.productState.outletslist
                                        .length > 0
                                        ? stripslashes(
                                            current.props.productState
                                              .outletslist[0].outlet_name
                                          )
                                        : "";
                                    gtmProducts.push({
                                      name: stripslashes(productName),
                                      id: item2.cart_item_product_id,
                                      price: proPrice,
                                      brand: outlet_name,
                                      category:
                                        gtmProductList.menu_custom_title,
                                      variant: "",
                                      list: "list products",
                                      position: i,
                                    });
                                    i++;
                                  });
                                }
                              });
                            }
                          }
                        });
                        if (gtmProducts.length > 0) {
                          window.dataLayer.push({
                            ecommerce: {
                              currencyCode: "SGD",
                              impressions: gtmProducts,
                            },
                          });
                        }
                      }, 3000);
                    });
                  }
                  current.productsubcatlist(
                    updateproductList,
                    navigateMenu,
                    index
                  );
                }
              );
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }

  productsubcatlist(subcatlistArr, navigateMenu, index) {
    // var subcatlistArr = this.props.productSubcatlist;
    var current = this;
    hideLoader("productlist-main-div", "class");
    if (navigateMenu !== "" && navigateMenu.length > 0) {
      const productDetails = navigateMenu.map(function (item, index) {
        if (
          subcatlistArr[item.pro_cate_slug] !== "" &&
          typeof subcatlistArr[item.pro_cate_slug] != undefined &&
          typeof subcatlistArr[item.pro_cate_slug] != "undefined"
        ) {
          return (
            <div
              key={index}
              id={item.pro_cate_slug}
              className="category-product-list"
              //title={item.category_name}
            >
              {item.pro_cate_description !== null &&
              item.pro_cate_description !== "" ? (
                <div className="do-or-die">
                  {stripslashes(item.pro_cate_description)}
                </div>
              ) : item.pro_subcate_description !== null &&
                item.pro_subcate_description !== "" ? (
                <div className="do-or-die">
                  {stripslashes(item.pro_subcate_description)}
                </div>
              ) : (
                ""
              )}
              {subcatlistArr[item.pro_cate_slug].map(
                (subcatlistArr1, index) => {
                  return subcatlistArr1["subcategorie"].map(
                    (categories, categoryIndex) => {
                      if (categories.products.length > 0) {
                        return (
                          <div className="innerproduct" key={categoryIndex}>
                            <h3 id={categories.pro_subcate_slug}>
                              {stripslashes(categories.pro_subcate_name)}
                            </h3>
                            {categories.pro_subcate_description !== null ? (
                              <p>
                                {stripslashes(
                                  categories.pro_subcate_description
                                )}
                              </p>
                            ) : categories.pro_subcate_short_description !==
                              null ? (
                              <p>
                                {stripslashes(
                                  categories.pro_subcate_short_description
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                            <ul className="products-list-ulmain">
                              {current.productList(
                                categories.products,
                                categories.products.length,
                                item.pro_cate_slug,
                                categories.pro_subcate_slug
                              )}
                            </ul>
                          </div>
                        );
                      }
                    }
                  );
                }
              )}
            </div>
          );
        }
      });
      this.setState({ allProductList: productDetails }, function () {
        $(".category-product-list").each(function () {
          if ($(this).find(".products-list-ulmain li").length > 0) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });
      });
      return "";
    } else {
      return "";
    }

    /* if (Object.keys(subcatlistArr).length > 0) {
      hideLoader("productlist-main-div", "class");
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          return (
            <div className="innerproduct" key={categoryIndex}>
              <h3 id={categories.pro_subcate_slug}>
                {stripslashes(categories.pro_subcate_name)}
              </h3>
              <ul className="products-list-ulmain">
                {this.productList(
                  categories.products,
                  categories.products.length
                )}
              </ul>
            </div>
          );
        }
      });
      return productDetails;
    } else {
      return "";
    } */
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }

    return returnText;
  }

  /* Products List */
  productList(list, productcount, pro_cate_slug, pro_subcate_slug) {
    var imageSource = this.props.productCommon.product_image_source;
    var tagImageSource = this.props.productCommon.tag_image_source;
    var slugType = this.state.selectedslugType;
    var naviSlug = this.state.selectedNavigation;
    const listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      var checkFilter = this.checkFilter(productDetail.product_tag);
      if (checkFilter === true) {
        return (
          <li
            className={
              this.chkProStockCls(
                productDetail.product_slug,
                productDetail.product_stock
              ) +
              (this.props.match.params.proValue === productDetail.product_slug
                ? " proactive"
                : "")
            }
            id={prodivId}
            key={index}
            onClick={this.viewProDetail.bind(
              this,
              productDetail,
              pro_cate_slug,
              pro_subcate_slug
            )}
            title={
              productDetail.product_alias !== ""
                ? stripslashes(productDetail.product_alias)
                : stripslashes(productDetail.product_name)
            }
          >
            <div className="product-info-div">
              <div
                className={
                  "product-title-maindiv " + productDetail.product_slug
                }
              >
                <div className="product-title">
                  <h3>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h3>
                </div>
                <div className="product-tag-list">
                  {Object.keys(productDetail.product_tag).length > 0 ? (
                    <ul>
                      {productDetail.product_tag.map((producttag, index1) => {
                        return (
                          <li key={index1}>
                            {producttag.pro_tag_name !== ""
                              ? producttag.pro_tag_name
                              : ""}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {description !== "" && (
                <div className="product-short-description">
                  <p>{description !== "" ? description : Parser("&nbsp;")}</p>
                </div>
              )}
              {productDetail.product_specialprice_applicable === "yes" ? (
                <div className="product-price">
                  <span className="price_disc">
                    {showPriceValue(productDetail.product_price)}
                  </span>{" "}
                  <h3>{showPriceValue(productDetail.product_special_price)}</h3>
                </div>
              ) : (
                <div className="product-price">
                  <h3>{showPriceValue(productDetail.product_price)}</h3>
                </div>
              )}
            </div>
            <div className="products-image-div">
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={
                    this.state.imagesource.product_image_source +
                    "/" +
                    productDetail.product_thumbnail
                  }
                />
              ) : (
                <img src={noimage} className="up-unavalible" />
              )}
            </div>
          </li>
        );
      }
    });

    return listProduct;
  }

  checkFilter(productTag) {
    var filterTag = this.props.productState.filterTag;
    var exist = 0;
    if (filterTag.length > 0) {
      if (Object.keys(productTag).length > 0) {
        productTag.map((item) => {
          if (filterTag.indexOf(item.tag_id) >= 0) {
            exist++;
          }
        });
      }
    } else {
      exist = 1;
    }
    if (exist > 0) {
      return true;
    } else {
      return false;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    if (
      cookie.load("defaultAvilablityId") === "" ||
      typeof cookie.load("defaultAvilablityId") === undefined ||
      typeof cookie.load("defaultAvilablityId") === "undefined"
    ) {
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });

      return false;
    }

    if (
      cookie.load("group_order_confirm") !== "" &&
      typeof cookie.load("group_order_confirm") !== undefined &&
      typeof cookie.load("group_order_confirm") !== "undefined"
    ) {
      if (cookie.load("group_order_confirm") === "Yes") {
        $.magnificPopup.open({
          items: {
            src: "#warning_group_order_close",
          },
          type: "inline",
        });
        return false;
      }
    }

    if (
      cookie.load("defaultAvilablityId") === "" ||
      typeof cookie.load("defaultAvilablityId") === undefined ||
      typeof cookie.load("defaultAvilablityId") === "undefined"
    ) {
      $(".required_availability").show();
      $("html, body").animate(
        {
          scrollTop: $("#availability_div").offset().top - 100,
        },
        1000
      );
      setTimeout(function () {
        $(".required_availability").hide();
      }, 3000);
      return false;
    }

    if (
      this.state.shopType !== "" &&
      typeof this.state.shopType !== undefined &&
      typeof this.state.shopType !== "undefined"
    ) {
      if (this.state.currentShopType !== this.state.shopType) {
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        $("#change_shop_type").val(this.state.currentShopType);
        return false;
      }
    } else {
      this.setState({ shopType: this.state.currentShopType }, function () {
        cookie.save("shopType", this.state.currentShopType, { path: "/" });
      });
    }
    var outlet_id =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var outletLocationId = this.props.productState.outletLocationId;

    if (this.props.productState.diffLocation === "Y") {
      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
      $("#change_site_location").val(
        this.props.productState.tempOutletLocationId
      );
      return false;
    } else {
      if (
        cookie.load("outletLocationId") === "" ||
        typeof cookie.load("outletLocationId") === undefined ||
        typeof cookie.load("outletLocationId") === "undefined"
      ) {
        cookie.save("outletLocationId", outletLocationId, { path: "/" });
      }
    }
    var cartOutletList = this.state.cartOutletList;
    if (
      cartOutletList.length >= parseInt(this.state.settings.max_order_handle)
    ) {
      if (cartOutletList.indexOf(outlet_id) < 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-max-orderpopup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      var specialprice_applicable =
        productDetail.product_specialprice_applicable;

      var postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        outletLocationId: outletLocationId,
        outlet_id: outlet_id,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        specialprice_applicable: specialprice_applicable,
        shopType: this.state.shopType,
        outletzone_id: this.state.orderZoneId,
        cart_id:
          cookie.load("cart_id") !== "" &&
          typeof cookie.load("cart_id") !== undefined &&
          typeof cookie.load("cart_id") !== "undefined"
            ? cookie.load("cart_id")
            : "",
        cart_group_id:
          cookie.load("cart_group_id") !== "" &&
          typeof cookie.load("cart_group_id") !== undefined &&
          typeof cookie.load("cart_group_id") !== "undefined"
            ? cookie.load("cart_group_id")
            : "",
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        $("#proIndex-" + IndexFlg).removeClass("active");
        hideLoader("proIndex-" + IndexFlg, "Idtext");
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          var productName =
            productDetail.product_alias !== ""
              ? stripslashes(productDetail.product_alias)
              : stripslashes(productDetail.product_name);
          var proPrice = productDetail.product_price;
          window.dataLayer.push({
            event: "addToCart",
            ecommerce: {
              currencyCode: "SGD",
              add: {
                products: [
                  {
                    name: stripslashes(productName),
                    id: productDetail.product_id,
                    price: proPrice,
                    brand: stripslashes(productDetail.outlet_name),
                    category: stripslashes(productDetail.catgory_name),
                    variant: "",
                    quantity: proqtyQty,
                  },
                ],
              },
            },
          });

          this.props.sateValChange("cartflg", "yes");
          removePromoCkValue();
          showCustomAlert(
            "success",
            "Great! Your item has been added to the cart",
            "cart-success-msg"
          );
          /* this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          ); */
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("Notice", errMsgtxt);
          //this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }

  viewProDetail(productDetail, pro_cate_slug, pro_subcate_slug, event) {
    event.preventDefault();
    console.log(productDetail, "productDetail");
    //  return false;
    this.props.history.push(
      "/food/" +
        this.props.match.params.siteLocation +
        "/" +
        this.props.match.params.slugRestaurant +
        "/" +
        pro_cate_slug +
        "/" +
        pro_subcate_slug +
        "/" +
        productDetail.product_slug
    );
    return false;

    if (
      cookie.load("defaultAvilablityId") === "" ||
      typeof cookie.load("defaultAvilablityId") === undefined ||
      typeof cookie.load("defaultAvilablityId") === "undefined"
    ) {
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });

      return false;
    }

    if (productDetail.product_type === "5") {
      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });

      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: "1",
      });

      return false;
    } else {
      if (
        cookie.load("group_order_confirm") !== "" &&
        typeof cookie.load("group_order_confirm") !== undefined &&
        typeof cookie.load("group_order_confirm") !== "undefined"
      ) {
        if (cookie.load("group_order_confirm") === "Yes") {
          $.magnificPopup.open({
            items: {
              src: "#warning_group_order_close",
            },
            type: "inline",
          });
          return false;
        }
      }

      if (
        cookie.load("defaultAvilablityId") === "" ||
        typeof cookie.load("defaultAvilablityId") === undefined ||
        typeof cookie.load("defaultAvilablityId") === "undefined"
      ) {
        $(".required_availability").show();

        $("html, body").animate(
          {
            scrollTop: $("#availability_div").offset().top - 100,
          },
          1000
        );
        setTimeout(function () {
          $(".required_availability").hide();
        }, 3000);
        return false;
      }

      if (
        this.state.shopType !== "" &&
        typeof this.state.shopType !== undefined &&
        typeof this.state.shopType !== "undefined"
      ) {
        if (
          parseInt(this.state.currentShopType) !== parseInt(this.state.shopType)
        ) {
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          $("#change_shop_type").val(this.state.currentShopType);
          return false;
        }
      } else {
        this.setState({ shopType: this.state.currentShopType }, function () {
          cookie.save("shopType", this.state.currentShopType, { path: "/" });
        });
      }

      var site_location_id =
        cookie.load("sl_location_id") !== "" &&
        typeof cookie.load("sl_location_id") !== undefined &&
        typeof cookie.load("sl_location_id") !== "undefined"
          ? cookie.load("sl_location_id")
          : "";

      var old_site_location_id = this.props.siteLocID;

      if (
        old_site_location_id !== "" &&
        old_site_location_id !== undefined &&
        site_location_id !== "" &&
        site_location_id !== undefined
      ) {
        if (parseInt(old_site_location_id) !== parseInt(site_location_id)) {
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      }

      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        showLoader("proIndex-" + productDetail.product_primary_id, "Idtext");
        $("#proIndex-" + productDetail.product_primary_id).addClass("active");
        this.props.sateValChange("view_pro_data", productSlug);
      }

      var productName =
        productDetail.product_alias !== ""
          ? stripslashes(productDetail.product_alias)
          : stripslashes(productDetail.product_name);
      var proPrice = productDetail.product_price;

      window.dataLayer.push({
        event: "productClick",
        ecommerce: {
          click: {
            products: [
              {
                name: stripslashes(productName),
                id: productDetail.product_id,
                price: proPrice,
                brand: stripslashes(productDetail.outlet_name),
                category: stripslashes(productDetail.catgory_name),
                variant: "",
                position: "",
              },
            ],
          },
        },
      });

      return false;
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    this.setState({ ["error_" + item]: "" });
  };

  addClassInput() {
    $(".input-focus").focus(function () {
      $(this).parents(".focus-out").addClass("focused");
    });
    $(".input-focus").blur(function () {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this).parents(".focus-out").removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
  }

  addToCartVoucherMe() {
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/

    if (!cookie.load("UserId")) {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
      cookie.save("redirectProducts", "Yes", { path: "/" });
      return false;
    }

    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    var TotalPrice =
      this.state.voucherProductDetail.product_price *
      this.state.voucher_product_qty;
    var postObject = {};

    var ImagePath = this.state.voucherProductDetail.product_thumbnail;
    if (ImagePath !== "") {
      var postImagePath =
        this.props.productCommon.product_image_source +
        "/" +
        this.state.voucherProductDetail.product_thumbnail;
    } else {
      var postImagePath = "";
    }

    var outletLocationId = this.props.productState.outletLocationId;

    postObject = {
      app_id: appId,
      outlet_id: this.state.outlet_id,
      shopType: this.state.shopType,
      outletLocationId: outletLocationId,
      outletzone_id: this.state.orderZoneId,
      product_id: this.state.voucherProductDetail.product_id,
      product_qty: this.state.voucher_product_qty,
      product_name: this.state.voucherProductDetail.product_name,
      product_sku: this.state.voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: this.state.voucherProductDetail.product_price,
      product_type: 5,
      product_image: postImagePath,
      availability_id: availabilityId,
      availability_name: availabilityName,
      isAddonProduct: isAddonProduct,
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      cart_id:
        cookie.load("cart_id") !== "" &&
        typeof cookie.load("cart_id") !== undefined &&
        typeof cookie.load("cart_id") !== "undefined"
          ? cookie.load("cart_id")
          : "",
      cart_group_id:
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "",
      voucher_for: "Me",
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      product_voucher_expiry_date:
        this.state.voucherProductDetail.product_voucher_expiry_date !== ""
          ? this.state.voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    showLoader("add_to_voucher", "class");

    Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      hideLoader("add_to_voucher", "class");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".product-list-main-div")
        .removeClass("add-cart");
      if (res.data.status === "ok") {
        this.props.sateValChange("cartflg", "yes");
        removePromoCkValue();
        showCustomAlert("success", "Great choice! Item added to your cart.");
        /*showCartLst();*/
        this.handleShowAlertFun(
          "success",
          "Great choice! Item added to your cart."
        );
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
        });
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Products can`t add your cart.";
        showCustomAlert("error", errMsgtxt);
        this.handleShowAlertFun("Error", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  addToCartVoucher() {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }

    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This is not a valid email.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");

      var TotalPrice =
        this.state.voucherProductDetail.product_price *
        this.state.voucher_product_qty;

      var ImagePath = this.state.voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.props.productCommon.product_image_source +
          "/" +
          this.state.voucherProductDetail.product_thumbnail;
      } else {
        var postImagePath = "";
      }

      var outletLocationId = this.props.productState.outletLocationId;

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: this.state.voucherProductDetail.product_id,
        product_qty: this.state.voucher_product_qty,
        product_name: this.state.voucherProductDetail.product_name,
        product_sku: this.state.voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        outletLocationId: outletLocationId,
        product_unit_price: this.state.voucherProductDetail.product_price,
        product_image: postImagePath,
        product_type: 5,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        voucher_for: "Gift",
        voucher_gift_name: this.state.voucher_name,
        voucher_gift_mobile: this.state.voucher_mobile,
        voucher_gift_email: this.state.voucher_email,
        voucher_gift_message: this.state.voucher_message,
        product_voucher_expiry_date:
          this.state.voucherProductDetail.product_voucher_expiry_date !== ""
            ? this.state.voucherProductDetail.product_voucher_expiry_date
            : "",
      };

      Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(
        (res) => {
          $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
          hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".smiple_product_lk")
            .show();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".product-list-main-div")
            .removeClass("add-cart");
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucher_name: "",
              voucher_mobile: "",
              voucher_email: "",
              voucher_message: "",
            });
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucherIndexFlag: "",
            });
          }

          return false;
        }
      );
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="productlist-main-div up-progrp-item">
        <div className="innerproduct_row">
          {this.state.allProductList}
          {/* this.productsubcatlist() */}
        </div>

        <div
          id="vouchergift-popup"
          className="white-popup mfp-hide popup_sec vouchergift-popup"
        >
          <div className="voucher-popup-inner pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>IM PURCHASING THIS</h3>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer voucher-popup-body product_voucher_body">
                <ul className="nav nav-tabs text-center product_voucher vouchergift_ul">
                  <li className="active">
                    <a
                      data-toggle="tab"
                      href="#tab-id-inn5"
                      aria-expanded="true"
                    >
                      <span>For Gift</span>
                    </a>
                  </li>
                  <li className="">
                    <a
                      data-toggle="tab"
                      href="#tab-id-inn6"
                      aria-expanded="false"
                    >
                      <span>For Me</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id="tab-id-inn5" className="tab-pane fade active in">
                    <h4 className="tab_mobtrigger inner_tab_border active"></h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="order-delivery">
                        <div className="ord-body">
                          <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                            <ul className="myacc_order_details">
                              <div className="voucher-popup-inner product-voucher-popup">
                                <h2>GIFT RECEIVER'S INFORMATION</h2>
                                <div className="form-group">
                                  <div className="focus-out">
                                    <label>Name</label>
                                    <input
                                      type="text"
                                      id="guest-name"
                                      name="guest-name"
                                      value={this.state.voucher_name}
                                      onClick={this.addClassInput.bind(this)}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        "voucher_name"
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {this.state.error_voucher_name}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="focus-out">
                                    <label>Enter Mobile Number</label>
                                    <input
                                      type="tel"
                                      id="guest-number"
                                      name="guest-number"
                                      value={this.state.voucher_mobile}
                                      onClick={this.addClassInput.bind(this)}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        "voucher_mobile"
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {this.state.error_voucher_mobile}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="focus-out">
                                    <label>Email Address</label>
                                    <input
                                      type="text"
                                      id="guest-email"
                                      name="guest-email"
                                      value={this.state.voucher_email}
                                      onClick={this.addClassInput.bind(this)}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        "voucher_email"
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {this.state.error_voucher_email}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="focus-out">
                                    <label>Message</label>
                                    <textarea
                                      type="text"
                                      id="guest-message"
                                      name="guest-message"
                                      value={this.state.voucher_message}
                                      onClick={this.addClassInput.bind(this)}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        "voucher_message"
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {this.state.error_voucher_message}
                                  </div>
                                </div>

                                <div className="howtouse-gift-vouchar">
                                  <img src={smartPhone} />
                                  <h3>How to use</h3>
                                  <p>
                                    After purchasing, your recipient will get
                                    email notification to use the above email
                                    address to sign up. Vouchers will be
                                    available within their account.
                                  </p>
                                </div>
                                <div className="form-group gift-group">
                                  <button
                                    type="button"
                                    className="button add_to_voucher"
                                    onClick={this.addToCartVoucher.bind(this)}
                                  >
                                    Add to Cart
                                  </button>
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-id-inn6" className="tab-pane fade">
                    <h4 className="tab_mobtrigger inner_tab_border"></h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                        <ul className="myacc_order_details used-voucher">
                          <div className="voucher-popup-inner product-voucher-popup">
                            <div className="howtouse-gift-vouchar">
                              <img src={smartPhone} />
                              <h3>How to use</h3>
                              <p>
                                After purchasing, your recipient will get email
                                notification to use the above email address to
                                sign up. Vouchers will be available within their
                                account.
                              </p>
                            </div>

                            <button
                              type="button"
                              className="button add_to_voucher"
                              onClick={this.addToCartVoucherMe.bind(this)}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  var siteLocID = "";

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      siteLocID = resultSetArr.siteLocID;
    }
  }

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
    siteLocID: siteLocID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
