/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";
import moment from "moment";
import {
  getReferenceID,
  stripslashes,
  getCalculatedAmount,
  callImage,
  showLoader,
  hideLoader,
  getOrderDateTime,
  getPromoCkValue,
  removeOrderDateTime,
  removePromoCkValue,
  resetCrtStyle,
  addressFormat,
  showAlert,
} from "../Helpers/SettingHelper";
import {
  appId,
  apiUrl,
  deliveryId,
  cateringId,
  CountryTxt,
  pickupId,
} from "../Helpers/Config";

/* import images */
import groupimg from "../../common/images/group.png";
import thumbsimg from "../../common/images/icon-win.svg";
import emptyCart from "../../common/images/empty-cart.png";
import warningImg from "../../common/images/warning.svg";
import {
  GET_CART_DETAIL,
  UPDATE_CART_DETAIL,
  DELETE_CART_DETAIL,
  DESTROY_CART_DETAIL,
  GET_CATERINGCART_DETAIL,
} from "../../actions";
var Parser = require("html-react-parser");
var qs = require("qs");
class CartSideBar extends Component {
  constructor(props) {
    super(props);
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    var promotionType =
      cookie.load("promotionType") !== "" &&
      typeof cookie.load("promotionType") !== undefined
        ? cookie.load("promotionType")
        : "";

    this.state = {
      defaultAvilTy: avilablityIdTxt,
      promotionType: promotionType,
      cartlistdetail: [],
      overAllcart: [],
      cartItems: [],
      cartDetails: [],
      cartStatus: "",
      settings: [],
      cartTotalItmCount: 0,
      cartTotalAmount: 0,
      cateringCartItems: [],
      cateringCartDetails: [],
      cateringCartTotalItmCount: 0,
      cartOutletList: [],
      cart_group_id:
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "",

      currentAvailability: "",
      updateCartResponse: [],
      startMsg: 0,
    };
  }

  UNSAFE_componentWillMount() {
    // if (this.state.defaultAvilTy === cateringId) {
    //   this.props.getCateringCartDetail();
    // } else {

    this.props.getCartDetail();
  }

  componentWillReceiveProps(headerProps) {
    if (headerProps.cartTriggerFlg === "yes") {
      if (headerProps.headerState.defaultAvilTy !== this.state.defaultAvilTy) {
        this.setState(
          { defaultAvilTy: headerProps.headerState.defaultAvilTy },
          function () {
            this.setState(
              {
                defaultAvilTy: headerProps.headerState.defaultAvilTy,
              },
              function () {
                this.setState({
                  defaultAvilTy: headerProps.headerState.defaultAvilTy,
                });
              }
            );
          }
        );
      }
      headerProps.prpSateValChange("cartflg", "no");
      headerProps.cart_Outlet_List("cartflg", "no");
      this.props.getCartDetail();
      resetCrtStyle();
    }
    if (headerProps.cartClearTriggerFlg === "yes") {
      headerProps.prpSateValChange("cartclearflg", "no");
      this.clearCartItm();
    }
    hideLoader("product-details", "class");
    hideLoader("cart_body", "class");
    hideLoader("cancel_order", "class");
    if (this.state.cartOutletList !== headerProps.cartOutletList) {
      this.setState(
        { cartOutletList: headerProps.cartOutletList },
        function () {
          this.props.cart_Outlet_List(
            "cartOutletList",
            headerProps.cartOutletList
          );
        }
      );
    }
    if (this.state.cartTotalItmCount !== headerProps.cartTotalItmCount) {
      this.setState(
        { cartTotalItmCount: headerProps.cartTotalItmCount },
        function () {
          this.props.cart_Outlet_List(
            "totalCartCount",
            headerProps.cartTotalItmCount
          );
        }
      );
    }
    if (this.state.cartDetails !== headerProps.cartDetails) {
      this.setState({ cartDetails: headerProps.cartDetails }, function () {
        this.props.cart_Outlet_List("cartDetails", headerProps.cartDetails);
        this.props.cart_Outlet_List("cartGroups", headerProps.cartGroups);

        if (
          headerProps.cartDetails.cart_location_id !== "" &&
          headerProps.cartDetails.cart_location_id !== undefined
        ) {
          cookie.save(
            "cart_location_id_site",
            headerProps.cartDetails.cart_location_id,
            { path: "/" }
          );
        }

        if (headerProps.deletedGroup !== "") {
          if (
            cookie.load("cart_group_id") !== "" &&
            typeof cookie.load("cart_group_id") !== "undefined" &&
            typeof cookie.load("cart_group_id") !== "undefined"
          ) {
            if (cookie.load("cart_group_id") === headerProps.deletedGroup) {
              this.removeGroupOrder();
            }
          }
        }
      });
    }

    if (this.state.updateCartResponse !== headerProps.updateCartResponse) {
      if (Object.keys(headerProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: headerProps.updateCartResponse },
          function () {
            var Response = headerProps.updateCartResponse;

            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }

    if (this.state.cartDetails !== headerProps.cartStatus) {
      this.setState({ cartStatus: headerProps.cartStatus }, function () {
        if (
          cookie.load("cart_group_id") !== "" &&
          typeof cookie.load("cart_group_id") !== "undefined" &&
          typeof cookie.load("cart_group_id") !== "undefined" &&
          headerProps.cartStatus == "failure"
        ) {
          /*  cookie.remove("cart_group_id", { path: "/" });
          cookie.remove("group_order_confirm", { path: "/" });
          cookie.remove("cart_id", { path: "/" }); */
        }
      });
    }
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentDidMount() {
    var current = this;
    /* setInterval(function () {
      current.props.getCartDetail();
    }, 10000); */
  }
  removeGroupOrder() {
    var postObject = {
      app_id: appId,
      cart_group_id: cookie.load("cart_group_id"),
      cart_id: cookie.load("cart_id"),
    };
    axios
      .post(apiUrl + "cart/cancel_group", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          cookie.remove("cart_group_id", { path: "/" });
          cookie.remove("cart_id", { path: "/" });
          cookie.remove("group_order_confirm", { path: "/" });
          this.props.history.push("/");
        }
      });
  }

  removePromoFun(event) {
    event.preventDefault();
    removePromoCkValue();
    this.props.getCartDetail();
  }

  closeCartlist() {
    $("body").removeClass("cart-items-open");
    $(".hcartdd_trigger").removeClass("active");
    $(".hcart_dropdown").removeClass("open");
  }
  sendGroupOrder(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#conform-group-order-popup",
      },
      type: "inline",
    });
  }
  send_Group_Order(event) {
    event.preventDefault();
    showLoader("send_gruop_order", "class");
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      cart_group_id: cookie.load("cart_group_id"),
    };
    axios
      .post(apiUrl + "cart/confirm_group", qs.stringify(postObject))
      .then((response) => {
        hideLoader("send_gruop_order", "class");
        if (response.data.status === "ok") {
          this.props.getCartDetail();
          cookie.remove("group_start_time", { path: "/" });
          cookie.save("group_order_confirm", "Yes", { path: "/" });
          $("#pending_time_left").remove();
        } else {
        }
        return false;
      });
  }

  changeAvailabilityheader(availability, event) {
    event.preventDefault();

    this.setState({
      currentAvailability: availability,
    });

    $.magnificPopup.open({
      items: {
        src: "#warning-popup-cartsidebar",
      },
      type: "inline",
    });

    return false;

    // if (availability === deliveryId && this.state.deliveryPostalCode === "") {
    //   this.setState({ selectPostalCode: "Yes" });
    //   $.magnificPopup.open({
    //     items: {
    //       src: "#delevery-postcode-popup",
    //     },
    //     type: "inline",
    //   });
    //   return false;
    // }

    // var orderDateTime = cookie.load("orderDateTime");
    // if (availability === pickupId) {
    //   if (
    //     orderDateTime === "" ||
    //     typeof orderDateTime === undefined ||
    //     typeof orderDateTime === "undefined"
    //   ) {
    //     cookie.save("defaultAvilablityId", pickupId, { path: "/" });

    //     return false;
    //   }
    // }

    // if (this.state.defaultAvilTy !== availability) {
    //   cookie.save("defaultAvilablityId", availability, { path: "/" });
    //   this.setState(
    //     { defaultAvilTy: availability, load_product: "No" },
    //     function () {
    //       axios
    //         .get(
    //           apiUrl +
    //             "cart/update_availability?app_id=" +
    //             appId +
    //             "&reference_id=" +
    //             getReferenceID() +
    //             "&customer_id=" +
    //             cookie.load("UserId") +
    //             "&availability_id=" +
    //             availability
    //         )
    //         .then((res) => {
    //           location.reload();
    //           this.setState({ cartTriggerFlg: "yes" }, function () {
    //             this.setState({ cartTriggerFlg: "yes" });
    //             //this.destroyCartShopeType();
    //           });
    //         });
    //     }
    //   );
    // }
  }

  cartDetailsList() {
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();

      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        this.props.maxCart
      );
      var orderDateTime =
        typeof cookie.load("orderDateTime") === "undefined"
          ? ""
          : cookie.load("orderDateTime");
      var orderTAT =
        typeof cookie.load("orderTAT") === "undefined"
          ? ""
          : cookie.load("orderTAT");
      var orderDateTmTxt = getOrderDateTime(orderDateTime, orderTAT);
      let cart_group_id =
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "";
      var completeGroup, cartGroups;
      var cartActive = "hcart_dropdown";
      var clearCartDetails = 0;
      if (
        Object.keys(this.props.cartGroups).length > 0 &&
        cart_group_id !== ""
      ) {
        cartGroups = this.props.cartGroups;
        if (
          cartGroups[cart_group_id] !== "" &&
          typeof cartGroups[cart_group_id] !== undefined &&
          typeof cartGroups[cart_group_id] !== "undefined"
        ) {
          if (cartGroups[cart_group_id].cart_group_status === "C") {
            completeGroup = "Y";
            cartActive += " only-group after-send-group open";
          } else {
            clearCartDetails = 1;
            cartActive += " only-group";
          }
        }
      }

      var currentShopTypeUpd = "";
      if (
        cookie.load("shopType") !== "" &&
        typeof cookie.load("shopType") !== undefined &&
        typeof cookie.load("shopType") !== "undefined"
      ) {
        currentShopTypeUpd = cookie.load("shopType");
      } else {
        var currentShopType = this.props.match.path;
        if (
          currentShopType === "/retail/:slugEvent/" ||
          currentShopType === "/retail/:slugEvent/:slugRestaurant/"
        ) {
          currentShopTypeUpd = 3;
        }
      }

      return (
        <div className={cartActive}>
          <div className="hcart_tt">
            <div
              id="cart-close-span"
              onClick={this.closeCartlist.bind(this)}
            ></div>

            {cart_group_id !== "" && <img src={groupimg} />}
            {cart_group_id !== "" ? (
              <h5>
                {cartDetailsArr.customer_first_name}
                {cartDetailsArr.customer_last_name !== ""
                  ? " " + cartDetailsArr.customer_last_name
                  : ""}
                ’s Group Order
              </h5>
            ) : (
              <h3>My Cart</h3>
            )}
          </div>

          <div className="hcart_scrollarea up-hcart-scrollarea">
            <div className="cart_table">
              <div className="cart_body">
                {cart_group_id === "" && (
                  <>
                    {this.state.defaultAvilTy === deliveryId ? (
                      <div className="up-cart-head">
                        <div className="new-header-left">
                          <div className="product-ava">
                            <div
                              className="product-lo-top"
                              id="availability_div"
                            >
                              <a
                                className="active"
                                onClick={this.changeAvailabilityheader.bind(
                                  this,
                                  deliveryId
                                )}
                              >
                                <span>Delivery</span>
                              </a>
                              <a
                                className=""
                                onClick={this.changeAvailabilityheader.bind(
                                  this,
                                  pickupId
                                )}
                              >
                                <span>Pickup</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="new-header-first">
                          <h4>
                            Delivering to: <br />
                          </h4>
                          <span>{cookie.load("orderDeliveryAddress")}</span>
                          <span>
                            {" " + CountryTxt} {cookie.load("orderPostalCode")}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="up-cart-head">
                        <div className="new-header-left">
                          <div className="product-ava">
                            <div
                              className="product-lo-top"
                              id="availability_div"
                            >
                              <a
                                className=""
                                onClick={this.changeAvailabilityheader.bind(
                                  this,
                                  deliveryId
                                )}
                              >
                                <span>Delivery</span>
                              </a>
                              <a
                                className="active"
                                onClick={this.changeAvailabilityheader.bind(
                                  this,
                                  pickupId
                                )}
                              >
                                <span>Pickup</span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="new-header-first">
                          <h4>
                            Pickup Location: <br />
                          </h4>
                          {globalSettings !== "" &&
                            globalSettings.site_location !== "" &&
                            globalSettings.site_location !== undefined &&
                            typeof globalSettings.site_location !== undefined &&
                            typeof globalSettings.site_location !==
                              "undefined" && (
                              <span>
                                {
                                  globalSettings.site_location[
                                    this.props.siteLocID
                                  ].sl_name
                                }
                                ,{" "}
                                {addressFormat(
                                  globalSettings.site_location[
                                    this.props.siteLocID
                                  ].sl_pickup_unit_number1,
                                  globalSettings.site_location[
                                    this.props.siteLocID
                                  ].sl_pickup_unit_number2,
                                  globalSettings.site_location[
                                    this.props.siteLocID
                                  ].sl_pickup_address_line1,
                                  globalSettings.site_location[
                                    this.props.siteLocID
                                  ].sl_pickup_address_line2,
                                  globalSettings.site_location[
                                    this.props.siteLocID
                                  ].sl_pickup_postal_code
                                )}
                              </span>
                            )}
                        </div>
                      </div>
                    )}

                    {/*<div className="new-header-second">  
                      <div className="new-header-second-inner"> 
                        <h5>  
                          {currentShopTypeUpd === "3" ||  
                          currentShopTypeUpd === 3  
                            ? this.state.defaultAvilTy !== deliveryId 
                              ? "Date & Time" 
                              : "Date"  
                            : "Date & Time"}  
                        </h5> 
                        <p> 
                          <span 
                            className={ 
                              currentShopTypeUpd === "3" || 
                              currentShopTypeUpd === 3  
                                ? this.state.defaultAvilTy === deliveryId 
                                  ? "event-cart-hide-time"  
                                  : ""  
                                : ""  
                            } 
                          > 
                            {moment(orderDateTmTxt).format("DD - MM - YYYY")} 
                          </span> 
                          {currentShopTypeUpd === "3" ||  
                          currentShopTypeUpd === 3 ? (  
                            this.state.defaultAvilTy !== deliveryId && (  
                              <span>  
                                {moment(orderDateTmTxt).format("hh.mm A")}  
                              </span> 
                            ) 
                          ) : ( 
                            <span>  
                              {moment(orderDateTmTxt).format("hh.mm A")}  
                            </span> 
                          )}  
                        </p>  
                      </div>  
                    </div>*/}
                  </>
                )}

                {cart_group_id === "" ? (
                  <div className="order-details-with-clear">
                    <h5></h5>
                    <a
                      href="/"
                      onClick={this.clearCartItm.bind(this)}
                      className="hclear_cart"
                      title="CLEAR CART"
                    >
                      CLEAR CART
                    </a>
                  </div>
                ) : (
                  clearCartDetails === 1 && (
                    <div className="order-details-with-clear">
                      <h5></h5>
                      <a
                        href="/"
                        onClick={this.clearCartItm.bind(this)}
                        className="hclear_cart"
                        title="CLEAR CART"
                      >
                        CLEAR CART
                      </a>
                    </div>
                  )
                )}

                {this.cartItemList()}
              </div>
              {cart_group_id === "" && (
                <div className="cart_footer">
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-capitalize">Subtotal</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>${calculatedAmount["cartSubTotalAmount"]}</span>
                      </div>
                    </div>
                  </div>
                  {parseFloat(calculatedAmount["deliveryCharge"]) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-capitalize">Delivery Fee</p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            $
                            {parseFloat(
                              calculatedAmount["deliveryCharge"]
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {parseFloat(calculatedAmount["additionalDelivery"]) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-capitalize">
                            Additional Delivery Fee
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            $
                            {parseFloat(
                              calculatedAmount["additionalDelivery"]
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {parseFloat(calculatedAmount["promotionAmount"]) > 0 && (
                    <div className="cart_row promotion-only-show">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            {this.state.promotionType !== "" &&
                            this.state.promotionType === "points"
                              ? "Discount"
                              : "Promo Code"}
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            $
                            {parseFloat(
                              calculatedAmount["promotionAmount"]
                            ).toFixed(2)}
                          </span>
                          <a
                            href="/"
                            onClick={this.removePromoFun.bind(this)}
                            className="cart_remove"
                          ></a>
                        </div>
                      </div>
                    </div>
                  )}
                  {parseFloat(calculatedAmount["orderGstAmount"]) > 0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-uppercase">
                            GST ({calculatedAmount["orderDisplayGst"]} %)
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            $
                            {parseFloat(
                              calculatedAmount["orderGstAmount"]
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {parseFloat(calculatedAmount["voucherDiscountAmount"]) >
                    0 && (
                    <div className="cart_row">
                      <div className="row-replace">
                        <div className="col-sm-cls text-left">
                          <p className="text-capitalize">
                            Voucher Disocunt Amount
                          </p>
                        </div>
                        <div className="col-sm-cls text-right">
                          <span>
                            $
                            {parseFloat(
                              calculatedAmount["voucherDiscountAmount"]
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="cart_row grant-total-cls">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Total</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          <sup>$</sup>
                          {calculatedAmount["grandTotalAmount"]}
                        </span>
                      </div>
                    </div>
                  </div>
                  {this.loadMinCartPercentage()}
                  {this.state.defaultAvilTy === deliveryId &&
                    this.loadDeliveryPercentage()}

                  {parseFloat(cartDetailsArr.cart_special_discount) > 0 && (
                    <div className="member-discount-total">
                      * {cartDetailsArr.cart_special_discount_type} $
                      {cartDetailsArr.cart_special_discount} Applied
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {cart_group_id !== "" ? (
            <div className="cart_footer">
              <div class="cart_row grant-total-cls">
                <div class="row-replace">
                  <div class="col-sm-cls text-left">
                    <p class="text-uppercase">Total</p>
                  </div>
                  <div class="col-sm-cls text-right">
                    <span>
                      <sup>$</sup>
                      {calculatedAmount["cartSubTotalAmount"]}
                    </span>
                  </div>
                </div>
              </div>

              {completeGroup === "Y" ? (
                <div className="cart_action">
                  <div className="after-cart-btm-text">
                    <img src={thumbsimg} />
                    <h5>
                      Items added to {cartDetailsArr.customer_first_name}
                      {cartDetailsArr.customer_last_name !== ""
                        ? " " + cartDetailsArr.customer_last_name
                        : ""}
                      ’s order
                    </h5>
                    <p>
                      We'll let you know once the order has been placed. Item in
                      your basket may be edited by{" "}
                      {cartDetailsArr.customer_first_name}
                      {cartDetailsArr.customer_last_name !== ""
                        ? " " + cartDetailsArr.customer_last_name
                        : ""}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="cart_action">
                  <div className="cartaction_bottom">
                    <Link
                      to={"#"}
                      className="btn btn_pink cartaction_checkout send_gruop_order"
                      title="Add to Order"
                      onClick={this.sendGroupOrder.bind(this)}
                    >
                      Add To Order
                    </Link>
                  </div>
                  <div className="cart-btm-text">
                    <p>You can’t change once items have been added to order</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="cart_action">
              <div className="cartaction_bottom">
                <Link
                  to={"/checkout"}
                  className="btn btn_pink cartaction_checkout"
                  title="Go to Checkout"
                >
                  Go to Checkout
                </Link>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      $("body").removeClass("cart-items-open");
      $(".hcartdd_trigger").removeClass("active");
      $(".hcart_dropdown").removeClass("open");
      return (
        <div className="hcart_dropdown">
          <div className="hcart_tt">
            <div
              id="cart-close-span"
              onClick={this.closeCartlist.bind(this)}
              className="cart_empty_close"
            ></div>

            <h3>My Cart</h3>
          </div>
          <div className="hcart_scrollarea">
            <div className="cart_table">
              <div className="cart_body"></div>
              <div className="cart-empty">
                <img src={emptyCart} />
                <h3>Your cart is empty</h3>
                <p>You have to add something first</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  /* this  function used to load delivery percentage */
  loadDeliveryPercentage() {
    var freeDeliveryAmnt = 0;
    var DeliveryAmnt = 0;
    var remainAmnt = 0;
    var delPercentage = 0;

    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var cartDetailsArr = this.props.cartDetails;
      var promoTionArr = getPromoCkValue();

      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }

      var zoneDetails = this.props.zonedetails;
      var calculatedAmount = getCalculatedAmount(
        globalSettings,
        zoneDetails,
        cartDetailsArr,
        promoTionArr,
        this.props.maxCart
      );

      freeDeliveryAmnt = parseFloat(calculatedAmount["freeDeliveryAmnt"]);
      DeliveryAmnt = parseFloat(calculatedAmount["deliveryCharge"]);
      var subTotal = parseFloat(calculatedAmount["cartSubTotalAmount"]);
      var percentage = (subTotal * 100) / freeDeliveryAmnt;
      percentage = Math.round(percentage);
      delPercentage = percentage;
      remainAmnt = parseFloat(freeDeliveryAmnt - subTotal).toFixed(2);
    }

    if (DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {
      return (
        <div className="cart_row progress_bar_div free-delivery">
          <div className="indication">
            <div className="indication_progress">
              <span
                className="progress_bar"
                style={{ width: delPercentage + "%" }}
              />
            </div>
            <p className="help-block">${remainAmnt} more to FREE delivery!</p>
          </div>
        </div>
      );
    }
  }

  loadMinCartPercentage() {
    var delPercentage = 0;
    var min_amount = 0;
    var cartItemsArr = this.props.cartItems;

    if (Object.keys(cartItemsArr).length > 0) {
      var globalSettings = Array();
      if (Object.keys(this.props.globalsettings).length > 0) {
        if (this.props.globalsettings[0].status === "ok") {
          globalSettings = this.props.globalsettings[0].result_set;
        }
      }
      var cartDetailsArr = this.props.cartDetails;
      var subTotal = parseFloat(cartDetailsArr.cart_sub_total);
      min_amount = parseFloat(globalSettings.pos_min_amount);
      var percentage = (subTotal * 100) / min_amount;
      percentage = Math.round(percentage);
      var balance = parseFloat(min_amount - subTotal);
      if (
        balance > 0 &&
        min_amount > 0 &&
        min_amount > subTotal &&
        percentage > 0
      ) {
        balance = balance.toFixed(2);
        return (
          <div className="cart_row progress_bar_div minimum-order-amount">
            <div className="indication">
              <div className="indication_progress">
                <span
                  className="progress_bar"
                  style={{ width: percentage + "%" }}
                />
              </div>
              <p className="help-block">${balance} more to min order amount</p>
            </div>
          </div>
        );
      }
    }
  }

  cartItemList() {
    var cartItemsArr = this.props.cartItems;
    var cartOutletArr = this.props.cartOutlet;
    var cartGroups = this.props.cartGroups;
    var cartDetails = this.props.cartDetails;
    var cart_group_id = "";
    if (
      cookie.load("cart_group_id") !== "" &&
      typeof cookie.load("cart_group_id") !== undefined &&
      typeof cookie.load("cart_group_id") !== "undefined"
    ) {
      cart_group_id = cookie.load("cart_group_id");
    }
    if (Object.keys(cartItemsArr).length > 0) {
      return Object.keys(cartItemsArr).map((index) => {
        return (
          <div className="cart_outlet_row" key={index}>
            {Object.keys(cartItemsArr[index]).map((index2) => {
              let letOutleName =
                cartOutletArr[index2] !== "" &&
                typeof cartOutletArr[index2] !== undefined &&
                typeof cartOutletArr[index2] !== "undefined"
                  ? cartOutletArr[index2]
                  : "";
              return (
                <div key={index2}>
                  <div className="cart-outlet-name">{letOutleName}</div>

                  {Object.keys(cartItemsArr[index][index2]).map((index1) => {
                    let personName = "";
                    if (Object.keys(cartGroups).length > 0) {
                      if (index1 === "0") {
                        if (
                          cookie.load("UserId") === cartDetails.cart_customer_id
                        ) {
                          personName = cookie.load("UserFname") + " (You)";
                        } else {
                          personName = cartDetails.customer_first_name + "'s";
                        }
                      } else {
                        if (
                          cartGroups[index1] !== "" &&
                          typeof cartGroups[index1] !== undefined &&
                          typeof cartGroups[index1] !== "undefined"
                        ) {
                          if (cookie.load("cart_group_id") === index1) {
                            personName =
                              cartGroups[index1].cart_member_name + " (You)";
                          } else {
                            personName =
                              cartGroups[index1].cart_member_name + "'s";
                          }
                        }
                      }
                    }
                    let product = cartItemsArr[index][index2][index1];
                    return (
                      <>
                        {personName !== "" && (
                          <div className="cart-person-name">{personName}</div>
                        )}
                        {this.checkAvailability(index2)}
                        {this.cartOutletItemList(product, index1)}
                      </>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      });
    }
  }

  checkAvailability(outletID) {
    let cartoutletavailability = this.props.cartoutletavailability;
    if (
      cartoutletavailability[outletID] !== "" &&
      typeof cartoutletavailability[outletID] !== undefined &&
      typeof cartoutletavailability[outletID] !== "undefined"
    ) {
      if (
        cartoutletavailability[outletID].indexOf(this.state.defaultAvilTy) < 0
      ) {
        return (
          <span className="outlet-avilable">
            Currently unavailable. Let's try other restaurants!
          </span>
        );
      }
    }
  }

  cartOutletItemList(cartItemsArr, cartGroupID) {
    let group_order_confirm =
      cookie.load("group_order_confirm") !== "" &&
      typeof cookie.load("group_order_confirm") !== undefined &&
      typeof cookie.load("group_order_confirm") !== "undefined"
        ? cookie.load("group_order_confirm")
        : "";
    if (Object.keys(cartItemsArr).length > 0 && cartItemsArr !== null) {
      return cartItemsArr.map((product, index) => (
        <div
          className="cart_row product-details"
          id={"rowcartid-" + product.cart_item_id}
          key={index}
        >
          <div
            className={
              this.state.cart_group_id !== ""
                ? group_order_confirm !== ""
                  ? "row-replace group-persion-cart"
                  : cartGroupID === this.state.cart_group_id
                  ? "row-replace"
                  : "row-replace group-persion-cart"
                : "row-replace"
            }
          >
            <div className="col-sm-cls cart_left up-cart-left">
              <div className="cart_info text-left">
                <div className="up-cart-qty">
                  <div className="qty_bx">
                    {this.state.cart_group_id !== "" ? (
                      group_order_confirm === "" &&
                      cartGroupID !== this.state.cart_group_id && (
                        <label
                          type="text"
                          disabled=""
                          data-qtyval="0"
                          className="combo-input-label combo-input combolst_qty_value"
                        >
                          {product.cart_item_qty + "x"}
                        </label>
                      )
                    ) : (
                      <label
                        type="text"
                        disabled=""
                        data-qtyval="0"
                        className="combo-input-label combo-input combolst_qty_value"
                      >
                        {product.cart_item_qty + "x"}
                      </label>
                    )}
                  </div>
                </div>
                <h4>
                  <strong>
                    {stripslashes(product.cart_item_product_name)}
                  </strong>
                </h4>
                {product.cart_item_product_type === "5" &&
                  product.cart_item_product_voucher_gift_email !== "" &&
                  product.cart_item_product_voucher_gift_email !==
                    cookie.load("UserEmail") &&
                  product.cart_item_product_voucher_gift_mobile !== "" &&
                  (product.cart_voucher_order_item_id === "" ||
                    product.cart_voucher_order_item_id === null) && (
                    <div className="cart_extrainfo">
                      <p>
                        <b>Gift Details</b>
                      </p>
                      <p>Name: {product.cart_item_product_voucher_gift_name}</p>
                      <p>
                        Mobile: {product.cart_item_product_voucher_gift_mobile}
                      </p>
                      <p>
                        Email: {product.cart_item_product_voucher_gift_email}
                      </p>
                      <p>
                        Message:{" "}
                        {product.cart_item_product_voucher_gift_message}
                      </p>
                    </div>
                  )}
                {this.loadModifierItems(
                  product.cart_item_type,
                  product.modifiers,
                  product.set_menu_component
                )}

                {product.cart_item_special_notes !== "" && (
                  <p className="help-block">
                    {stripslashes(product.cart_item_special_notes)}
                  </p>
                )}

                {parseFloat(product.cart_item_promotion_discount) > 0 && (
                  <span className="member-discount-desc">
                    $ {product.cart_item_promotion_discount}{" "}
                    {product.cart_item_promotion_type} discount Applied
                  </span>
                )}
              </div>
            </div>

            <div className="col-sm-cls cart_right text-right">
              {this.state.cart_group_id !== ""
                ? group_order_confirm === "" &&
                  cartGroupID === this.state.cart_group_id && (
                    <div className="qty_bx">
                      <span
                        className="qty_minus"
                        onClick={this.updateCartQty.bind(this, product, "decr")}
                      >
                        -
                      </span>
                      <input
                        type="text"
                        value={product.cart_item_qty}
                        readOnly
                      />
                      <span
                        className="qty_plus"
                        onClick={this.updateCartQty.bind(this, product, "incr")}
                      >
                        +
                      </span>
                    </div>
                  )
                : group_order_confirm === "" && (
                    <div className="qty_bx">
                      <span
                        className="qty_minus"
                        onClick={this.updateCartQty.bind(this, product, "decr")}
                      >
                        -
                      </span>
                      <input
                        type="text"
                        value={product.cart_item_qty}
                        readOnly
                      />
                      <span
                        className="qty_plus"
                        onClick={this.updateCartQty.bind(this, product, "incr")}
                      >
                        +
                      </span>
                    </div>
                  )}

              <div className="cart_price">
                <p>${product.cart_item_total_price}</p>
              </div>
            </div>
          </div>

          {
            // this.state.cart_group_id !== ""
            //   ? group_order_confirm === "" &&
            //     cartGroupID === this.state.cart_group_id && (
            //       <a
            //         href="/"
            //         onClick={this.deleteCartItm.bind(this, product)}
            //         className="cart_remove"
            //       ></a>
            //     )
            //   : group_order_confirm === "" && (
            //       <a
            //         href="/"
            //         onClick={this.deleteCartItm.bind(this, product)}
            //         className="cart_remove"
            //       ></a>
            //     )
          }
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    var temp_html = "";

    if (itemType === "Modifier" && len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modVlPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModVlPrice = modVlPrice > 0 ? " (+$" + modVlPrice + ")" : "";
        temp_html +=
          "<p><strong>" +
          modName +
          ": </strong></p> <p>" +
          modval +
          newModVlPrice +
          "</p> ";
      }

      html += temp_html + "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (itemType === "Component" && comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        if (combo[i]["product_details"][0]["modifiers"].length) {
          html +=
            "<p><strong>" +
            comboName +
            ": </strong></p><p>" +
            comboVal +
            "  " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        } else {
          html +=
            "<p><strong>" +
            comboName +
            ": </strong></p><p>" +
            comboVal +
            " " +
            this.showComboModifiers(
              combo[i]["product_details"][0]["modifiers"]
            ) +
            "</p> ";
        }
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["cart_menu_component_product_qty"]) > 0) {
          var comboPro = combos[r]["cart_menu_component_product_name"];
          var comboQty = combos[r]["cart_menu_component_product_qty"];
          var comboPrice = combos[r]["cart_menu_component_product_price"];
          var newPrice = comboPrice > 0 ? " (+$" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "<div >";
    if (lenMod > 0) {
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["cart_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+$" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b> </p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";

      return html;
    }

    return "";
  }

  updateCartQty(itemArr, type) {
    var productId = itemArr.cart_item_product_id;
    var cartItemId = itemArr.cart_item_id;
    var cartQty = itemArr.cart_item_qty;
    var totalItmCount = this.props.cartTotalItmCount;
    var orderVoucherId = itemArr.cart_voucher_order_item_id;

    showLoader("rowcartid-" + cartItemId, "Idtext");

    if (type === "decr") {
      cartQty = parseInt(cartQty) - 1;
      if (parseInt(totalItmCount) === 0) {
      } else if (parseInt(cartQty) === 0) {
        this.props.deleteCartDetail(cartItemId);
        window.dataLayer.push({
          event: "removeFromCart",
          ecommerce: {
            remove: {
              products: [
                {
                  name: stripslashes(itemArr.cart_item_product_name),
                  id: itemArr.cart_item_product_id,
                  price: itemArr.cart_item_total_price,
                  brand: itemArr.outlet_name,
                  category: itemArr.pro_cate_name,
                  variant: "",
                  quantity: 0,
                },
              ],
            },
          },
        });
      } else {
        this.props.updateCartDetail(
          productId,
          cartItemId,
          cartQty,
          orderVoucherId
        );
      }
    } else {
      cartQty = parseInt(cartQty) + 1;
      this.props.updateCartDetail(
        productId,
        cartItemId,
        cartQty,
        orderVoucherId
      );
    }
    this.setState({ startMsg: 1 });
    removePromoCkValue();
  }

  deleteCartItm(itemArr, event) {
    event.preventDefault();
    var cartItemId = itemArr.cart_item_id;
    showLoader("rowcartid-" + cartItemId, "Idtext");
    this.props.deleteCartDetail(cartItemId);
    removePromoCkValue();
    window.dataLayer.push({
      event: "removeFromCart",
      ecommerce: {
        remove: {
          products: [
            {
              name: stripslashes(itemArr.cart_item_product_name),
              id: itemArr.cart_item_product_id,
              price: itemArr.cart_item_total_price,
              brand: itemArr.outlet_name,
              category: itemArr.pro_cate_name,
              variant: "",
              quantity: 0,
            },
          ],
        },
      },
    });
  }

  clearCartItm(event) {
    if (typeof event !== undefined && typeof event !== "undefined") {
      event.preventDefault();
    }
    showLoader("cart_body", "class");
    showLoader("cancel_order", "class");
    this.props.destroyCartDetail();
    removePromoCkValue();
  }

  gotoCateringChkut(event) {
    event.preventDefault();

    var cartTotal = this.props.cateringCartTotalItmCount;
    /*if(parseFloat(cartTotal)>0) {*/
    cookie.save("cateringCheckoutFromCart", "Yes", { path: "/" });
    this.props.history.push("/catering");
    /*}*/
  }

  destroyCartShopeType() {
    this.props.destroyCartDetail();
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    /* Delivery avilablity */
    /* cookie.remove("orderDateTime");
    cookie.remove("deliveryDate");
    cookie.remove("deliveryTime"); */
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("cart_location_id_site", { path: "/" });
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("outletLocationId", { path: "/" });
    cookie.remove("shopType", { path: "/" });
    cookie.remove("UserDefaultPostalCode", { path: "/" });
    cookie.remove("orderDeliveryAddress", { path: "/" });
    cookie.remove("orderHandled", { path: "/" });
    cookie.remove("deliveryPostalCode", { path: "/" });
    cookie.remove("orderPostalCode", { path: "/" });
    cookie.remove("checkoutredirect", { path: "/" });
    //location.reload();
  }

  changeAvailabilityCartsidebar(event) {
    event.preventDefault();
    var availability = this.state.currentAvailability;
    if (this.state.defaultAvilTy !== availability) {
      this.props.destroyCartDetail();
      cookie.save("defaultAvilablityId", availability, { path: "/" });
      this.setState(
        { defaultAvilTy: availability, load_product: "No" },
        function () {
          axios
            .get(
              apiUrl +
                "cart/update_availability?app_id=" +
                appId +
                "&reference_id=" +
                getReferenceID() +
                "&customer_id=" +
                cookie.load("UserId") +
                "&availability_id=" +
                availability
            )
            .then((res) => {
              location.reload();
              this.setState({ cartTriggerFlg: "yes" }, function () {
                this.setState({
                  cartTriggerFlg: "yes",
                  currentAvailability: "",
                });
              });
            });
        }
      );
    }
  }

  render() {
    var currenturl = window.location.href;
    var substring1 = "checkout";
    return (
      <li className="htico_cart">
        {this.state.defaultAvilTy === cateringId ? (
          <a
            href="/"
            onClick={this.gotoCateringChkut.bind(this)}
            className="hcartdd_trigger"
            title=""
          >
            <i></i>
            <span className="hcart_round">
              {this.props.cateringCartTotalItmCount}
            </span>
          </a>
        ) : (
          <a
            href="/"
            className={
              currenturl.includes(substring1) !== true
                ? "hcartdd_trigger"
                : "hcartdd_trigger_dis"
            }
            title="Cart"
            id="cart_count_details"
            data-totalitmcount={this.props.cartTotalItmCount}
          >
            <i></i>
            <input
              type="hidden"
              value={this.props.cartTotalItmCount}
              id="totalitmcount"
            />

            <span>
              {this.props.cartTotalItmCount !== "" &&
              this.props.cartTotalItmCount !== null
                ? this.props.cartTotalItmCount
                : "0"}
            </span>
          </a>
        )}
        {currenturl.includes(substring1) !== true &&
        this.state.defaultAvilTy !== cateringId
          ? this.cartDetailsList()
          : ""}
        <div
          className="custom_alertcls alert-success alert-dismissible1 success_hide"
          id="cart-success-msg"
          style={{ display: "none" }}
        >
          {" "}
          <a
            href="/"
            type="button"
            className="custom_close"
            data-dismiss="alert"
            aria-label="Close"
          >
            {" "}
            <span aria-hidden="true">×</span>{" "}
          </a>{" "}
          <p className="jquery-success-msg">
            Great! Your item has been added to the cart
          </p>{" "}
        </div>
        {/* Conform Group Order - end */}
        <div
          id="conform-group-order-popup"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Warning</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <input type="hidden" id="changeaddressheader" />
                  <p>Are you sure you want to proceed?</p>
                  <p>
                    Please note that you cannot change your order once submitted
                  </p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      No
                    </a>
                    <a
                      href="/"
                      onClick={this.send_Group_Order.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      Yes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Conform Group Order - end */}

        {/* Warning Popup - start */}
        <div
          id="warning-popup-cartsidebar"
          className="white-popup mfp-hide popup_sec warning_popup"
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Warning</div>
                <div className="alert_body">
                  <img className="warning-popup-img" src={warningImg} />
                  <input type="hidden" id="changeaddressheader" />
                  <p>By switching you are about to clear your cart.</p>
                  <p>Do you wish to proceed ?</p>
                  <div className="alt_btns">
                    <a
                      href="/"
                      className="popup-modal-dismiss button button-left disbl_href_action"
                    >
                      No
                    </a>
                    <input type="hidden" value="" id="change_shop_type" />
                    <input type="hidden" value="" id="change_site_location" />
                    <a
                      href="/"
                      onClick={this.changeAvailabilityCartsidebar.bind(this)}
                      className="button button-right popup-modal-dismiss disbl_href_action"
                    >
                      Yes
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Warning Popup - end */}
      </li>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var cartOutletList = 0;
  var cartOutlet = Array();
  var cartTotalItmCount = 0;
  var cartTotalIAmount = 0;
  var cartoutletavailability = Array();
  var siteLocID = "";
  var maxCart = 0;
  var cartStatus = "";
  var cartGroups = Array();
  var deletedGroup = Array();
  var updateCartResponse = Array();

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartItems = resultSetArr.cart_items;
      cartOutletList = resultSetArr.cartoutlet;
      cartOutlet = resultSetArr.outlets;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      cartTotalIAmount = resultSetArr.cart_details.cart_grand_total;
      cartoutletavailability = resultSetArr.cartoutletavailability;
      siteLocID = resultSetArr.siteLocID;
      maxCart = resultSetArr.maxCart;
      cartGroups = resultSetArr.cartGroups;
      deletedGroup = resultSetArr.deletedGroup;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }

  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartOutletList: cartOutletList,
    cartOutlet: cartOutlet,
    siteLocID: siteLocID,
    maxCart: maxCart,
    cartGroups: cartGroups,
    cartoutletavailability: cartoutletavailability,
    cartTotalItmCount: cartTotalItmCount,
    cartTotalIAmount: cartTotalIAmount,
    cartStatus: cartStatus,
    deletedGroup: deletedGroup,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
    updateCartResponse: updateCartResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    updateCartDetail: (productId, cartItemId, cartQty, orderVoucherId) => {
      dispatch({
        type: UPDATE_CART_DETAIL,
        productId,
        cartItemId,
        cartQty,
        orderVoucherId,
      });
    },
    deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(CartSideBar);
