/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import {
  stripslashes,
  addressFormat,
  showAlert,
  showPriceValue,
} from "../Helpers/SettingHelper";
import { appId, deliveryId, apiUrlNotify, apiUrl } from "../Helpers/Config";

var Parser = require("html-react-parser");

class Reservationthankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderdetail: [],
      cartTriggerFlg: "no",
    };
  }

  componentWillMount() {
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";

    if (orderId !== "") {
      this.loadOrderDetails(orderId);
      //this.props.getOrderDetail(orderId);
      //this.sendOrderSMSNotification(orderId);
      /*this.sendNotification();*/
    } else {
      showAlert("Notice", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };
  loadOrderDetails(orderId) {
    axios
      .get(
        apiUrl +
          "reports/reservation_orderhistory_details?app_id=" +
          appId +
          "&local_order_no=" +
          orderId
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ orderdetail: res.data.result_set[0] });
        } else {
          showAlert("Notice", "Invalid order detail.");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.props.history.push("/");
        }
      });
  }
  loadTime(reservTime) {
    if (reservTime !== "" && reservTime !== null) {
      var todayDate = new Date();
      return Moment(
        new Date(
          todayDate.getFullYear() +
            "-" +
            todayDate.getMonth() +
            "-" +
            todayDate.getDate() +
            " " +
            reservTime
        )
      ).format("hh:mm A");
    }
  }

  render() {
    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        {/* container - start */}
        <div className="container">
          {/* innersection_wrap - start */}
          {Object.keys(this.state.orderdetail).length > 0 && (
            <div className="innersection_wrap tnk-you">
              {/* order-detail-maindiv - start */}
              <div className="tnkyou-upmarket">
                <div className="thank-order-lhs">
                  <div className="thank-order-detaildiv">
                    <div className="tnk-detail">
                      {/*<h2>YOUR ORDER DETAILS</h2>*/}
                      <div className="tnk-order tnky-indiv">
                        <h3>thank you for your Reservation!</h3>
                        <p className="tnky-para-one">
                          Your Reservation has been reserved sucessfully
                        </p>
                      </div>
                    </div>

                    <div className="tnk-detail mobile-detail">
                      {/*<h2>YOUR ORDER DETAILS</h2>*/}
                      <div className="tnk-order tnky-indiv">
                        <h3>
                          Order No -{" "}
                          {this.state.orderdetail.reservation_local_order_id}
                        </h3>
                      </div>
                    </div>

                    <div className="tnk-delivery">
                      <div className="delivery-cart-div">
                        <div className="cart_row cart-header-first">
                          <div className="row">
                            <div className="col-xs-6 cart_right upmarket-col-xs-12">
                              <h4>Reserve Outlet</h4>
                              <p>
                                {this.state.orderdetail.reservation_outlet_name}
                                {/* addressFormat(
                                    this.state.orderdetail.order_customer_unit_no1,
                                    this.state.orderdetail.order_customer_unit_no2,
                                    this.state.orderdetail.outlet_address_line1,
                                    this.state.orderdetail.order_customer_address_line2,
                                    this.state.orderdetail.order_customer_postal_code
                                  ) */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />

                      <div className="delivery_total delivery_total_number delivery_datetime_div">
                        <div className="delivery_total_left">
                          <div className="tnky-date-info">
                            <h2>Reservation Date</h2>
                          </div>

                          <h4 className="checkoutDate tnky-checkdate">
                            {Moment(
                              this.state.orderdetail.reservation_date
                            ).format("DD-MM-YYYY")}
                            ,{" "}
                            {this.loadTime(
                              this.state.orderdetail.reservation_start_time
                            )}
                          </h4>
                        </div>
                      </div>

                      <div className="tnk-chk-order tnk-uporder">
                        <Link
                          to={"/myreservations/"}
                          className="button"
                          id="up_track_order"
                        >
                          Check Booking Status
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="thank-order-rhs">
                  <div className="orderitem_body_div">
                    <div className="tnk-detail destop-detail">
                      <div className="tnk-order tnky-indiv">
                        <h3>
                          Order No -{" "}
                          {this.state.orderdetail.reservation_local_order_id}
                        </h3>
                      </div>
                    </div>

                    <div className="remark_notesec text-left">
                      <h4>Phone No</h4>
                      <p>
                        {this.state.orderdetail.reservation_cust_contact_no}
                      </p>
                    </div>
                    <div className="remark_notesec text-left">
                      <h4>Number of Pax</h4>
                      <p>
                        {parseInt(
                          this.state.orderdetail.reservation_no_of_adult_pax
                        ) &&
                          this.state.orderdetail.reservation_no_of_adult_pax +
                            " Adult"}{" "}
                        {parseInt(
                          this.state.orderdetail.reservation_no_of_children_pax
                        ) &&
                          "& " +
                            this.state.orderdetail
                              .reservation_no_of_children_pax +
                            " Child"}
                      </p>
                    </div>
                    {this.state.orderdetail.reservation_specification && (
                      <div className="remark_notesec text-left">
                        <h4>Special Instruction</h4>
                        <p>
                          {" "}
                          {stripslashes(
                            this.state.orderdetail.reservation_specification
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* order-detail-maindiv - end */}
            </div>
          )}
          {/* innersection_wrap - start */}
        </div>
        {/* container - end */}

        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }
  return {
    settingsArr: globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(Reservationthankyou);
