/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import cookie from "react-cookies";
import moment from "moment";
import { appId, apiUrl } from "../Helpers/Config";
import Axios from "axios";

import rejectImg from "../../common/images/worried.png";
var base64 = require("base-64");
var qs = require("qs");

class Reservationsuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = { message: "", reservation_data: [] };
  }

  componentDidMount() {
    let subreservationId =
      typeof this.props.match.params.resid != "undefined"
        ? this.props.match.params.resid
        : "";
    let acceptReject =
      typeof this.props.match.params.acceptreject != "undefined"
        ? this.props.match.params.acceptreject
        : "";

    var postObject = {};
    postObject = {
      app_id: appId,
      sug_reser_id: base64.decode(subreservationId),
    };

    let ajaxUrl = "";
    if (acceptReject === "accept") {
      ajaxUrl = apiUrl + "reservation/reservation_suggest_accept";
    }
    if (acceptReject === "reject") {
      ajaxUrl = apiUrl + "reservation/reservation_suggest_reject";
    }

    if (ajaxUrl) {
      Axios.post(ajaxUrl, qs.stringify(postObject)).then((res) => {
        $(".dvLoadrCls").fadeOut(2000);

        if (res.data.status === "success") {
          if (acceptReject == "accept") {
            this.setState({ acceptReject: "success" });
            this.setState({ message: "Your Reservation has been confirmed" });
            this.setState({ reservation_data: res.data.result_set[0] });
            this.setState({ outletName: res.data.outlet_name });
          }
          if (acceptReject == "reject") {
            this.setState({ acceptReject: "error" });
            this.setState({ message: "Reservation cancelled" });
          }
        } else if (res.data.status === "error") {
          this.setState({ acceptReject: "error" });
          this.setState({
            message: "Your Reservation already confirmed/rejected",
          });
        }
      });
    }
  }
  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="thankyou-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        {/* container - start */}
        <div className="container">
          {/* innersection_wrap - start */}
          <div className="mainacc_toptext tick">
            {this.state.acceptReject == "error" && (
              <div className="innersection_wrap tnk-you">
                <div>
                  <img src={rejectImg} alt="recject" />
                </div>
                <p>{this.state.message}</p>
                <div className="tnk-chk-order tnk-uporder">
                  <Link
                    to={"/myreservations/"}
                    className="button"
                    id="up_track_order"
                  >
                    Check Booking Status
                  </Link>
                </div>
              </div>
            )}
          </div>

          {this.state.acceptReject == "success" && (
            <div className="innersection_wrap tnk-you">
              {/* order-detail-maindiv - start */}
              <div className="tnkyou-upmarket">
                <div className="thank-order-lhs">
                  <div className="thank-order-detaildiv">
                    <div className="tnk-detail">
                      {/*<h2>YOUR ORDER DETAILS</h2>*/}
                      <div className="tnk-order tnky-indiv">
                        <h3>thank you for your Reservation!</h3>
                        <p className="tnky-para-one">{this.state.message}</p>
                      </div>
                    </div>

                    <div className="tnk-detail mobile-detail">
                      {/*<h2>YOUR ORDER DETAILS</h2>*/}
                      <div className="tnk-order tnky-indiv">
                        <h3>
                          Order No -{" "}
                          {
                            this.state.reservation_data
                              .reservation_local_order_id
                          }
                        </h3>
                      </div>
                    </div>

                    <div className="tnk-delivery">
                      <div className="delivery-cart-div">
                        <div className="cart_row cart-header-first">
                          <div className="row">
                            <div className="col-xs-6 cart_right upmarket-col-xs-12">
                              <h4>Reserve Outlet</h4>
                              <p>
                                {
                                  this.state.reservation_data
                                    .reservation_outlet_name
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />

                      <div className="delivery_total delivery_total_number delivery_datetime_div">
                        <div className="delivery_total_left">
                          <div className="tnky-date-info">
                            <h2>Reservation Date</h2>
                          </div>

                          <h4 className="checkoutDate tnky-checkdate">
                            {moment(
                              this.state.reservation_data.reservation_date
                            ).format("DD-MM-YYYY")}
                            ,{" "}
                            {moment(
                              this.state.reservation_data
                                .reservation_start_time,
                              "HH:mm:ss"
                            ).format("hh:mm A")}{" "}
                            -{" "}
                            {moment(
                              this.state.reservation_data.reservation_end_time,
                              "HH:mm:ss"
                            ).format("hh:mm A")}
                          </h4>
                        </div>
                      </div>

                      <div className="tnk-chk-order tnk-uporder">
                        <Link
                          to={"/myreservations/"}
                          className="button"
                          id="up_track_order"
                        >
                          Check Booking Status
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="thank-order-rhs">
                  <div className="orderitem_body_div">
                    <div className="tnk-detail destop-detail">
                      <div className="tnk-order tnky-indiv">
                        <h3>
                          Order No -{" "}
                          {
                            this.state.reservation_data
                              .reservation_local_order_id
                          }
                        </h3>
                      </div>
                    </div>

                    <div className="remark_notesec text-left">
                      <h4>Phone No</h4>
                      <p>
                        {
                          this.state.reservation_data
                            .reservation_cust_contact_no
                        }
                      </p>
                    </div>
                    <div className="remark_notesec text-left">
                      <h4>Number of Pax</h4>
                      <p>
                        {parseInt(
                          this.state.reservation_data
                            .reservation_no_of_adult_pax
                        ) &&
                          this.state.reservation_data
                            .reservation_no_of_adult_pax + " Adult"}{" "}
                        {parseInt(
                          this.state.reservation_data
                            .reservation_no_of_children_pax
                        ) &&
                          "& " +
                            this.state.reservation_data
                              .reservation_no_of_children_pax +
                            " Child"}
                      </p>
                    </div>
                    {this.state.reservation_data.reservation_specification !==
                      "" && (
                      <div className="remark_notesec text-left">
                        <h4>Special Instruction</h4>
                        <p>
                          {" "}
                          {stripslashes(
                            this.state.reservation_data
                              .reservation_specification
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* order-detail-maindiv - end */}
            </div>
          )}
          {/* innersection_wrap - start */}
        </div>
        {/* container - end */}
        <div id="dvLoading" className="dvLoadrCls"></div>
        {/* Footer section */}
        <Footer />
      </div>
    );
  }
}

export default Reservationsuggestion;
