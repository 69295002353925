/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_MALLS_OUTLETS, SET_MALLS_OUTLETS } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetMallsOutlets = function* () {
  yield takeEvery(GET_MALLS_OUTLETS, workerGetMallsOutlets);
};

function* workerGetMallsOutlets({shop_type, postal_code}) {
  try {
    const uri = apiUrl + "brands/get_all_malls_outlets?app_id=" + appId + "&shop_type=" + shop_type + "&postal_code=" + postal_code;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_MALLS_OUTLETS, value: resultArr });
  } catch {
    console.log("Get Events Failed");
  }
}
