/* eslint-disable */
import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import {
  fbAppId,
  baseUrl,
  appId,
  apiUrl,
  cateringId,
  stripeCompany,
} from "../Helpers/Config";

import { validated } from "react-custom-validation";
import validator from "validator";

import DatePicker from "react-datepicker";
import { setMinutes, setHours, format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
const isEmpty = (value) => (value === "" ? "This field is required." : null);
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

const minLength = (password, length) =>
  password.length >= length ? null : "Password must be at least 6 characters.";

const areSame = (password, repassword) =>
  password === repassword ? null : "Password do not match.";

const isChecked = (terms) =>
  terms ? null : "Please accept terms and conditions";


const isChecked_pdpa = (pdpa_consent) =>
  pdpa_consent ? null : "Please accept PDPA Consent";

function validationConfigSignup(props) {
  const { firstname, email, password, repassword, mobile, terms } =
    props.fields;

  return {
    fields: ["firstname", "email", "password", "repassword", "mobile", "terms"],

    validations: {
      firstname: [[isEmpty, firstname]],
      email: [
        [isEmail, email],
        [isUnique, email],
      ],
      password: [[minLength, password, 6]],
      repassword: {
        rules: [[areSame, password, repassword]],
        fields: ["password", "repassword"],
      },
      mobile: [
        [isEmpty, mobile],
        [isMobile, mobile],
        [isPhonenumerExist, mobile],
      ],
      terms: [[isChecked, terms, 1]],
      //pdpa_consent: [[isChecked, pdpa_consent, 1]],
    },
  };
}

const phonenumberPattern = /^[0-9]{6,14}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Mobile Number.";

const isUnique = (email) => {
  var qs = require("qs");
  var postObject = {
    app_id: appId,
    type: "web",
    customer_email: email,
  };

  axios
    .post(apiUrl + "customer/email_exist", qs.stringify(postObject))
    .then((response) => {
      if (response.data.status === "ok") {
        document.getElementById("spn-email-error").innerHTML = "";
      } else {
        document.getElementById("spn-email-error").innerHTML =
          '<span class="error">Email already exists</span>';
      }
    });
};

const isPhonenumerExist = (mobile) => {
  var qs = require("qs");
  var postObject = {
    app_id: appId,
    type: "web",
    customer_phone: mobile,
  };
  axios
    .post(apiUrl + "customer/phoneno_exist", qs.stringify(postObject))
    .then((response) => {
      if (response.data.status === "ok") {
        document.getElementById("spn-mobile-error").innerHTML = "";
      } else {
        document.getElementById("spn-mobile-error").innerHTML =
          '<span class="error">Mobile Number already exists</span>';
      }
    });
};

class Signup extends Component {
  constructor(props) {
    super(props);
  }

  closepopup(path) {
    const { history } = this.props;
    history.push(path);
    $.magnificPopup.close();
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    const spanStyle = {
      clear: "both",
    };
    let errMsgFirstName,
      errMsgEmail,
      errMsgpassword,
      errMsgrepassword,
      errMsgMobile,
      errMsgTerms;

    if ($validation.firstname.error.reason !== undefined) {
      errMsgFirstName = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.password.error.reason !== undefined) {
      errMsgpassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    }
    if ($validation.repassword.error.reason !== undefined) {
      errMsgrepassword = $validation.repassword.show && (
        <span className="error">{$validation.repassword.error.reason}</span>
      );
    }
    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }

    if ($validation.terms.error.reason !== undefined) {
      errMsgTerms = $validation.terms.show && (
        <span className="error">{$validation.terms.error.reason}</span>
      );
    }



    return (
      <div className="popup-body">
        <h4>Enter Your Information</h4>
        <div className="form-group">
          <div className="focus-out">
            <label>Your Name</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.firstname}
              {...$field("firstname", (e) =>
                onChange("firstname", e.target.value)
              )}
            />
            {errMsgFirstName}
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Your Mobile Number</label>
            <input
              type="tel"
              className="form-control input-focus"
              pattern="\d*"
              value={fields.mobile}
              maxLength={8}
              {...$field("mobile", (e) => onChange("mobile", e.target.value))}
            />
            <div id="spn-mobile-error">{errMsgMobile}</div>
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Your Email Address</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.email}
              {...$field("email", (e) => onChange("email", e.target.value))}
            />
            <div id="spn-email-error">{errMsgEmail}</div>
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Enter Your Password</label>
            <input
              type="password"
              className="form-control input-focus"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
            />
            {errMsgpassword}
          </div>
          <p className="password-info">
            <i>Password must be at least 6 characters.</i>
          </p>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Re Enter Your Password</label>
            <input
              type="password"
              className="form-control input-focus"
              value={fields.repassword}
              {...$field("repassword", (e) =>
                onChange("repassword", e.target.value)
              )}
            />
            {errMsgrepassword}
          </div>
        </div>




        <div className="form-group">
          <div className="custom_checkbox custom_checkbox_content">
            <input
              type="checkbox"
              id="terms"
              checked={fields.terms}
              {...$field(
                "terms",
                (e) => onChange("terms", e.target.value),
                null,
                false
              )}
            />
            <span></span>
            <div className="sign_reg">
              <p>
                I have read and agree to the  {" "}
                
                <Link
                  className="sign_reg_uline"
                  to={"/terms-of-use"}
                  target="_blank"
                >
                Terms &amp; Conditions.
                </Link> {" "}

                 I have read and accept the  {" "}

                <Link
                  className="sign_reg_uline"
                  to={"/pdpa-consent"}
                  target="_blank"
                > 
                 Privacy Policy.
                </Link>
              </p>
            </div>
          </div>
          {errMsgTerms}
        </div>


        <div className="form-group">
          <div className="login_pop_sub">
            <button
              type="button"
              className="btn btn_black btn_minwid signup_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
        </div>

        <div className="form-group-11">
          <div className="controls single-link">
            <a href="#login-popup" className="open-popup-link">
              Back to login
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Signup = validated(validationConfigSignup)(Signup);

export default withRouter(Signup);
