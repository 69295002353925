import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_MULTIPLE_MARKET_SEARCH, SET_MULTIPLE_MARKET_SEARCH } from '../actions';
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from 'react-cookies';

export const watchGetMultimarketsearch = function* () {
  yield takeEvery(GET_MULTIPLE_MARKET_SEARCH, workerGetMultimarketsearch);
}

function* workerGetMultimarketsearch({search_key, shop_type}) {

  var delivery_postal_code = 
      cookie.load("orderPostalCode") !== "" &&
      typeof cookie.load("orderPostalCode") !== undefined &&
      typeof cookie.load("orderPostalCode") !== "undefined"
        ? cookie.load("orderPostalCode")
        : "";
  try {
    const uri = apiUrl+'brands/mall_outlet_search?app_id='+appId + "&shop_type=" + shop_type + "&search_key="+ search_key + "&postal_code="+ delivery_postal_code;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
  yield put({ type: SET_MULTIPLE_MARKET_SEARCH, value: resultArr });
  } 

  catch {
    console.log('Get Banner Failed');
  }
} 
