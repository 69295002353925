/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_HIGHLIGHTPROLIST, SET_HIGHLIGHTPROLIST } from "../actions";
import { apiUrl, appId } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";
export const watchGetHighlightproductslist = function* () {
  yield takeEvery(GET_HIGHLIGHTPROLIST, workerGetHighlightproductslist);
};

function* workerGetHighlightproductslist(params) {
  try {
    var addparams = "";
    if (
      params !== "" &&
      typeof params !== undefined &&
      typeof params !== "undefined"
    ) {
      addparams = params.params;
    }
    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");

    var outlet =
      cookie.load("orderOutletId") !== undefined &&
      cookie.load("orderOutletId") !== "undefined" &&
      cookie.load("orderOutletId") !== ""
        ? cookie.load("orderOutletId")
        : "";
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
        ? "&fav_cust_id=" + cookie.load("UserId")
        : "";

    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    const uri =
      apiUrl +
      "products/highlight_products_list?app_id=" +
      appId +
      "&customer_id=" +
      userID +
      "&outlet=" +
      outlet +
      spicelPride +
      addparams;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_HIGHLIGHTPROLIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Highlight Products List Failed");
  }
}
