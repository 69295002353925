import { SET_BRANDS } from "../actions";

const brands = (state = [], action) => {
  switch (action.type) {
    case SET_BRANDS:
      return [...action.value];
    default:
      return state;
  }
};

export default brands;
