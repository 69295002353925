import { SET_SITE_LOCATION } from '../actions';

const sitelocation = (state = [], action) => {

  switch (action.type) {
    case SET_SITE_LOCATION:
      return [...action.value];

    default: return state;
  }
}


export default sitelocation;