/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  brandUrl,
  mediaUrl,
  siteURL
} from "../Helpers/Config";
import { stripslashes, hideLoader} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import innerbanner from "../../common/images/mall-banner.jpg"; 
import brandImg from "../../common/images/tusta.jpg";
import brandImg1 from "../../common/images/burgs.jpg";
import brandImg2 from "../../common/images/cqc.jpg";
import brandImg3 from "../../common/images/affair.jpg";

import filter from "../../common/images/filter.png";
import searchorg from "../../common/images/search-org.png";
import noimage from "../../common/images/no-img-product.png";
import cookie from "react-cookies";



import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GET_GLOBAL_SETTINGS, GET_REQUESTPAGEDATA, GET_BRANDS} from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

var Parser = require("html-react-parser");

class BrandList extends Component {
  constructor(props) {
    super(props);
    this.state = { settings:[], brandlist:[], branddetails:'', brandbackgroundImg:'', tag: []};
    this.props.getSettings();
    this.props.getBrands();
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);
  }

  componentWillReceiveProps(nextProps) {
  
      if (nextProps.globalsettings !== this.state.settings) {
      if (nextProps.globalsettings.length > 0) {
        if (nextProps.globalsettings[0].status === "ok") {
          this.setState({ settings: nextProps.globalsettings[0].result_set });
        }
      }
    }

     if (nextProps.brandlist !== this.state.brandlist) {
      if(Object.keys(nextProps.brandlist).length > 0){
      this.setState({ brandlist: nextProps.brandlist[0].result_set, tag: nextProps.brandlist[0].tag }, function () {
       this.displayBrands();
      });
     }
    }

  }
  sateValChange = (field, value) => {};


 checkAvailabilityBrand(brand_slug, brand_id){

    var orderOutletId = cookie.load("orderOutletId");

    if (orderOutletId === "" || orderOutletId === undefined) {

      cookie.save("home_brand_id", brand_id);
      cookie.save("brand_slug", brand_slug);

     $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });
      return false;

    }else{

      if(brand_slug !== null && brand_slug !== '' && brand_id !== '') {
      cookie.save("home_brand_id", brand_id);
      cookie.save("brand_slug", brand_slug);
      location.href = siteURL + "brands/" +  brand_slug;
    }

   }

  }


    loadTag(tag_id) {
    if (tag_id !== "" && tag_id !== null && tag_id !== undefined) {
      let tagID = tag_id.split(",");
      let tagList = this.state.tag;
      if (tagID.length > 0) {
        return tagID.map(function (item, index) {
          if (
            tagList[item] !== "" &&
            typeof tagList[item] !== undefined &&
            typeof tagList[item] !== "undefined"
          ) {
            return (
              <span key={index}>
                {tagList[item]}
                {tagID.length - 1 != index ? ", " : ""}
              </span>
            );
          }
        });
      }
    }
  }

  displayBrands(){
    if (Object.keys(this.state.brandlist).length > 0 && this.state.brandlist !== undefined && this.state.brandlist !== null) {
      var brands_list = this.state.brandlist.map((item, index)=>{
        return(<li>
                   <a onClick={this.checkAvailabilityBrand.bind(this, item.brand_slug, item.brand_id)} to={"/brands/" + item.brand_id}>   
                    <div className="market-image">
                           <img src={
                                item.brand_active_image !== ""
                                  ? brandUrl  + item.brand_active_image
                                  : noimage
                              } alt="" />
                    </div>   
                   <div className="market-name">
                        <h4>{item.brand_name}</h4>
                        <p>{item.brand_tags !== "" && this.loadTag(item.brand_tags)}</p>
                    </div>   
                   </a> 
                </li> );
      });
      return brands_list;
   }
  }

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />

         <div className="innerbanner">
              <img src={innerbanner} alt="" />    
         </div>


         <div className="search-and-info">
           <div className="container"> 
           <h2>Brands</h2>
            </div>
         </div>
        <section className="markets mall-inner">
          <div className="container">
            <ul> 

              {this.displayBrands()}
  
            </ul>  
          </div>
        </section>
        
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    globalsettings: state.settings,
    brandlist: state.brands
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getBrands: () => {
      dispatch({ type: GET_BRANDS });
    }
    };
};

BrandList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BrandList));
