/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";

import { stripslashes, hideLoader,  searchFilters, showLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import innerbanner from "../../common/images/mall-banner.jpg"; 
import brandImg from "../../common/images/tusta.jpg";
import brandImg1 from "../../common/images/burgs.jpg";
import brandImg2 from "../../common/images/cqc.jpg";
import brandImg3 from "../../common/images/affair.jpg";
import moveright from "../../common/images/arrow-right.png";
import noimage from "../../common/images/no-img-product.png";
import filter from "../../common/images/filter.png";
import searchorg from "../../common/images/search-org.png";
import closebl from "../../common/images/close-orange.png";
import groupdark from "../../common/images/group.png";
import groupwhite from "../../common/images/group-white.png";
import closeImg from "../../common/images/close.png";


import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import Slider from "react-slick";


import {
  apiUrl,
  apiUrlV2,
  appId,
  mediaUrl,
  deliveryId,
  pickupId,
  siteURL,
} from "../Helpers/Config";
import { GET_GLOBAL_SETTINGS, GET_REQUESTPAGEDATA, GET_STATIC_BLOCK, GET_ALL_OUTLETS, GET_EVENTS, GET_SITE_LOCATION, GET_MALLS_OUTLETS, GET_MULTIPLE_MARKET_SEARCH} from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";

var Parser = require("html-react-parser");

class Multiplemarket  extends Component {
  constructor(props) {
    super(props);

    let defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var deliveryDate = cookie.load("deliveryDate");
    var currentPageUrl = this.props.location.pathname.split("/");
    let currentPageUrll = "";
    if (this.props.location.pathname === "/") {
      currentPageUrll = "food";
    } else {
      currentPageUrll =
        currentPageUrl[1] !== "" &&
        typeof currentPageUrl[1] !== undefined &&
        typeof currentPageUrl[1] !== "undefined"
          ? currentPageUrl[1]
          : "";
      currentPageUrll = "food";
    }
    if (currentPageUrll === "retail") {
      if (
        defaultAvilTy === "" ||
        typeof defaultAvilTy === undefined ||
        typeof defaultAvilTy === "undefined"
      ) {
        var deliveryDate1 = new Date();

        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        
        cookie.save(
          "deliveryDate",
          deliveryDate1.getDate() +
            "/" +
            deliveryDate1.getMonth() +
            "/" +
            deliveryDate1.getFullYear(),
          { path: "/" }
        );
        cookie.save(
          "deliveryTime",
          deliveryDate1.getHours() +
            ":" +
            deliveryDate1.getMinutes() +
            ":" +
            deliveryDate1.getSeconds(),
          { path: "/" }
        );
        var orderDateTime = new Date().toISOString();
        cookie.save("orderDateTime", orderDateTime, { path: "/" });

        defaultAvilTy = pickupId;
        this.loadPickupOutlet();
      }
    }




    const orderPostalCode =
      cookie.load("orderPostalCode") !== "" &&
      typeof cookie.load("orderPostalCode") !== undefined &&
      typeof cookie.load("orderPostalCode") !== "undefined"
        ? cookie.load("orderPostalCode")
        : "";


    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";

      let uriparms_one = '';
      let pathname = props.location.pathname;
      pathname = pathname.split('/');
      uriparms_one = pathname[2];

    this.state = {
      defaultAvilTy: defaultAvilTy,
      orderPostalCode: orderPostalCode,
      deliveryPostalCode: deliveryPostalCode,
      outletslist: [],
      searchoutletslist: [],
      outletsMainlist: [],
      displayOutlets: "",
      totalRes: "",
      settings: [],
      tag: [],
      tagdisplay: "",
      promotion: [],
      order_date: new Date(),
      selectPostalCode: "No",
      filterTag: [],
      eventList: [],
      eventImagePath: "",
      event_List: "",
      urltype: this.props.match.path,
      eventSlug:
        this.props.match.params.slugEvent !== "" &&
        typeof this.props.match.params.slugEvent !== undefined &&
        typeof this.props.match.params.slugEvent !== "undefined"
          ? this.props.match.params.slugEvent
          : "",
      locationSlug:
        this.props.match.params.sluglocation !== "" &&
        typeof this.props.match.params.sluglocation !== undefined &&
        typeof this.props.match.params.sluglocation !== "undefined"
          ? this.props.match.params.sluglocation
          : "",
      staticblack: [],
      restaurantcontent: [],
      retailcontent: [],
      siteLocationList: [],
      mallslug: uriparms_one,
      mallname: "",
      malloutletlist: [],
      addClass: "yes", 
      marketPlacetype: "Restaurants",
      multiplemarketsearch: [],
      notfoundError:false,
      searchProKeyPress:"",
      multimallsearch:false,

    };
    
    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      this.props.history.push("/");
      return;
    }

    
    this.handleChange = this.handleChange.bind(this);
    this.changeSiteLocation = this.changeSiteLocation.bind(this);
    this.props.getSettings();
    this.props.getStaticBlock();
    this.props.getloadSiteLocation();

    var shop_type = "";

    if (
      this.props.match.path === "/marketplace" ||
      this.props.match.path === "marketplace/:sluglocation"
    ) {
      shop_type = 1;
    } else if (this.props.match.path === "/retail/:sluglocation") {
      shop_type = 2;
    } else {
      shop_type = 3;
    }
    this.props.getAllOutlets(
      defaultAvilTy,
      this.state.eventSlug,
      shop_type,
      deliveryPostalCode
    );

    this.props.getEvents();
    this.props.getloadSiteLocation();

    var postal_code =  cookie.load("nearest_postal_code") !== "" &&
    typeof cookie.load("nearest_postal_code") !== undefined &&
    typeof cookie.load("nearest_postal_code") !== "undefined"
      ? cookie.load("nearest_postal_code")
      : cookie.load("orderPostalCode");

    this.props.getMallOutlets(shop_type, postal_code);

  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);

    var RctThis = this;
   $('.search-rest').keyup(function(e){
    var values = $(".search-rest").val();
    if(e.keyCode == 13)
    {
      if(values.length > 1){
        RctThis.searchtotalmaket(values);
      }
    }
    });

  }

  componentWillReceiveProps(PropsDt) {

     if (PropsDt.malloutletlist !== this.props.malloutletlist) {
      if(Object.keys(PropsDt.malloutletlist).length > 0){

      if(PropsDt.malloutletlist !== undefined &&  PropsDt.malloutletlist !== "" && PropsDt.malloutletlist !== null){


        $(".dvLoadrCls").fadeOut(500);
        this.setState(
          {
            outletslist: PropsDt.malloutletlist[0].result_set,
            eventOutletsList: PropsDt.malloutletlist[0].result_set,
            searchoutletslist: PropsDt.malloutletlist[0].result_set,
            outletsMainlist: PropsDt.malloutletlist[0].result_set,
            tag: PropsDt.malloutletlist[0].tag,
             notfoundError: false,
            },
          function () {
            this.displayOutlets();
            this.displaytag();
          }
        );


        hideLoader("mall-inner", "class");

      } else {
        this.setState(
          {
            outletslist: [],
            eventOutletsList: [],
            searchoutletslist: [],
            outletsMainlist: [],
            tag: [],
            promotion: [],
            notfoundError: true
          },
          function () {
            this.displayOutlets();
            this.displaytag();
          }
        );

       hideLoader("mall-inner", "class");

      }
    }
  }


     if(PropsDt.multiplemarketsearch !== this.props.multiplemarketsearch) {
       
      if(Object.keys(PropsDt.multiplemarketsearch).length > 0){
      if(PropsDt.multiplemarketsearch !== undefined &&  PropsDt.multiplemarketsearch !== "" && PropsDt.multiplemarketsearch !== null){
        
        this.setState(
          {
            outletslist: PropsDt.multiplemarketsearch[0].result_set,
            eventOutletsList: PropsDt.multiplemarketsearch[0].result_set,
            searchoutletslist: PropsDt.multiplemarketsearch[0].result_set,
            outletsMainlist: PropsDt.multiplemarketsearch[0].result_set,
            tag: PropsDt.multiplemarketsearch[0].tag,
            notfoundError: false,
            },
          function () {
            this.displayOutlets();
            this.displaytag();
          }
        );
        $(".dvLoadrCls").fadeOut(200);
        hideLoader("mall-inner", "class");

      } else {
        $(".dvLoadrCls").fadeOut(200);
        this.setState(
          {
            outletslist: [],
            eventOutletsList: [],
            searchoutletslist: [],
            outletsMainlist: [],
            tag: [],
            promotion: [],
            notfoundError: true,
          },
          function () {
            this.displayOutlets();
            this.displaytag();
          }
        );

       hideLoader("mall-inner", "class");

      }
    }
  }



    if (PropsDt.eventList !== this.props.eventList) {
      if (PropsDt.eventList[0].status === "ok") {
        this.setState(
          {
            eventList: PropsDt.eventList[0].result_set,
            eventImagePath: PropsDt.eventList[0].imagepath,
          },
          function () {
           //this.displayEvents();
          }
        );
      } else {
        this.setState(
          {
            eventList: [],
            eventImagePath: "",
          },
          function () {
            //this.displayEvents();
          }
        );
      }
    }

    if (PropsDt.match.url !== this.props.match.url) {
      var pageslug = PropsDt.match.path;

      var eventSlug =
        PropsDt.match.params.slugEvent !== "" &&
        typeof PropsDt.match.params.slugEvent !== undefined &&
        typeof PropsDt.match.params.slugEvent !== "undefined"
          ? PropsDt.match.params.slugEvent
          : "";
      this.setState({ urltype: pageslug, eventSlug: eventSlug }, function () {
        //this.displayEvents();
        var shop_type = "";
        if (pageslug === "/mall/:sluglocation" || pageslug === "/") {
          shop_type = 1;
        } else if (pageslug === "/retail/:sluglocation") {
          shop_type = 2;
        } else {
          shop_type = 3;
        }
        this.props.getAllOutlets(
          this.state.defaultAvilTy,
          eventSlug,
          shop_type,
          this.state.deliveryPostalCode
        );
      });
    }
    if (PropsDt.staticblack !== this.state.staticblack) {
      this.setState({ staticblack: PropsDt.staticblack });
      var resPas = "";
      var retailPas = "";
      if (Object.keys(PropsDt.staticblack).length > 0) {
        PropsDt.staticblack.map((data) => {
          if (data.staticblocks_slug === "restaurants-content") {
            resPas = data;
          }
          if (data.staticblocks_slug === "retail-content") {
            retailPas = data;
          }
        });
      }
      this.setState({ restaurantcontent: resPas, retailcontent: retailPas });
    }
    if (PropsDt.globalsettings !== this.state.settings) {
      if (PropsDt.globalsettings.length > 0) {
        if (PropsDt.globalsettings[0].status === "ok") {
          this.setState({ settings: PropsDt.globalsettings[0].result_set });
        }
      }
    }


    if (PropsDt.siteLocationList !== this.state.siteLocationList) {
       if (Object.keys(PropsDt.siteLocationList).length > 0) {
        if (PropsDt.siteLocationList[0].status === "ok") {
          this.setState({ siteLocationList: PropsDt.siteLocationList[0].result_set, locationImage: PropsDt.siteLocationList[0].common}, function(){
            this.displaymallname()
          } );
        }
      }
     
    }

  }


  openSingleMarket(slug, sl_location_id){
   
    if (slug == null) {
      
    cookie.save("sl_location_id", sl_location_id, { path: "/" });
    
    }else if(slug !==""){

      cookie.save("sl_location_id", sl_location_id, { path: "/" });

     var shopType = cookie.load("shopType") !== "" &&
      typeof cookie.load("shopType") !== undefined &&
      typeof cookie.load("shopType") !== "undefined"
        ? cookie.load("shopType")
        : "1";

      if(shopType === '1'){

         location.href = "mall/" + slug;

      }else {

         location.href = "retail/" + slug;
      }
      
    }

  }
  
  displayOutlets() {
    /*  no-res-pro */
    let outlet = "";
    let current = this;
    let totalRes = 0;
    var captolOutletTotal = 0;

    if (
      this.state.outletslist !== "" &&
      typeof this.state.outletslist !== undefined &&
      typeof this.state.outletslist !== "undefined" &&
      this.state.outletslist.length > 0
    ) {

      outlet = this.state.outletslist.map((item, index) => {
        if(Object.keys(item.outlets).length > 0){
          totalRes++;
          captolOutletTotal++;

          var slidesToShowCount = (Object.keys(item.outlets).length >= 3 ? 3 : Object.keys(item.outlets).length);

          var OutletSlider = {
          dots: false,
          arrows: true,
          slidesToShow: slidesToShowCount,
          slidesToScroll: 1,
          infinite: (slidesToShowCount >= 3 ? true: false ),
          autoplay: true,
          Speed: 5000,
          variableWidth: true,

          responsive: [
            {
              breakpoint: 1100,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: false,
                infinite: true,
              },
            },
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                infinite: true,
              },
            },
            {
              breakpoint: 780,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                infinite: true,
              },
            },

              {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                infinite: true,
              },
            },
          ],
        };

         if(item.sl_slug !=="" && item.sl_slug !==null){
          return (
          <div className="markets multimarket">
            <div className="multimarket-inner">
            
              <h3 className="multimarket_place_title" onClick={this.openSingleMarket.bind(this,item.sl_slug, item.sl_location_id)}>
               {this.state.marketPlacetype} at <span> {item.sl_name} 
               <strong>{ item.sl_kilo_meters} </strong></span>
              </h3>

              <div className="multimarket-inner-block-div">
              <Slider {...OutletSlider}>
                  {item.outlets.map((outlet, key)=>{

                    if(outlet.outlet_id !== '163'){

                    current.checkTimeAvilablity(outlet.outlet_id);
                      return (<div key={key} className={"in_height_market_"+(slidesToShowCount >= 3 ? "4": slidesToShowCount)} id={"res_" + outlet.outlet_id} style={{ width: 339}}>
                        <div className="multimarket-single">
                          <Link onClick={current.viewProducts.bind(
                                current,
                                outlet.outlet_id,
                                outlet.outlet_slug,
                                outlet.outlet_location_id,
                                item.sl_slug,
                                item.sl_location_id,
                                item.sl_name
                            )}> 
                            <div class="market-image"> 
                                <img src={
                                  outlet.outlet_image !== ""
                                    ? mediaUrl + "outlet/" + outlet.outlet_image
                                    : noimage
                                } alt="" />     
                           
                             {/*<div className="offer-tag">
                                <span>15 <br /> min </span> 
                                <strong>20% <br /> off </strong>
                              </div>*/}

                              </div>

                            <div class="market-name">
                              <h4>{outlet.outlet_name}</h4>
                              <p>{outlet.outlet_tag_id !=="" ? current.loadTag(outlet.outlet_tag_id) : ''}</p>
                              <div className="shop_closed"></div>
                            </div>     
                          </Link>
                        </div>
                      </div>
                    );
                  }
                  })
                  }
                </Slider>
              </div>
            </div>  
          </div>
          );
        }
      }
      });
      if (captolOutletTotal === 0) {
        outlet = "";
      }
    }

    hideLoader("innerproduct", "class");
    this.setState({
      displayOutlets: outlet,
      totalRes: totalRes,
    });
  }


  displaytag() {
    var tagdisplay = "";
    var tags = this.state.tag;
    if (tags !== "" && tags !== null && tags !== undefined) {
      var current1 = this;

      const keysSorted = Object.keys(tags).sort(function(a,b){
        return b-a

       });
       
      tagdisplay = Object.keys(tags).map(function (item, index) {
        return (
          <li className="custom_checkbox" key={index}>
            <input
              type="checkbox"
              className="restaurants_tag"
              onChange={current1.handleChange}
              value={item}
            />
            <span>{tags[item]}</span>
          </li>
        );
      });
    }
    this.setState({ tagdisplay: tagdisplay });
  }


  loadTag(tag_id) {
  
    if (tag_id !== "" && tag_id !== null && tag_id !== undefined) {
      let tagID = tag_id.split(",");
      let tagList = this.state.tag;
      if (tagID.length > 0) {
        return tagID.map(function (item, index) {
          if (
            tagList[item] !== "" &&
            typeof tagList[item] !== undefined &&
            typeof tagList[item] !== "undefined"
          ) {
            return (
              <span key={index}>
                {tagList[item]}
                {tagID.length - 1 != index ? ", " : ""}
              </span>
            );
          }
        });
      }
    }
  }

  loadPromo(outlet_id) {
    let promotion = this.state.promotion;
    if (promotion !== "") {
      if (
        promotion[outlet_id] !== "" &&
        typeof promotion[outlet_id] !== undefined &&
        typeof promotion[outlet_id] !== "undefined"
      ) {
        return promotion[outlet_id].map(function (item, index) {
          return <span key={index}>{item}</span>;
        });
      }
    }
  }

  displayEvents() {
    var event_List = "";
    var ecurrent = this;
    if (this.state.eventList.length > 0) {
      event_List = this.state.eventList.map(function (item, index) {
        return (
          <li
            key={index}
            className={
              ecurrent.state.urltype === "/events/:slugEvent/" &&
              ecurrent.state.eventSlug === item.event_slug
                ? "active"
                : ""
            }
          >
            <Link to={"/events/" + item.event_slug}>{item.event_name}</Link>
          </li>
        );
      });
    }
    this.setState({ event_List: event_List });
  }


  viewProducts(outlet_id, outlet_slug, outlet_location_id, locationSlug, sl_location_id, sl_name) {

   var defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
        
    if(defaultAvilTy !=="" && defaultAvilTy !==null && defaultAvilTy !==undefined){

       if(outlet_id !=="" && outlet_location_id !=="" && outlet_slug !=="" && locationSlug!==''){
       var shopType = "food";

      if (
      this.state.marketPlacetype === "Restaurants") {
        shopType = "food";
        cookie.save("shopType", '1', { path: "/" });
      }else{
        shopType = "retail";
        cookie.save("shopType", '2', { path: "/" });
      }
      cookie.remove("orderOutletId", { path: "/" });
      cookie.remove("tempOutletLocationId", { path: "/" });
      cookie.save("orderOutletId", outlet_id, { path: "/" });
      cookie.save("tempOutletLocationId", outlet_location_id, { path: "/" });
      cookie.save("sl_location_id", sl_location_id, { path: "/" });
      cookie.save("sl_name", sl_name, { path: "/" });
      
      
      location.href =
        siteURL + shopType + "/" + locationSlug + "/" + outlet_slug;
      }
  
    }else{

      cookie.save("sl_location_id", sl_location_id, { path: "/" });
      
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
      return false;

    }

   }

  checkTimeAvilablity(outletID) {
    if (outletID !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      if (deliveryTime !== "") {
        axios
          .get(
            apiUrlV2 +
              "settings/chkOutletTimesAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              this.state.defaultAvilTy +
              "&outletID=" +
              outletID +
              "&orderDateTime=" +
              orderDate +
              " " +
              deliveryTime
          )
          .then((response) => {
            var res_min_date = "";
            if (response.data.min_date !== "") {
              res_min_date = moment(response.data.min_date).format("D MMM YY");
            }
            //$("#res_" + outletID + " .restaurant-date").html(res_min_date);
            if (response.data.status === "success") {
              $("#res_" + outletID).removeClass("no-res-pro");
              $("#res_" + outletID + " .shop_closed")
                .hide()
                .html("");
            } else {
              $("#res_" + outletID).addClass("no-res-pro");
              $("#res_" + outletID + " .shop_closed")
                .show()
                .html("Temporarily closed");
            }
          });
      }
    }
  }
   sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "selectPostalCode") {
      if (value == "Yes") {
        cookie.save("defaultAvilablityId", deliveryId, { path: "/" });
        this.setState(
          { defaultAvilTy: deliveryId, selectPostalCode: "No" },
          function () {
            showLoader("innerproduct", "class");
            var shop_type = "";
            if (
              this.props.match.path === "/mall/:sluglocation" ||
              this.props.match.path === "/"
            ) {
              shop_type = 1;
            } else if (this.props.match.path === "/retail/:sluglocation") {
              shop_type = 2;
            } else {
              shop_type = 3;
            }
            this.props.getAllOutlets(
              deliveryId,
              this.state.eventSlug,
              shop_type,
              this.state.deliveryPostalCode
            );
          }
        );
      }
    }
    if (field === "postalcode") {
      this.setState({ orderPostalCode: value });
      if (this.state.defaultAvilTy === deliveryId) {
        this.setState({ deliveryPostalCode: value });
      }
    }
    if (field === "loadOutlet" && value !== "") {
      var outletdata = value.split("_");
      this.setState({ defaultAvilTy: outletdata[0] });
      this.props.getAllOutlets(outletdata[0], "", 1, outletdata[1]);
    }
  };

   handleChange(event) {
    let filterTag = this.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    this.setState({ filterTag: filterTag }, function () {
      this.applyFilter();
    });
  }



  displaymallname(){
    if(this.state.siteLocationList !=="" && this.state.siteLocationList !==undefined && this.state.siteLocationList !==null){
      var mallname  = "";
      var malllist = this.state.siteLocationList.map((item, index)=>{
        if(item.sl_slug === this.state.mallslug){
          mallname = item.sl_name;
        }
      });

      this.setState({
        mallname: mallname
      });
    } 
  }


  searchPro = (event) => {
    var value = event.target.value.toLowerCase();
     this.setState({
      search_key_value : value,
      multimallsearch:true,
    });
  };

  clearMultimallsearch(event){
    event.preventDefault();

     var shop_type = "";

    if ( this.props.match.path === "/marketplace" || this.props.match.path === "marketplace/:sluglocation" ) {
      shop_type = 1;
    } else if (this.props.match.path === "/retail/:sluglocation") {
      shop_type = 2;
    } else {
      shop_type = 3;
    }

     var postal_code =  cookie.load("nearest_postal_code") !== "" &&
      typeof cookie.load("nearest_postal_code") !== undefined &&
      typeof cookie.load("nearest_postal_code") !== "undefined"
        ? cookie.load("nearest_postal_code")
        : cookie.load("orderPostalCode");

      this.setState({
      search_key_value : '',
      multimallsearch:false,
      multiplemarketsearch: [],
      outletslist: [],
      eventOutletsList: [],
      searchoutletslist: [],
      outletsMainlist: [],
      tag: [],
      promotion: [],
      notfoundError: false,
    }, function(){

  
    });

   $(".dvLoadrCls").show(); 
   this.props.getMallOutlets(shop_type, postal_code);

  }

  searchProKeyPress = (event) => {

    var value = this.state.search_key_value.toLowerCase();
    if(value !=="" && value.length > 2){
    this.searchtotalmaket(value);
    }

  };

   searchtotalmaket(value){

      var shop_type = "";

    if (
      this.props.match.path === "/marketplace" ||
      this.props.match.path === "marketplace/:sluglocation"
    ) {
      shop_type = 1;
    } else if (this.props.match.path === "/retail/:sluglocation") {
      shop_type = 2;
    } else {
      shop_type = 3;
    }

    if(value !==""){
    $(".dvLoadrCls").show(); 
    this.props.getserachValue(value, shop_type);
    }else{

       var postal_code =  cookie.load("nearest_postal_code") !== "" &&
        typeof cookie.load("nearest_postal_code") !== undefined &&
        typeof cookie.load("nearest_postal_code") !== "undefined"
          ? cookie.load("nearest_postal_code")
          : cookie.load("orderPostalCode");
    $(".dvLoadrCls").show();
    this.props.getMallOutlets(shop_type, postal_code);

    }
  }



  applyFilter() {
    var current2 = this;
    var matches = [];
    if (current2.state.filterTag.length > 0) {
      matches = this.state.searchoutletslist.filter(function (item) {
        if (item.outlet_tag_id !== "" && item.outlet_tag_id !== null  && item.outlet_tag_id !== undefined) {
          let outlet_tag_id = item.outlet_tag_id.split(",");
          let result = searchFilters(outlet_tag_id, current2.state.filterTag);
          if (result === true) {
            return item;
          }
        }
      });
    } else {
      matches = this.state.searchoutletslist;
    }
    this.setState({ outletslist: matches }, function () {
      this.displayOutlets();
    });
  }

  clearFilter(event) {
    event.preventDefault();
    $(".restaurants_tag").prop("checked", false);
    this.setState(
      { outletslist: this.state.searchoutletslist, filterTag: [] },
      function () {
        this.displayOutlets();
      }
    );
  }

    onChangetoOption(type){

    var shop_type = "";

    if(type == "restarants"){

      shop_type = "1";

    this.props.getAllOutlets(
      this.state.defaultAvilTy,
      this.state.eventSlug,
      shop_type,
      this.state.deliveryPostalCode
    );

    this.setState({
      addClass:"yes", 
      shop_type: 2,
      marketPlacetype: "Restaurants"
    });


    var postal_code =  cookie.load("nearest_postal_code") !== "" &&
        typeof cookie.load("nearest_postal_code") !== undefined &&
        typeof cookie.load("nearest_postal_code") !== "undefined"
          ? cookie.load("nearest_postal_code")
          : cookie.load("orderPostalCode");

   cookie.save("shopType", '1', { path: "/" });

   this.props.getMallOutlets(shop_type, postal_code);

    showLoader("mall-inner", "class");

    }else{

      showLoader("mall-inner", "class");

       shop_type = "2";
       this.props.getAllOutlets(
        this.state.defaultAvilTy,
        this.state.eventSlug,
        shop_type,
        this.state.deliveryPostalCode
      );

      
     var postal_code =  cookie.load("nearest_postal_code") !== "" &&
        typeof cookie.load("nearest_postal_code") !== undefined &&
        typeof cookie.load("nearest_postal_code") !== "undefined"
          ? cookie.load("nearest_postal_code")
          : cookie.load("orderPostalCode");

       cookie.save("shopType", '2', { path: "/" });

       this.props.getMallOutlets(shop_type, postal_code);

      this.setState({
      addClass:"no",
      shop_type: 2,
      marketPlacetype: "Retail"
     });

    }

  }



  changeSiteLocation = (item, event) =>{

   var value = event.target.value.toLowerCase();
   var shop_type = "";

  if (
      this.props.match.path === "/marketplace" ||
      this.props.match.path === "marketplace/:sluglocation"
    ) {
      shop_type = 1;
    } else if (this.props.match.path === "/retail/:sluglocation") {
      shop_type = 2;
    } else {
      shop_type = 3;
    }


   if(value !==""){
    this.props.getserachValue(value, shop_type);

   }else{

    window.location.reload();


   }

  }

  render() {



    return (
      <div className="pagesList-main-div marketplace-control">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
         <div className="search-and-info mall-info marketplace-top-info">
           <div className="container"> 
            <div className="search-info-inner">
                   <div className="search-filter mall-search"> 
                       <div className="search-filter-lhs"> 
                              <img src={searchorg} alt="" onClick={this.searchProKeyPress.bind(this)} />
                              <input type="text" className="search-rest" placeholder="Search restaurants, dishes & cuisines" onChange={this.searchPro} value={this.state.search_key_value}/>
                              {this.state.multimallsearch === true && (
                              <span onClick={this.clearMultimallsearch.bind(this)}><img src={closeImg} alt="" /></span>)}
                       </div>
                       <div className="search-filter-rhs restaurant_filter"> 
                           Filters
                        <img
                        src={closebl}
                        alt=""
                        className="close_filter_section filter-clear-image"
                        style={{ display: "none" }}
                      />
                      <img className="close_filter_section filter-img" src={filter} alt="" />  

                      </div>

                     <div className="filter-details" style={{ display: "none" }}>
                      {this.state.filterTag.length > 0 && (
                        <div className="clear-filter">
                          <Link to="#" onClick={this.clearFilter.bind(this)}>
                            Clear
                          </Link>
                        </div>
                      )}
                      <div className="item-filter common-filter">
                        <h3 className="filter-tag">Tag</h3>
                         <ul>{this.state.tagdisplay}</ul>
                    </div>
                    </div>       
                    </div>

                    {this.state.siteLocationList.length > 0 && (
                     <div className="search-ii destop-li-search marketplace-li"> 
                       <select className="up-inner-select form-control"
                        onChange={this.changeSiteLocation.bind(this, 'mallname')}
                        defaultValue={this.state.locationSlug}>
                          <option value="">All Malls</option>
                          {this.state.siteLocationList.map((item, index) => {
                          if(item.sl_slug !=="" && item.sl_slug !==null){
                          return (
                            <option
                              value={item.sl_name}
                              key={index}
                            >
                              {item.sl_name}
                            </option>
                          );
                         }
                         })}
                        </select>
                        <span className="down-arrow">
                        </span>
                  </div>
                 )}   

                {this.state.siteLocationList.length > 0 && (
                     <div className="search-ii mobile-li-search marketplace-li"> 
                       <select className="up-inner-select form-control"
                        onChange={this.changeSiteLocation.bind(this, 'mallname')}
                        defaultValue={this.state.locationSlug}>
                          <option value="">All Malls</option>
                          {this.state.siteLocationList.map((item, index) => {
                          if(item.sl_slug !=="" && item.sl_slug !==null){
                          return (
                            <option
                              value={item.sl_name}
                              key={index}
                            >
                              {item.sl_name}
                            </option>
                          );
                         }
                         })}
                        </select>
                        <span className="down-arrow">
                        </span>
                  </div>
                 )}    

             </div>

             <div className="rest-switch">
                  <div className="rest-switch-inner">
                  <a className={this.state.addClass === "yes" ? "active": ""} onClick={this.onChangetoOption.bind(this, "restarants")} >RESTAURANTS</a>

                  {this.state.settings.enable_retail == 1 && (
                  <a 
                   className={this.state.addClass === "no" ? "active": ""} onClick={this.onChangetoOption.bind(this, "retail")}
                    >Retail</a> )}
                  </div>
             </div>
            </div>
         </div>

        <section className="markets mall-inner marketplace-In-all">
          <div className="container">
          
           {this.state.displayOutlets !== "" && (
                <ul>
                  
                    {this.state.displayOutlets}
                  
                </ul>
            )}

            {this.state.displayOutlets === "" && (
                

                <h3>No results found.</h3>
            )}

          </div>
        </section>
        
        <Footer />
         <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }



  return {
    globalsettings: state.settings,
    alloutlets: state.alloutlets,
    eventList: state.events,
    staticblack: blacksArr,
    malloutletlist: state.mallsoutlets,
    siteLocationList: state.sitelocation,
    multiplemarketsearch: state.multiplemarketsearch,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getAllOutlets: (availability, eventslug, shop_type, zip_code) => {
      dispatch({
        type: GET_ALL_OUTLETS,
        availability,
        eventslug,
        shop_type,
        zip_code,
      });
    },
    getEvents: () => {
      dispatch({ type: GET_EVENTS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getloadSiteLocation: () => {
      dispatch({ type: GET_SITE_LOCATION });
    },
    getMallOutlets: (shop_type, postal_code) => {
      dispatch({ type: GET_MALLS_OUTLETS, shop_type, postal_code});
    },
    getserachValue: (search_key, shop_type) => {
      dispatch({ type: GET_MULTIPLE_MARKET_SEARCH, search_key, shop_type});
    }
    };
};

Multiplemarket.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateTopProps, mapDispatchToProps)(Multiplemarket));
