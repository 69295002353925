import { SET_MALLS_OUTLETS } from "../actions";

const mallsoutlets = (state = [], action) => {
  
  switch (action.type) {
    case SET_MALLS_OUTLETS:
      return [...action.value];
    default:
      return state;
  }
};

export default mallsoutlets;
